import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {InstructionHeader, PageLoader, BlockLoader} from '../../elements';

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){
		
		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		
		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			axios.get(Api.PageApi+'v1/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			
			var d = this.state.pageData;
			return d[fieldName];
		}
		catch(error){
			return null;
		}
	}
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<BlockLoader loading={this.state.showLoader} />
				
				<InstructionHeader title={this.getField('pagetitle')} />
				
				<section>
					<div className="container" >
						
						<Row className="mt-50" >
							<Col md={12} >
							
								<Card className="shadow-lg" >
								{Object.keys(this.state.pageData).length> 0 ? <Card.Body>
										<h5 className="text-center" >{this.getField('pagetitle')}</h5>
										<hr />
										<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
										
									</Card.Body>
									:
									this.state.showLoader ? <div></div> : <Card.Body className="text-center" >
										<h3 className="py-50" >Sorry the requested page not found.!</h3>
									</Card.Body>
								}
								</Card>
							
							</Col>
						</Row>
						
						
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default NotFound;
