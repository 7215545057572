import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import PsContext from '../../../context';



class RegisterFor extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<div className="cl__register__page" >
				<Card>
					<Card.Body>
							
						<Row>
							<Col md={12} >
								<div className="text-center" >
									<h5>Online Admission Portal</h5>
									<hr />
								</div>
							</Col>
						</Row>
						<Row className="pt-10" >
							
							{this.context.settingValue('enable_ug_admission')=='1' && (<Col md={4} >
								<Link to="/ug-admission-instruction"  style={{color: '#fff', fontSize: '20px'}} >
									<div className=" text-center bg-gradient-info py-20 text-whtie">
										<div style={{fontSize: '50px'}} ><i className="ion-compose" /></div>
										Apply for UG
									</div>
								</Link>
							</Col>)}
							{this.context.settingValue('enable_pg_admission')=='1' && (<Col md={4} >
								<Link to="/pg-admission-instruction"  style={{color: '#fff', fontSize: '20px'}} >
									<div className="text-center bg-gradient-warning py-20 text-whtie" >
										<div style={{fontSize: '50px'}} ><i className="ion-compose" /></div>
										Apply for PG
									</div>
								</Link>
							</Col>)}

							{this.context.settingValue('enable_mphil_admission')=='1' && (<Col md={4} >
								<Link to="/admission/apply/mphil"  style={{color: '#fff', fontSize: '20px'}} >
									<div className="text-center bg-gradient-success py-20 text-whtie" >
										<div style={{fontSize: '50px'}} ><i className="ion-compose" /></div>
										Apply for MPhil
									</div>
								</Link>
							</Col>)}

							<Col md={4} >
								<Link to="/page/prospectus" style={{color: '#fff', fontSize: '20px'}} >
									<div className="text-center bg-gradient-success py-20 text-whtie" >
										<div style={{fontSize: '50px'}} ><i className="ion-ios7-copy-outline" /></div>
										Prospectus
									</div>
								</Link>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				
				{/*<Card className="mt-15" >
					<Card.Body>
						<Row>
							<Col md={12} >
								<h5 className="text-center" >Instructions to Candidates</h5>
								<hr />
							</Col>
							<Col md={12} >
								

							</Col>
						</Row>
					</Card.Body>
	</Card>*/}
				
			</div>
		);
	}
	
}
export default RegisterFor;
