import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, getlsItem} from '../../../utils';
import {PageLoader} from '../../elements';

import NotFoundAlert from '../NotFound/NotFoundAlert';
import PsContext from '../../../context';

class RePay extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			btnText: 'PAY NOW',
			showLoader: false,
			rePayData: [],
			responseData: [],
		}
		
		this.handlePayNowSubmit = this.handlePayNowSubmit.bind(this);
		
	}
	
	componentDidMount(){
		//console.log(this.props.location.state);
		this.setState({repayData: this.props.location.state});
	}
	
	getField(field){
		try{
			var d = this.state.repayData;
			return d[0][field];
		}
		catch(error){
			return '';
		}
	}
	
	handlePayNowSubmit(e){
		e.preventDefault();
		try{
			
			if(!window.confirm('Do you want to continue..?')){
				return;
			}
			
			this.setState({showLoader: true, btnText: 'Processing'});
			//frmrePay
			API.post('v1/ug/course/repay', $("#frmrePay").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({responseData: res['data'].data})
					document.getElementById("redirect").submit();
					//this.setState({showLoader: false, btnText: 'PAY NOW'});
					//window.location.href = res['data'].reqlink;
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'PAY NOW'});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'PAY NOW'});
		}
	}
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		let sno=1;
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				{this.state.repayData==null || this.state.repayData==undefined || Object.keys(this.state.repayData).length<=0 ? <NotFoundAlert /> :				
				<section>
					<div className="container mt-30 py-30" >
						
						<Row >
							<Col md={{span:8, offset:2}} >
								
								
								
								<Card className="shadow-lg" >
									<Card.Body>
										
										<form action="" method="post" encType="multipart/form-data" id="frmrePay" onSubmit={this.handlePayNowSubmit} >
										
											<input type="hidden" name="i__apiCode" value={this.getField('api_code')} />
											<input type="hidden" name="i__userId" value={this.getField('user_id')} />
											<input type="hidden" name="i__userCode" value={this.getField('user_code')} />
											<input type="hidden" name="i__applicationId" value={this.getField('application_id')} />
											<input type="hidden" name="uuid" value={this.getField('uuid')} />
											
											<Row>
												<Col md={4} >
													<label>Application No</label>
												</Col>
												<Col md={7} >
													<input type="text" name="i__applicationNo" className="form-control form-control-sm" readOnly value={this.getField('application_code')} />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={4} >
													<label>Name</label>
												</Col>
												<Col md={7} >
													<input type="text" name="i__name" className="form-control form-control-sm text-uppercase" readOnly value={this.context.user.name+' '+this.context.user.initial} />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={4} >
													<label>Mobile</label>
												</Col>
												<Col md={7} >
													<input type="text" name="i__mobile" className="form-control form-control-sm" readOnly value={getls('logged_user', 'mobile')} />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={4} >
													<label>Email</label>
												</Col>
												<Col md={7} >
													<input type="text" name="i__email" className="form-control form-control-sm" readOnly value={getls('logged_user', 'email')} />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={4} >
													<label>Course Type</label>
												</Col>
												<Col md={7} >
													<input type="text" name="i__courseType" className="form-control form-control-sm" readOnly value={capitalizeFirst(this.getField('course_type'))} />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<table className="table table-bordered table-sm" >
														<thead>
															<tr className="bg-light" >
																<th>S.No</th>
																<th>Course Name</th>
																<th>Medium</th>
																<th>Preference</th>
															</tr>
														</thead>
														<tbody>
														{this.state.repayData.map((item,i)=>{
															return <tr key={i} >
																<td>{sno++}</td>
																<td>{item.degreename}-{item.coursename}</td>
																<td>{capitalizeFirst(item.medium)}</td>
																<td>{item.preference}</td>
															</tr>;
														})
														}
														</tbody>
													</table>
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={10} className="text-right" >
													<label>Application Fee</label>
												</Col>
												<Col md={2} >
													<label><b>{this.getField('total_fee')}</b></label>
												</Col>
												<Col md={12}> <hr /></Col>
											</Row>
											
											<Row>
												<Col md={12} >
													<div className="text-center" >
														<button type="submit" className="btn btn-success" disabled={this.state.btnText!='PAY NOW'} >
															{this.state.btnText}
														</button>
													</div>
												</Col>
											</Row>
										
										</form>
									
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				}
				

				{this.state.responseData && Object.keys(this.state.responseData).length>0 && (<form  method="post" name="redirect" id="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> 
				<input type="hidden" name="encRequest" value={this.state.responseData.reqdata} />
				<input type="hidden" name="access_code" value={this.state.responseData.access_code} />
			</form>)}

			</React.Fragment>
		);
	}
	
}
export default RePay;
