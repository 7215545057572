import React, {Component} from 'react';
import './index.css';

class BlockLoader extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		 if(!this.props.loading)
	   {
		   return( '' );
	   }
		return(
			<div className="dark_block_loader" >
				<div>
					<div className="loader"></div>
					<div className="loading-text">Please wait..</div>
				</div>
			</div>
		);
	}
}
export default BlockLoader;
