import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Spinner, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {InstructionHeader, InstructionHeaderResponsive} from '../../elements';

import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class MphilInstruction extends Component{
	constructor(props){
		super(props);
		this.state = {
			enableBtn: false,
			showLoader: true,
			data: [],
		}
	}
	

	componentDidMount(){

		this.loadData();
	}

	loadData(){
		this.setState({showLoader: true});
		API.get('v1/page?slug=mphil-admission-instruction').then(res=>{
			if(res['data'].status=='1'){
				this.setState({data: res['data'].data});
			}
			this.setState({showLoader: false});
		});
	}
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<InstructionHeaderResponsive title="PG Instruction"/>
				
				<main role="main" className="container pt-30">
				

				<section>
					<div className="container mt-30" >
						
					<Card className='mt-5'>
							<Card.Body style={{lineHeight: '29px', fontSize: '14px'}}>
						
						{this.state.showLoader ? <>
							<center className='my-5'>
								<Spinner  animation="border" />
							</center>
						</>
						:
						<>
						<div dangerouslySetInnerHTML={{__html : this.state.data.content }} />
						
						
						<Row>
							<Col md={12} className="text-center" >
								<hr style={{borderTop: '1px solid #0a0b0b'}} />
									<label style={{fontSize: '15px', fontWeight: '600'}} >
									<input type="checkbox" onChange={()=>{this.setState({enableBtn: !this.state.enableBtn})}} style={{width: '18px', height: '18px'}} /> I read and accept the Guidelines
									</label>
									
								<div className="text-center mt-5" style={{visibility: this.state.enableBtn ? 'visible' : 'hidden'}} >
									<Link to="/admission/apply/mphil" className="btn bg-theme text-white" >
										Continue &nbsp; <i className="ion-chevron-right" />
									</Link>
								</div>
							</Col>
						</Row>

						</>
						}
				
				
						</Card.Body>
						</Card>
					</div>
				</section>

				</main>
				
			</React.Fragment>
		);
	}
	
}
export default MphilInstruction;
