import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";



class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
			
					
			</React.Fragment>
		);
	}
	
}
export default Home;
