import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col, Card, Modal, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Var } from "../../../utils/Variables";
import API from "../../../utils/API";
import {
  integerIndMobile,
  setlsItem,
  upperCase,
  lettersOnly,
} from "../../../utils";
import { InstructionHeader } from "../../elements";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      btnText: "Register Now",
      captchaImg: "",
      captchaCode: "",
      isRedirect: false,
      courseType: this.props.match.params.coursetype,
      showMobileRegisterAlert: false,
      mobileAlertMsg: "",
      validated: false,
    };
    this.loadCaptcha = this.loadCaptcha.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    // var str = upperCase("SUBBIAH");
    // var unicode = true;
    // var letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // str.split('').map((txt,i)=>{
    // if(
    // });
    this.loadCaptcha();
  }

  loadCaptcha() {
    try {
      API.get("v1/captcha").then((res) => {
        this.setState({
          captchaImg: res["data"].img,
          captchaCode: res["data"].code,
        });
      });
    } catch (error) {}
  }

  checkIsUnicode(val) {
    try {
    } catch (error) {
      return false;
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({ validated: true });
      return;
    }
    try {
      this.setState({ btnText: "Processing..", showLoader: true });
      API.post("v1/register", $("#frmRegistration").serialize()).then((res) => {
        if (res["data"].status == "1") {
          notify.show(res["data"].message, "success");
          //setlsItem("reg_hadotp", "yes");
          setlsItem("reg_hadmobile", res["data"].mobile);
          setlsItem("reg_api_code", res["data"].api_code);
          this.setState({
            btnText: "Register Now",
            showLoader: false,
            isRedirect: true,
          });
        } else {
          notify.show(res["data"].message, "error");
          if (res["data"].message == "Mobile number already exist")
            this.setState({
              btnText: "Register Now",
              showMobileRegisterAlert: true,
              showLoader: false,
            });
          else this.setState({ btnText: "Register Now", showLoader: false });

          this.loadCaptcha();
        }
      });
    } catch (error) {
      this.setState({ btnText: "Register Now", showLoader: false });
      this.loadCaptcha();
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/mobile-verify" />;
    }

    return (
      <React.Fragment>
        <InstructionHeader title="Registration" />

        <section>
          <div className="container mt-30 pb-30">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Card className="shadow-lg">
                  <Card.Body>
                    <Form
                      noValidate
                      validated={this.state.validated}
                      action=""
                      method="post"
                      encType="multipart/form-data"
                      id="frmRegistration"
                      onSubmit={this.handleFormSubmit}
                    >
                      <Row>
                        <Col md={12}>
                          <div className="text-center">
                            <h5>Candidate Registration</h5>
                            <hr />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <label>
                            Apply For <span className="text-danger">*</span>
                          </label>
                          {/*<select className="form-control form-control-sm" required name="i__applyFor" >
														<option value="ug" selected={this.state.courseType=='ug' ? 'selected' : ''} > UG </option>
														<option value="pg" selected={this.state.courseType=='pg' ? 'selected' : ''}  > PG </option>
														<option value="mphil" selected={this.state.courseType=='mphil' ? 'selected' : ''}  > MPhil </option>*
													</select>*/}
                          <Form.Control
                            type="text"
                            value={upperCase(this.state.courseType)}
                            name="i__applyFor"
                            required
                            size="sm"
                          />
                        </Col>
                      </Row>

                      {/*<Row className="mt-15" >
												<Col md={12} >
													<label>HSC Registration No <span className="text-danger" >*</span></label>
													<input type="number" className="form-control form-control-sm" placeholder="HSC Registration No" name="i__hscRegNo" required />
												</Col>
											</Row>*/}

                      <Row className="mt-15">
                        <Col md={3}>
                          <label>
                            Initial <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Initial"
                            name="i__initial"
                            required
                            style={{ textTransform: "uppercase" }}
                            onKeyPress={lettersOnly}
                            autoFocus={true}
                          />
                        </Col>
                        <Col md={9}>
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Name"
                            name="i__name"
                            required
                            style={{ textTransform: "uppercase" }}
                            onKeyPress={lettersOnly}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-15">
                        <Col md={12}>
                          <label>
                            Mobile <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Mobile Number"
                            name="i__mobile"
                            required
                            onKeyPress={integerIndMobile}
                          />
                          <div>
                            <span className="tamil-text-sm">
                              ஒரு தொலைபேசி எண்ணை ஒரு முறை மட்டுமே பதிவு செய்ய
                              இயலும்.
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-15">
                        <Col md={12}>
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            placeholder="Email Id"
                            name="i__email"
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-15">
                        <Col md={12}>
                          <label>
                            Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-sm"
                            placeholder="Login Password"
                            name="i__password"
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-15">
                        <Col md={12}>
                          <label>
                            Captcha <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <img src={this.state.captchaImg} />
                        </Col>
                        <Col md={7}>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Captcha"
                            name="i__captcha"
                            required
                          />
                        </Col>
                        <Col md={2}>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            type="button"
                            onClick={this.loadCaptcha}
                          >
                            <i className="fa-solid fa-arrows-rotate" />
                          </button>
                        </Col>
                      </Row>
                      <input
                        type="hidden"
                        name="i__captchaCode"
                        value={this.state.captchaCode}
                      />
                      <Row className="mt-15 py-15">
                        <Col md={12}>
                          <button
                            type="submit"
                            className="btn btn-success btn-block"
                            disabled={this.state.btnText != "Register Now"}
                          >
                            {this.state.btnText}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <Modal
          show={this.state.showMobileRegisterAlert}
          onHide={() => {
            this.setState({ showMobileRegisterAlert: false });
          }}
        >
          <Modal.Header closeButton>Information</Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">
              Sorry the given mobile number is already registerd.
            </div>
            Please <Link to="/">Click Here</Link> to login your account.
            <hr />
            If you forgot your password please{" "}
            <Link to="/account/forgot-password">Click Here</Link> to reset your
            password.
            <hr />
            OR using different Mobile number to continue registration.
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
export default Registration;
