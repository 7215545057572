import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {InstructionHeader, InstructionHeaderResponsive} from '../../elements';

import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class UgInstructions extends Component{
	constructor(props){
		super(props);
		this.state = {
			enableBtn: false,
			showLoader: true,
			data: [],
		}
	}
	
	componentDidMount(){

		this.loadData();
	}

	loadData(){
		this.setState({showLoader: true});
		API.get('v1/page?slug=ug-admission-instruction').then(res=>{
			if(res['data'].status=='1'){
				this.setState({data: res['data'].data});
			}
			this.setState({showLoader: false});
		});
	}
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<InstructionHeaderResponsive title="UG Instruction"/>
				
				 <main role="main" className="container pt-20">
				<section>
					<div className="container mt-30" >
						
						{/*<Row >
							<Col md={12} >
								For the convenience of the applicants Online Application facility is made available..
								<br /><br />
								<b>Instructions to be followed before filling in the online Application</b>
								<br />
								<br />
								<ul>
									<li>Physically challenged candidates must upload the Certificate of proof.</li>
									<li>Eminent Sports persons and Children of Ex Service Men must upload Certificate of proof.</li>
									<li>Academic Certificate, Transfer Certificate, and Community Certificate must be uploaded by all the candidates.</li>
									<li>The candidate must upload a recent passport size photo in JPEG and PNG Format (300KB - 1MB of size only).</li>
									<li>Photo background should be in white color</li>
									<li>Application Fee for all the Government Aided UG Degree Courses is Rs 50/-</li>
									<li>For SC/ST Candidates no Application Fee</li>
									<li>Application Fee for all the Self Financed UG Degree Courses (for Candidates belonging to all categories) is Rs 100/-</li>
								</ul>
							</Col>
						</Row>*/}
						<Card className='mt-5'>
							<Card.Body style={{lineHeight: '29px', fontSize: '14px'}}>
						
						{this.state.showLoader ? <>
							<center className='my-5'>
								<Spinner  animation="border" />
							</center>
						</>
						:
						<>
						<div dangerouslySetInnerHTML={{__html : this.state.data.content }} />
						
						<Row>
							<Col md={12} className="text-center" >
								<hr />
									<label style={{fontSize: '15px', fontWeight: '600'}} >
									<input type="checkbox" onChange={()=>{this.setState({enableBtn: !this.state.enableBtn})}} style={{width: '18px', height: '18px'}} /> I read and accept the Guidelines
									</label>
								<div className="text-center mt-5" style={{visibility: this.state.enableBtn ? 'visible' : 'hidden'}} >
									<Link to="/admission/apply/ug" className="btn bg-theme text-white" >
										Continue &nbsp; <i className="ion-chevron-right" />
									</Link>
								</div>
							</Col>
						</Row>

						</>
						}
				
				
						</Card.Body>
						</Card>

					</div>
				</section>
				</main>
				
			</React.Fragment>
		);
	}
	
}
export default UgInstructions;
