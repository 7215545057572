import {getlsItem, setls, setlsItem, getls} from '../utils';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import CustomPage from './pages/Instructions/CustomPage';
import Login from './pages/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import UgInstructions from './pages/Instructions/UgInstructions';
import PgInstruction from './pages/Instructions/PgInstruction';
import Registration from './pages/Admission/Registration';
import OtpVerify from './pages/Admission/OtpVerify';

import Dashboard from './pages/Dashboard';
import UGRegularCourseApply from './pages/UG/RegularCourseApply';
import UGSelfCourseApply from './pages/UG/SelfCourseApply';
import UGCourseApplyFor from './pages/UG/CourseApplyFor';
import UGApplicationDownload from './pages/UG/ApplicationDownload';
import UGRePay from './pages/UG/RePay';
import UGPaymentComplete from './pages/UG/PaymentComplete';
import UGAdmissionFeeComplete from './pages/UG/AdmissionFeeComplete';

import UG_EditPersonalInformation from './pages/UG/EditPersonalInformation';

import PGSelfCourseApply from './pages/PG/SelfCourseApply';
import PGRegularCourseApply from './pages/PG/RegularCourseApply';
import PGCourseApplyFor from './pages/PG/CourseApplyFor';
import PGApplicationDownload from './pages/PG/ApplicationDownload';
import PGRePay from './pages/PG/RePay';
import PGPaymentComplete from './pages/PG/PaymentComplete';
import PGAdmissionFeeComplete from './pages/PG/AdmissionFeeComplete';

import MPHILSelfCourseApply from './pages/MPHIL/SelfCourseApply';
import MPHILRegularCourseApply from './pages/MPHIL/RegularCourseApply';
import MPHILCourseApplyFor from './pages/MPHIL/CourseApplyFor';
import MPHILApplicationDownload from './pages/MPHIL/ApplicationDownload';
import MPHILRePay from './pages/MPHIL/RePay';
import MPHILPaymentComplete from './pages/MPHIL/PaymentComplete';
import MPHILAdmissionFeeComplete from './pages/MPHIL/AdmissionFeeComplete';
import MphilInstruction from './pages/Instructions/MphilInstruction';


const pageList = [
	{ name: 'Login', path: '/', component: Login },
	{ name: 'Forgot Password', path: '/account/forgot-password', component: ForgotPassword },
	{ name: 'Ug Instructions', path: '/ug-admission-instruction', component: UgInstructions },
	{ name: 'Pg Instructions', path: '/pg-admission-instruction', component: PgInstruction },
	{ name: 'Mphil Instructions', path: '/mphil-admission-instruction', component: MphilInstruction },
	{ name: 'Ug Admission', path: '/admission/apply/:coursetype?', component: Registration },
	{ name: 'Mobile Verify', path: '/mobile-verify', component: OtpVerify },
	
	{ name: 'Dashboard', path: '/app/dashboard', component: Dashboard, withLogin: true },
	{ name: 'UG Regular Course Apply', path: '/app/ug-regular-course-apply', component: UGRegularCourseApply, withLogin: true },
	{ name: 'UG Self Course Apply', path: '/app/ug-self-course-apply', component: UGSelfCourseApply, withLogin: true },
	{ name: 'Course Apply', path: '/app/ug-course-apply', component: UGCourseApplyFor, withLogin: true },
	{ name: 'Course Apply', path: '/app/ug-application-download', component: UGApplicationDownload , withLogin: true},
	{ name: 'Re Pay', path: '/app/ug/repay', component: UGRePay, withLogin: true },
	{ name: 'Payment Completed', path: '/app/ug/payment-status/:orderId', component: UGPaymentComplete , withLogin: true},
	{ name: 'Payment Completed', path: '/app/ug/admission-fee-status/:orderId', component: UGAdmissionFeeComplete, withLogin: true },

	{ name: 'Edit Personal Information', path: '/app/ug/edit-personal', component: UG_EditPersonalInformation, withLogin: true },
	
	{ name: 'Course Apply', path: '/app/pg-course-apply', component: PGCourseApplyFor, withLogin: true },
	{ name: 'PG Self Course Apply', path: '/app/pg-self-course-apply', component: PGSelfCourseApply , withLogin: true},
	{ name: 'PG Regular Course Apply', path: '/app/pg-regular-course-apply', component: PGRegularCourseApply, withLogin: true },
	{ name: 'Course Apply', path: '/app/pg-application-download', component: PGApplicationDownload , withLogin: true},
	{ name: 'Re Pay', path: '/app/pg/repay', component: PGRePay , withLogin: true},
	{ name: 'Payment Completed', path: '/app/pg/payment-status/:orderId', component: PGPaymentComplete , withLogin: true},
	{ name: 'Payment Completed', path: '/app/pg/admission-fee-status/:orderId', component: PGAdmissionFeeComplete, withLogin: true },
	
	{ name: 'Course Apply', path: '/app/mphil-course-apply', component: MPHILCourseApplyFor, withLogin: true },
	{ name: 'MPhil Self Course Apply', path: '/app/mphil-self-course-apply', component: MPHILSelfCourseApply, withLogin: true },
	{ name: 'MPhil Regular Course Apply', path: '/app/mphil-regular-course-apply', component: MPHILRegularCourseApply , withLogin: true},
	{ name: 'Course Apply', path: '/app/mphil-application-download', component: MPHILApplicationDownload, withLogin: true },
	{ name: 'Re Pay', path: '/app/mphil/repay', component: MPHILRePay, withLogin: true },
	{ name: 'Payment Completed', path: '/app/mphil/payment-status/:orderId', component: MPHILPaymentComplete, withLogin: true },
	{ name: 'Payment Completed', path: '/app/mphil/admission-fee-status/:orderId', component: MPHILAdmissionFeeComplete, withLogin: true },
	{ name: 'Custom Page', path: '/page/:slug', component: CustomPage, withLogin: false },
];
/* 
const st = [
	{ name: 'Dashboard', path: '/app/dashboard', component: Dashboard },
	{ name: 'UG Regular Course Apply', path: '/app/ug-regular-course-apply', component: UGRegularCourseApply },
	{ name: 'UG Self Course Apply', path: '/app/ug-self-course-apply', component: UGSelfCourseApply },
	{ name: 'Course Apply', path: '/app/ug-course-apply', component: UGCourseApplyFor },
	{ name: 'Course Apply', path: '/app/ug-application-download', component: UGApplicationDownload },
	{ name: 'Re Pay', path: '/app/ug/repay', component: UGRePay },
	{ name: 'Payment Completed', path: '/app/ug/payment-status/:orderId', component: UGPaymentComplete },
	
];

if(getlsItem('logged')=='yes'){
	st.map((item,i)=>{
		pageList.push(item);
	});
} */

pageList.push({ name: 'Not Found', path: '*', component: NotFound, withLogin: false, });	

export default pageList;