import Ract from 'react';
import Axios from 'axios';
import API from './API';

export const listAll = async() =>{
	
	return new Promise((resolve, reject) => {
		API.get('v1/admission/settings')
			.then(function ({data}) {
				resolve(data.data);
			})
			.catch(function (error) {
				//resolve([]);
				return [];
			});	
	});
}



export const listAnnouncements = async() =>{
	
	return new Promise((resolve, reject) => {
		API.get('v1/announcement')
			.then(function ({data}) {
				resolve(data.data);
			})
			.catch(function (error) {
				//resolve([]);
				return [];
			});	
	});
}
