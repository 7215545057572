import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card, Modal, Form} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {loginFor, Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {InstructionHeader, BlockLoader} from '../../elements';
import {setls, getls, setlsItem, getlsItem} from '../../../utils';
import LOGO from '../../../assets/img/spc-logo.png';
import PsContext from '../../../context';


class ForgotPassword extends Component{

	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			loginText: 'Send OTP',
			captchaImg: '',
			captchaCode: '',
			isRedirect: false,
			enableResend: false,
			showResetForm: true,
			timeSpan: 180,
			btnText: 'Verify OTP',
			apiCode: '',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.showTimeSpan = this.showTimeSpan.bind(this);
		this.handleResendOtp = this.handleResendOtp.bind(this);
		this.handleOtpFormSubmit = this.handleOtpFormSubmit.bind(this);
	}
	
	componentDidMount(){
		setInterval(this.showTimeSpan, 1000);
	}
	
	showTimeSpan(){
		if(this.state.timeSpan==0){
			this.setState({enableResend: true});
		}
		else{
			this.setState({timeSpan: this.state.timeSpan-1});
		}
	}
	
	handleResendOtp(){
		try{
			this.setState({showLoader: true});
			let m = JSON.parse(this.context.get('pass_reest'));
			var form = new FormData();
			form.append('i__api', (m && m.reg_api_code) || this.state.apiCode);
			
			API.post('v1/mobileotp/resend',form).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.setState({showLoader: false, enableResend: false, timeSpan: 180});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false});
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({loginText: 'Validating..', showLoader: true});
			API.post('v1/forgot/sendotp', $("#frm_ForPas").serialize()).then(res=>{
				if(res['data'].status=='1'){
					//setls('pass_reest', res['data'].data);
					this.context.set('pass_reest', JSON.stringify(res['data'].data));
					notify.show(res['data'].message,'success');
					this.setState({apiCode: res['data'].data.api_code, showLoader: false, showResetForm: false});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({loginText: 'Send OTP', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({loginText: 'Send OTP', showLoader: false});
		}
	}
	
	
	handleOtpFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnText: 'Validating..', showLoader: true});
			API.post('v1/forgot/validate', $("#frm_RetPs").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.context.set('pass_reest', '');
					this.setState({btnText: 'Verify OTP', showLoader: false, isRedirect: true});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Verify OTP', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Verify OTP', showLoader: false});
		}
	}
	
	
	render(){
		
		
			
		return(
			<>
				<InstructionHeader title="Forgot Password"/>
				
				<BlockLoader loading={this.state.showLoader} />
				
				<Row className="mt-5" >
					<Col md={{span: 4, offset: 4}} >
						<Card style={{marginTop: '50px'}}>
							<Card.Body>
								<h5 className="text-center" >Forgot Password</h5>
								<hr />
								{this.state.showResetForm ?
								<form action="" method="post" encType="multipart/form-data" id="frm_ForPas" onSubmit={this.handleFormSubmit} >

									<Row>
										<Col md={12}>
											<label>Register For</label>
											<Form.Control as="select" name="i__courseType" size="sm" >
												
												{loginFor.map(item => this.context.settingValue(item.key)=='1' && <option value={item.value}>{item.text}</option>)}
											</Form.Control>
										
										</Col>
									</Row>
									<Row className="mt-20" >
										<Col md={12} >
											<label>Registered Mobile number <span className="text-danger">*</span></label>
											<input type="number" className="form-control form-control-sm" required name="i__mobile" placeholder="Your mobile number" />
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={12} >
											<div className="text-right" >
												<button type="submit" className="btn btn-info btn-sm" disabled={this.state.loginText!='Send OTP'} >
												{this.state.loginText}
												</button>
											</div>
										</Col>
									</Row>
									
								</form>
								:
								<form action="" method="post" encType="multipart/form-data" id="frm_RetPs" onSubmit={this.handleOtpFormSubmit} >
											<input type="hidden" name="i__api" value={getls('pass_reest', 'api_code')} />

											
											<Row>
												<Col md={12} >
													<input type="text" className="form-control form-control-md" readOnly value={getls('pass_reest', 'mobile')} style={{textAlign: 'center'}} name="i__mobile" />
												</Col>
											</Row>
											
											<Row className="mt-20" >
												<Col md={12} >
													<input type="text" className="form-control form-control-lg" style={{textAlign: 'center'}} name="i__otp" required placeholder="Enter Your OTP" />
												</Col>
											</Row>
											
											<Row className="mt-15" >
												<Col md={12} >
													<hr />
													<button type="submit" className="btn btn-success btn-block" disabled={this.state.btnText!='Verify OTP'} >
														{this.state.btnText}
													</button>
												</Col>
												<Col md={12} >
													<div className="text-center mt-15" >
													{this.state.enableResend ? null : this.state.timeSpan} &emsp;
														{this.state.enableResend ?
															<a href="javascript:;" onClick={this.handleResendOtp} > Resend</a>
															: <a href="javascript:;" style={{color: 'rgb(173, 173, 173)'}} > Resend</a>
														}
													</div>
												</Col>
											</Row>
								</form>
								}
							</Card.Body>
						</Card>
					</Col>
				</Row>
				
				<Modal show={this.state.isRedirect} >
					<Modal.Header>
						Message
					</Modal.Header>
					<Modal.Body>
						<div className="alert alert-success" >
							OTP verified successfully. We are sending temporary login password to your registered mobile number.
						</div>
						<hr />
						<div className="text-center" >
							<Link to="/">  Click Here </Link> to login your account.
							<br /><br />
						</div>
					</Modal.Body>
				</Modal>
			
			</>
		);
	}
	
}
export default ForgotPassword;
