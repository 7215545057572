import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {InstructionHeader, InstructionHeaderResponsive} from '../../elements';

import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class CustomPage extends Component{
	constructor(props){
		super(props);
		this.state = {
			enableBtn: false,
			showLoader: true,
			slug: props.match.params.slug,
			data: [],
		}
	}
	
	componentDidMount(){

		this.loadData();
	}

	componentWillReceiveProps(props){

		this.setState({showLoader: true, data: []});
		API.get('v1/page?slug='+props.match.params.slug).then(res=>{
			if(res['data'].status=='1'){
				this.setState({data: res['data'].data});
			}
			this.setState({showLoader: false});
		});
	}

	loadData(){
		this.setState({showLoader: true, data: []});
		API.get('v1/page?slug='+this.state.slug).then(res=>{
			if(res['data'].status=='1'){
				this.setState({data: res['data'].data});
			}
			this.setState({showLoader: false});
		});
	}
	
	render(){
		
		
		return(
			<React.Fragment>

				
				 <main role="main" className="container pt-20">
				<section>
					<div className="container mt-30" >
						
						<Card className='mt-5'>
							<Card.Body style={{lineHeight: '29px', fontSize: '14px', minHeight: 'calc(50vh)'}}>
						
						{this.state.showLoader ? <>
							<center className='my-5'>
								<Spinner  animation="border" />
							</center>
						</>
						:
						<>
						<h4 className="text-center">{this.state.data.name}</h4>
						<hr />
						<div dangerouslySetInnerHTML={{__html : this.state.data.content }} />
						
						

						</>
						}

						{!this.state.showLoader && Object.keys(this.state.data).length<1 && (
							<div className='text-center' >
								<h4>Sorry the requested page not found</h4>
								
							</div>
						)}
				
				
						</Card.Body>
						</Card>

					</div>
				</section>
				</main>
				
			</React.Fragment>
		);
	}
	
}
export default CustomPage;
