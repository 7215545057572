import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Footer} from '../../elements';
import { listAnnouncements } from '../../../utils/fieldSettings';
import { getAscSortOrder } from '../../../utils';

class Announcement extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadAnnouncement();
	}

	loadAnnouncement(){
		listAnnouncements().then(res=>{
			if(res){
				this.setState({
					dataList: res.sort(getAscSortOrder('display_order')),
				});
			}
		});
	}
	
	renderHtmlText(){
;
		return this.state.dataList.map(item => <li style={{display: 'inline-block '}} ><div className="px-4" dangerouslySetInnerHTML={{__html : item.html_text}} /></li>)
	}

	marqueeMouseOver(){
		document.getElementById("adm_top_marquee").stop();
	};
	
	marqueeMouseOut(){
		document.getElementById("adm_top_marquee").start();
	};

	render(){
		
		
		return(
			<Card className="mb-20" >
				<Card.Body >
					
					<marquee onMouseOver={this.marqueeMouseOver.bind(this)} onMouseOut={this.marqueeMouseOut.bind(this)} id="adm_top_marquee" >
						{/*<img src="https://res.cloudinary.com/dzyixy4du/image/upload/v1595394301/new_2_.gif" style={{width: '55px'}} /> &nbsp;
						தேர்வு செய்யப்பட்ட முதலாம் ஆண்டு மாணவிகள் (2020-2021) இணைய வழி மூலம் கல்விக் கட்டணம் கட்டும் கடைசி நாள் 27.08.2020.   தவறினால் சேர்க்கை ரத்து  செய்யப்படும்.*/}
						<ul className="top_marquee">{this.renderHtmlText()}</ul>
					</marquee>
					
				</Card.Body>
			</Card>
		);
	}
	
}
export default Announcement;
