import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Table, Button} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var, MonthData, Api} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, upperCase,setls} from '../../../utils';
import {PageLoader} from '../../elements';

import multiDownload from 'multi-download';

class AdmissionFeePayment extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			showPasswordModal: false,
			showLoader: false,
			dataList: [],
			rePayData: [],
			reqData: [],
			userData: [],
			rePayData: [],
			personalData: [],
			submitReqForm: false,
		}
	}
	
	
	componentDidMount(){
		
		this.checkReadytoPay();
	}
	
	checkReadytoPay(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			form.append('i__userId', getls('logged_user', 'user_id'));
			API.post('v1/pg/ready_to_intial_pay',form).then(res=>{
				if(res['data'].status=='1'){
					setls('logged_user_course', res['data'].user);
					this.setState({dataList: res['data'].data, userData: res['data'].user,personalData: res['data'].personal, showLoader: false});
					
				}
				else{
					//notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handlePayNowClick(item){
		try{
			
			if(!window.confirm('Do you want to Pay Now?')){
				return false;
			}
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			form.append('i__userId', getls('logged_user', 'user_id'));
			form.append('i__id', item.id);
			API.post('v1/pg/get_initial_pay_link',form).then(res=>{
				if(res['data'].status=='1'){
					window.location.href = res['data'].reqlink;
					//this.setState({rePayData: res['data'].reqlink, submitReqForm: true});
					//document.getElementById("frmCcPay").submit();
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getRequestField(fieldName){
		try{
			var d = this.state.reqData;
			return d[fieldName];
		}
		catch(error){return '';}
	}
	
	getUserField(fieldName){
		try{
			var d = this.state.userData;
			return d[fieldName];
		}
		catch(error){return '';}
	}
	
	getTotalAmount(course){
		try{
			if(course.coursecat=='science' && course.course_type=='regular' && this.state.personalData.physically_challanged=='1'){
				return (parseFloat(course.total)-327);
			}
			return course.total;
		}
		catch(error){
			return course.total;
		}
	}
	
	render(){
		
		var applyFor = getls('logged_user', 'applied_for');

				return(
			<div className="container mt-30" >
				
				<PageLoader loading={this.state.showLoader} />
				
					<Row >
					<Col md={12} >
						{Object.keys(this.state.dataList).length>0 ?
							<>
							{/*getls('logged_user', 'admission_fee_paid')=='0' ?*/}
								<div className="mb-20" >
									<div className="alert alert-accent alert-info">
									Hi, your are selected for the following courses, Click the Pay Now  button to Pay the admission amount to confirm your selection.
									</div>
									<div className="alert alert-accent alert-danger">
										(No Transaction charges for RUPAY Debit Card.)
									</div>
									<Table bordered >
										<thead className="thead-primary" >
											<tr>
												<th>S.No</th>
												<th>Application No</th>
												<th>Course Name</th>
												<th>Type</th>
												<th>Amount</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
										{this.state.dataList.map((item,i)=>{
											return <tr key={i} >
												<td>{i+1}</td>
												<td>{item.application_code}</td>
												<td>{item.degree_name} - {item.course_name}</td>
												<td>{upperCase(item.coursetype)}</td>
												<td align="right" >Rs. {this.getTotalAmount(item)}</td>
												<td align="center" >
												{item.installment_status=='1' ? <Button type="button" variant="warning" size="sm" >
														Already Paid
													</Button>
													: <Button type="button" size="sm" onClick={this.handlePayNowClick.bind(this,item)} >
														Pay Now
													</Button>
												}
												</td>
											</tr>;
										})
										}
										</tbody>
									</Table>
								</div>
						{/*: null}*/}
							</>
						: null}
					</Col>									
					
				</Row>
				
				
				{/*
				<form action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" method="post" name="redirect" id="frmCcPay" >
					<input type="hidden" name="encRequest" value={this.getRequestField('reqdata')} />
					<input type="hidden" name="access_code" value={this.getRequestField('access_code')} />
				</form>
				*/
				}
				
			</div>
		);
	}
	
	
}
export default AdmissionFeePayment;
