import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import LOGO from '../../../assets/img/spc-logo.png';

import InstructionHeaderResponsive from './InstructionHeaderResponsive';

class InstructionHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	componentDidMount(){
		 
	}
	
	render(){
		
		
		return(
		<>
			
			<InstructionHeaderResponsive {...this.props} />
			
		{/*<section style={{backgroundColor: '#fff'}}>
				<Row  className="px-10 pt-10"  >
					<Col md={3} >
						<Link to="/" > <img src={LOGO} style={{width: '100%'}} /> </Link>
					</Col>
				</Row>
				
				<div className="bg-theme" style={{paddingTop: '10px', paddingBottom: '6px'}} >
				<div className="container">
					<Row   >
						<Col md={10} >
							<h6 className="text-white" style={{fontWeight: '600'}} >{this.props.title}</h6>
						</Col>
						<Col md={2} >
							<Link to="/" className="text-white"  >Back to Login</Link>
						</Col>
					</Row>
				</div>
				</div>
		</section>*/}
		</>
		);
	}
	
}
export default InstructionHeader;
