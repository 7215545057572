import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var, MonthData, Api} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, upperCase} from '../../../utils';
import {PageLoader} from '../../elements';

import multiDownload from 'multi-download';
import PsContext from '../../../context';

class ApplicationDownloadList extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			isRedirect: false,
			redirectTo: '',
			redirectParams: [],
		}

	}
	
	componentDidMount(){
		
		this.loadApplicationList();
	}
	
	loadApplicationList(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', this.context.user.api_code);
			API.post('v1/pg/courses',form).then(res=>{
				if(res['data'].status=='1'){
					var d = groupByMultiple(res['data'].data, function(obj){
						//return[obj.application_id, obj.application_code, obj.course_type];
						return[obj.uuid, obj.course_type];
					});
					this.setState({dataList: res['data'].data, dataView: d, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getCourses(item){
		var tr = [];
		try{
			
			item.map((course,i)=>{
				tr.push(<tr key={i} >
					<td>{course.application_code}</td>
					<td>{course.degreename}-{course.coursename}</td>
					<td>{capitalizeFirst(course.medium)}</td>
					<td align="center">{course.fee_paid_status =='1' ? 'Paid' : course.fee_paid_response}</td>
				</tr>);
			});
			{/*<td>{course.fee_paid_status =='1' ? <button type="button" className="btn btn-sm btn-primary" onClick={this.handleSingleDownloadClick.bind(this,course)} >
					Download
				</button> : course.fee_paid_response}
				
		</td>*/}
			return tr;
		}
		catch(error){
			return tr;
		}
	}
	
	getDownLoadLink(items){
		var tr = [];
		try{
			var item = items[0];
			if(item.fee_paid_status=='1'){
				tr.push(<button type="button" className="btn btn-sm btn-primary" onClick={this.handleSingleDownloadClick.bind(this,item)} >
					Download
				</button>);
			}
			else{
			//else if(item.fee_paid_status=='0'){
				tr.push(<div>
				{(item.fee_paid_status=='0' && item.order_id!=null && item.order_id!='' && upperCase(item.fee_paid_response) != upperCase('Aborted')) ? <a href="javascript:;" onClick={this.trackMyOrderStatus.bind(this,item)} className='btn btn-secondary btn-sm me-1' >
					<i className="fa-solid fa-shuffle"></i> Track Status
				</a> : null}
				<button type="button" className="btn btn-sm btn-primary" onClick={this.handleRepayClick.bind(this,items)} >
					<i className="fa-solid fa-money-check"></i> Pay Now
				</button>
				</div>);
			}
			
			return tr;
		}
		catch(error){
			return tr;
		}
	}
	
	handleRepayClick(items){
		
		this.setState({redirectParams: items, redirectTo: '/app/pg/repay', isRedirect: true});
	}
	
	handleDownloadClick(items){
		try{
			this.setState({showLoader: true});
			
			var urls = [];
			//console.log(Api.Url +'v1/ug/pdf/'+item.application_code+'/'+item.api_code);
			/* var link = document.createElement('a');
			link.href = Api.Url +'v1/ug/pdf/'+item.application_code+'/'+item.api_code;
			link.download = item.application_code+'.pdf';
			link.dispatchEvent(new MouseEvent('click')) */;
			 for(var i=0;i<Object.keys(items).length;i++)
			{ 
				 var item = items[i];
				/* var filePath = Api.Url +'v1/ug/pdf/'+item.application_code+'/'+item.api_code;
				var a = document.createElement('A');
				a.href = filePath;
				a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);  */
				urls.push(Api.Url +'v1/pg/pdf/'+item.application_code+'/'+item.api_code);
			}
			
			multiDownload(urls);
			
			this.setState({showLoader: false});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	handleSingleDownloadClick(item){
		try{
			this.setState({showLoader: true});			
			
				var filePath = Api.Url +'/v1/pg/pdf/'+item.admission_year+'/'+item.uuid+'/'+item.application_code;
				var a = document.createElement('A');
				a.href = filePath;
				a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a); 				
			
			this.setState({showLoader: false});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	trackMyOrderStatus(course){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__apiCode', this.context.api);
			form.append('uuid', course.uuid);
			form.append('id', course.id);
			
			API.post('v1/pg/trackmyorder', form).then(res=>{
				if(res['data'].status=='1'){
					//notify.show(res['data'].message, 'success');
					this.loadApplicationList();
					this.setState({showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		let sno=1;
		
		if(this.state.isRedirect){
			return(<Redirect to={{
					pathname: this.state.redirectTo,
					state: this.state.redirectParams
			}} />);
		}
		
		return(								
				<Card className="shadow-lg mt-30" >
					<Card.Body>
						
						<PageLoader loading={this.state.showLoader} />
						
						<Row>
							<Col md={12} >
								<div className="text-center" >
									<h5>Download Filled Applications</h5>
									<hr />
								</div>
							</Col>
						</Row>
						
						<Row>
							<Col md={12} >
								<div className="table-responsive" >
								<table className="table table-bordered table-sm" >
									<thead>
										<tr className="bg-red-light-4" >
											<th>Download</th>
											<th>Course Type</th>
											<th>Application No</th>
											<th>Course Name</th>
											<th>Medium</th>
											<th>Payment Status</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										return <React.Fragment>
											<tr key={i} >
											<td align="center" rowSpan={Object.keys(item).length+1} >
												{this.getDownLoadLink(item)}
											</td>
											<td rowSpan={Object.keys(item).length+1} >{capitalizeFirst(item[0].course_type)}</td>
										</tr>
										{this.getCourses(item)}
										</React.Fragment>;
									})
									}
									</tbody>
								</table>
								</div>
							</Col>
						</Row>
						
												
					</Card.Body>
				</Card>
		);
	}
	
}
export default ApplicationDownloadList;
