import React, { useState } from 'react';
import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PsContext from '../../../context';

const PortalLockWindow=(props)=>{

	const context = useContext(PsContext);

	const [pass, setPass] = useState('');

	const handleOkClick=()=>{

		if(pass == '' || pass.length<1) {
			alert('Enter Password');
			return;
		}
		
		if(pass != 'Adm@#'){
			alert('Invalid Password');
			return;
		}

		context.setPassLockDisabled('0');
	}

	return(
		<>
			<center>
				<Row>
					<Col md={{span:4, offset: 4}}>
						<h5>Portal locked with password for demo purpose..</h5>
						<label>Password</label>
						<Form.Control
							type="password"
							value={pass}
							onChange={e => setPass(e.target.value)}
						/>
						<Button className="mt-10" onClick={handleOkClick}>
							Go Next
						</Button>
					</Col>
					
				</Row>
			</center>
		</>
	);
};

export default PortalLockWindow;