import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import routes from '../views';
import {Header, HeaderResponsive, Footer} from '../views/elements';
import API from '../utils/API';
import {Api} from '../utils/Variables';
import {setls} from '../utils';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";

import Announcement from '../views/pages/Announcement';
import PsContext from '../context';

class SiteLayout extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		//this.loadSettings();
		//this.context.loadSettings();
	}
	
	loadSettings(){
		try{
			API.get('v1/settings/me/1').then(res=>{
				if(res['data']. status == '1'){
					this.setState({siteData: res['data'].data});
					setls('site',res['data'].data);	
				}
			});
		}
		catch(error){
			
		}
	
	}
	
	render(){

			
		$('html, body').animate({scrollTop:0});
		
		//return(<Announcement />);
		
		return(
			<div> 
				
				<Helmet>
					<title>{this.context.settingValue('admission_meta_title')}</title>
				</Helmet>
				<Notifications />
				
				<HeaderResponsive />
				
				<div className="page_content_holder" >
				
					<Switch>
						{/*routes.map((page,key)=>(
							page.withLogin ? 
								this.context.logged=='yes' ? 
								<Route exact path={page.path}  component={page.component} key={key} /> : 
								<Redirect to="/" /> :
								<Route exact path={page.path}  component={page.component} key={key} />
							
						))*/}
						{routes.map((page,key)=>(
								<Route exact path={page.path}  component={page.component} key={key} />						
						))}
					</Switch>	
					
				</div>
				
				<Footer />
				
				<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank" style={{display:'inherit'}}></iframe>
				
			</div>
		);
	}
	
}
export default SiteLayout;