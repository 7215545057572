import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ReactFileReader from 'react-file-reader';
import {Var, MonthData} from '../../../utils/Variables';
import API from '../../../utils/API';
import {integerIndMobile,setlsItem, getls, setls, decimalKeyPress, numberToMonth, upperCase, getlsItem, lettersOnly} from '../../../utils';
import {PageLoader} from '../../elements';
import SELECT_IMAGE from '../../../assets/img/select-image.png';
import Select from "react-dropdown-select";

import InstructionForMarksUpload from './InstructionForMarksUpload';
import PsContext from '../../../context';

class MarksUpload extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save Marks',
			captchaImg: '',
			captchaCode: '',
			isRedirect: false,
			redirectToCourseApply: false,
			selectedCountry: '',
			selectedState: '',
			selectedFile: '',
			applicantInfo: [],
			hscSubjects: [],
			selectedCommunity: '',
			scoredTotal: 0,
			part3Total : 0,
			registerNo: '',
			today: '',
			subject3: '',
			subject4: '',
			subject5: '',
			subject6: '',
		}
		this.loadCaptcha = this.loadCaptcha.bind(this);
		this.handleProfilePhotChange = this.handleProfilePhotChange.bind(this);
		this.marksInputOnBlur = this.marksInputOnBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		var MyDate = new Date();
		var d =  MyDate.getFullYear()+'-'+('0' + (MyDate.getMonth()+1)).slice(-2)+'-'+('0' + MyDate.getDate()).slice(-2);
		this.setState({today: d});
		this.loadApplicantInfo();
		this.loadHscSubjects();
		//this.loadCaptcha();
	}
	
	loadApplicantInfo(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', this.context.api);
			API.post('v1/applicant/min_info',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({applicantInfo: res['data'].data, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadHscSubjects(){
		try{
			this.setState({showLoader: true, hscSubjects: []});			
			API.get('v1/hsc/subjects').then(res=>{
				if(res['data'].status=='1'){
					this.setState({hscSubjects: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadCaptcha(){
		try{
			API.get('v1/captcha').then(res=>{
				this.setState({captchaImg: res['data'].img, captchaCode: res['data'].code});
			});
		}
		catch(error){ }
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			var hasRegno=false;
			$(".hs_regno").each(function(k){
				if($(this).val()!='' && $(this).val()!=null) hasRegno = true;
			});
			
			if(!hasRegno)
			{
				$.alert({title:'Error',content:'Please Enter Your Higher Secondary register No in anyone of the above input box. உங்களுடைய உயர்கல்வி சான்றிதழின் பதிவு எண்ணை மேலே அதற்காக கொடுக்கப்பட்டுள்ள ஏதாவது ஒரு கட்டத்தில் பதிவு  செய்யவும்.!'});
				return false;
			}
			
			if(this.state.subject3=='' || this.state.subject4=='' || this.state.subject5=='' || this.state.subject6=='')
			{
				$.alert('Please fill all the subject');
				return false;
			}
			
			if(!window.confirm('Please check all the filled informations before processing. Do you want to proceed next?')){
				return;
			}
			
			this.setState({btnText: 'Processing..', showLoader: true});
			API.post('v1/ug/register/hscmarksupload', $("#frmStepII").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					//setls('logged_user', res['data'].user);
					this.context.updateUser(res['data'].user);
					this.setState({btnText: 'Save Marks', showLoader: false, redirectToCourseApply: true});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save Marks', showLoader: false});
					//this.loadCaptcha();
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Save Marks', showLoader: false});
			this.loadCaptcha();
		}
	}
	
	handleProfilePhotChange(files){
		
		this.setState({selectedFile: files.base64});
	}
	
	marksInputOnBlur(e){
		try{
			var $this = $(e.target);
			var min = $($this).attr('data-min');
			var max = $($this).attr('data-max');
			var val = e.target.value;
			
			if(parseFloat(val)<parseFloat(min) || parseFloat(val)>parseFloat(max)){
				alert('Please Enter Correct mark');
				$($this).val('');
			}
			
			this.updateScoredTotal();
		}
		catch(error){
			
		}
	}
	
	updateScoredTotal(){
		try{
			var scored = 0;
			var partTotal = 0;
			
			$(".i__mark_scored").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					scored = parseFloat(scored) + parseFloat(mark);
				}
			});
			
			$(".i__part3_scored").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					partTotal = parseFloat(partTotal) + parseFloat(mark);
				}
			});
			
			this.setState({scoredTotal: scored, part3Total: partTotal});
		}
		catch(error){ }
	}
		
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		if(this.state.isRedirect){
			return(<Redirect to="/app/dashboard" />);
		}
		
		if(this.state.redirectToCourseApply){
			return(<Redirect to="/app/ug-course-apply" />);
		}

		var minMark = 70;
		var maxMark = 200;
		var community = '';
		var subjectCount = 6;
		var board = 'state';
		if(Object.keys(this.state.applicantInfo).length>0 && this.state.applicantInfo.year_of_passing=='2019'){
			minMark = 35;
			maxMark = 100;
			//subjectCount = 5;

		}
		
		if(Object.keys(this.state.applicantInfo).length>0 && this.state.applicantInfo.board=='cbse'){
			board = 'cbse';
		}
		
		var year = [];
		
		if(maxMark==100)
		{
			for(var i= ((new Date()).getFullYear());i>=((new Date()).getFullYear()-10);i--){
				year.push(i);
			}
		}
		else if(maxMark==200)
		{
			for(var i= (2019);i>=(2019-10);i--){
				year.push(i);
			}
		}
		
		var profileImage = SELECT_IMAGE;
		if(this.state.selectedFile!='' && this.state.selectedFile.length>4)
			profileImage = this.state.selectedFile;
		
		
		if(Object.keys(this.state.applicantInfo).length>0){
			community = this.state.applicantInfo.community;
		}
		
		var subjects = [];
		this.state.hscSubjects.map((item,i)=>{
			subjects.push({value: item.id, label: item.name});
		});
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:10, offset:1}} >
								
								<InstructionForMarksUpload />
								
								<Card className="shadow-lg" >
									<Card.Body>
										<form action="" method="post" encType="multipart/form-data" id="frmStepII" onSubmit={this.handleFormSubmit} >
											
											<input type="hidden" name="i__userId" value={this.context.user.user_id} />
											<input type="hidden" name="i__userCode" value={this.context.user.user_code} />
											<input type="hidden" name="i__apiCode" value={this.context.user.api_code} />
											
											<Row>
												<Col md={12} >
													<div className='float-start'>
														<Link to="/app/ug/edit-personal" className="btn btn-info btn-sm">
															<i className="fa-solid fa-pen me-2"></i> Edit Personal Information
														</Link>
													</div>
													<div className="text-center" >
														<h5>Registration Step-II</h5>
														<hr />
													</div>
												</Col>
												<Col md={6} >
												<label>Name : <b className='text-uppercase'>{this.context.user.name+' '+this.context.user.initial}</b></label>
												</Col>
												<Col md={6} className="text-end" >
													<label>Registration No : <b>{this.context.user.user_code}</b></label>
												</Col>
												
											</Row>
											
										
										<Row className="mt-15" >
											<Col md={3} >
												<label>Medium of Study <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select name="i__medium" className="form-control form-control-sm" required >
													<option value=""> -Select- </option>
													<option value="tamil"> Tamil </option>
													<option value="english"> English </option>
												</select>
											</Col>
										</Row>

										<Row className="mt-15" >
											<Col md={3} >
												<label>Type of the School <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select name="i__schoolType" className="form-control form-control-sm" required >
													<option value=""> -Select- </option>
													<option value="govt"> Government </option>
													<option value="govt.aided"> Government Aided </option>
													<option value="private"> Private </option>
												</select>
											</Col>
										</Row>

										<Row className="mt-15" >
											<Col md={3} >
												<label>Name of the School Studied <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input type="text" name="i__school" className="form-control form-control-sm text-uppercase" onKeyPress={lettersOnly} required placeholder='School Name'/>
											</Col>
										</Row>

										<Row className="mt-15" >
											<Col md={3} >
												<label>Place of the School Studied <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input type="text" name="i__schoolPlace" className="form-control form-control-sm text-uppercase" onKeyPress={lettersOnly} required placeholder='School Place'/>
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="px-0 mt-20" >
												<table className="table table-bordered table-sm cl__tbl__marksupload" >
													<thead>
														<tr>
															<th width="220px" >Subject (பாடம்)</th>
															<th>Marks Scored (பெற்ற மதிப்பெண்)</th>
															<th>Maximum Marks (மொத்த மதிப்பெண்)</th>
															<th>Month & Year of Passing (தேர்ச்சி பெற்ற மாதம் / வருடம்)</th>
															<th>Register No (பதிவு எண்)</th>
															<th>No.of Attempts (முயற்சிகளின் எண்ணிக்கை)</th>
														</tr>
													</thead>
													<tbody>
														{(board=='cbse') ? null : <tr>
															<td className="" >Part-I : Language பகுதி I மொழிப்பாடம்
																<input type="hidden" name="i__subjects[]" value="5" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>}
														
														<tr>
															<td className="" >Part-II : English பகுதி II ஆங்கிலம்
															<input type="hidden" name="i__subjects[]" value="6" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>
														
														<tr>
															<td className="" style={{padding: '0px 5px'}} >
															{/*<select name="i__subjects[]" required className="cl__hsc__subjects" >
																	<option value=""> -Subject- </option>
																	{this.state.hscSubjects.map((item,i)=>{
																		return <option value={item.id} > {item.name} </option>;
																	})																		
																	}
															</select>*/}
															<Select placeholder="Search" 
																options={subjects}
																onChange={(e)=>{this.setState({subject3: e[0].value})}}
															/>
															<input type="hidden" name="i__subjects[]" value={this.state.subject3} />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored i__part3_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>
														
														<tr>
															<td className="" style={{padding: '0px 5px'}} >
															{/*<select name="i__subjects[]" required className="cl__hsc__subjects" >
																	<option value=""> -Subject- </option>
																	{this.state.hscSubjects.map((item,i)=>{
																		return <option value={item.id} > {item.name} </option>;
																	})																		
																	}
															</select>*/}
															<Select placeholder="Search" 
																options={subjects}
																onChange={(e)=>{this.setState({subject4: e[0].value})}}
															/>
															<input type="hidden" name="i__subjects[]" value={this.state.subject4} />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored i__part3_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>
														
														<tr>
															<td className="" style={{padding: '0px 5px'}} >
															{/*<select name="i__subjects[]" required className="cl__hsc__subjects" >
																	<option value=""> -Subject- </option>
																	{this.state.hscSubjects.map((item,i)=>{
																		return <option value={item.id} > {item.name} </option>;
																	})																		
																	}
															</select>*/}
															<Select placeholder="Search" 
																options={subjects}
																onChange={(e)=>{this.setState({subject5: e[0].value})}}
															/>
															<input type="hidden" name="i__subjects[]" value={this.state.subject5} />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored i__part3_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>
														
														<tr>
															<td className="" style={{padding: '0px 5px'}} >
															{/*<select name="i__subjects[]" required className="cl__hsc__subjects" >
																	<option value=""> -Subject- </option>
																	{this.state.hscSubjects.map((item,i)=>{
																		return <option value={item.id} > {item.name} </option>;
																	})																		
																	}
															</select>*/}
															<Select placeholder="Search" 
																options={subjects}
																onChange={(e)=>{this.setState({subject6: e[0].value})}}
															/>
															<input type="hidden" name="i__subjects[]" value={this.state.subject6} />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored i__part3_scored" data-min={minMark} data-max={maxMark} onBlur={this.marksInputOnBlur} name="i__markScored[]" required step=".01" />
															</td>
															<td>
																<input type="number" className="cl__no__arrow" value={maxMark} name="i__maxMarks[]" />
															</td>
															<td>
																<select name="i__passMonth[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{MonthData.map((item,i)=>{
																		return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
																	})
																	}
																</select>
																
																<select name="i__passYear[]" className="cl__no__arrow" required style={{padding: '3px'}} >
																	{year.map((item,i)=>{
																		return <option value={item} > {item} </option>;
																	})
																	}
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow hs_regno"  name="i__registerNo[]" style={{width: '100px'}}  />
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__attempts[]"  />
															</td>
														</tr>
														
														
														<tr>
															<td>Total மொத்தம்</td>
															<td>
																<input type="number" name="i__scoredTotal" className="cl__no__arrow"  style={{width: '80px'}} value={this.state.scoredTotal} />
															</td>
															<td>
																<input type="text" name="i__maxTotal" value={maxMark*subjectCount} style={{width: '80px'}} />
															</td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tbody>
												</table>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={6} >
												<label style={{fontSize: '14px'}} >
													Marks scored in Part III out of {maxMark*4}  <br />
													Part III ல் {maxMark*4} க்கு பெற்ற மதிப்பெண்கள்
													<span className="text-danger">*</span>
												</label>
											</Col>
											<Col md={5}>
												<input type="number" className="form-control form-control-sm" required name="i__part3Total" value={this.state.part3Total} required />
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={6} >
												<label style={{fontSize: '14px'}} >
													Community Details
													<span className="text-danger">*</span>
												</label>
											</Col>
											<Col md={5}>
												<input type="text" name="i__community" className="form-control form-control-sm" value={upperCase(community)} readOnly />
													{/*<select name="i__community" className="form-control form-control-sm" required onChange={(e)=>{this.setState({selectedCommunity: e.target.value})}} >
														<option value=""> -Select- </option>
														<option value="oc">OC ( மு.வ ) </option>
														<option value="bc">BC ( பி.வ ) </option>
														<option value="bcm">BC-Muslim ( பி.வ முஸ்லீம் ) </option>
														<option value="mbc" selected="">MBC ( மி.பி.வ ) </option>
														<option value="dnc">DNC ( மி.பி.வ ) </option>
														<option value="sc">SC ( ஆதி.தி ) </option>
														<option value="st">ST ( பழங்குடி ) </option>
													</select>*/}
											</Col>
										</Row>
										
										
										{community=='oc' ? <div>
												<input type="hidden" name="i__caste" value="" />
												<input type="hidden" name="i__issuingAuthority" value="" />
												<input type="hidden" name="i__certificateNo" value="" />
												<input type="hidden" name="i__issueDate" value="" />
												<input type="hidden" name="i__district" value="" />
												<input type="hidden" name="i__taluk" value="" />
										</div> : 
										<Row className="mt-15" id="i__community_container" >
											<Col md={12} >
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >Name of Sub Caste / சாதி உட்பிரிவின் பெயர் <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<input type="text" name="i__caste" className="form-control form-control-sm" required value={this.state.applicantInfo.caste} />
													</Col>
												</Row>
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >Issuing Authority / சான்றிதழ் வழங்கிய அதிகாரி <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<select name="i__issuingAuthority" className="form-control form-control-sm" required >
														<option value=""> SELECT </option>
														<option value="R.D.O"> R.D.O </option>
														<option value="ASST.COLLECTOR"> ASST.COLLECTOR </option>
														<option value="SUB.COLLECTOR"> SUB.COLLECTOR </option>
														<option value="PERSONAL ASSISTANT(GENERAL)"> PERSONAL ASSISTANT(GENERAL) </option>
														<option value="TALUK TAHSILDAR"> TALUK TAHSILDAR </option>
														<option value="TAHSILDAR"> TAHSILDAR </option>
														<option value="HEAD QUARTERS DEPUTY TAHSILDAR"> HEAD QUARTERS DEPUTY TAHSILDAR </option>
														<option value="SPECIAL DEPUTY TAHSILDAR"> SPECIAL DEPUTY TAHSILDAR </option>
														<option value="ADDL.HQ.DEPUTY.TAHSILDAR"> ADDL.HQ.DEPUTY.TAHSILDAR </option>
														<option value="ZONAL DEPUTY TAHSILDAR"> ZONAL DEPUTY TAHSILDAR </option>
														<option value="DEP TAHSILDAR(CERTIFICATE)"> DEPUTY TAHSILDAR(CERTIFICATE) </option>
														<option value="EXECUTIVE DEPUTY TAHSILDAR"> EXECUTIVE DEPUTY TAHSILDAR </option>
														<option value="OTHER AUTHORITY EMPOWRED TO ISSUE CERTIFICATE"> OTHER AUTHORITY EMPOWRED TO ISSUE CERTIFICATE </option>
														</select>
													</Col>
												</Row>
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >Certificate No / சான்றிதழ் எண் <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<input type="text" name="i__certificateNo" className="form-control form-control-sm" required />
													</Col>
												</Row>
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >Date of Issue / வழங்கப்பட்ட தேதி <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<input type="date" name="i__issueDate" className="form-control form-control-sm" required max={this.state.today} />
													</Col>
												</Row>												
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >District / மாவட்டம் <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<input type="text" name="i__district" className="form-control form-control-sm" required />
													</Col>
												</Row>
												<Row className="cl__row_btm_border mt-15" >
													<Col md={6} >
														<label style={{fontSize: '13px'}} >Taluk / வட்டம் <span className="text-danger">*</span></label>
													</Col>
													<Col md={5} >
														<input type="text" name="i__taluk" className="form-control form-control-sm" required />
													</Col>
												</Row>
											</Col>
										</Row>
										}
											
											{/*<Row className="mt-15" >
										<Col md={3} ><label>Captcha <span className="text-danger" >*</span></label></Col>
										<Col md={2} >
											<img src={this.state.captchaImg} />
										</Col>
										<Col md={4}  >
											<input type="text" className="form-control form-control-sm" placeholder="Captcha" name="i__captcha" required />
										</Col>
										<Col md={3} >
											<button className="btn btn-outline-secondary btn-sm" type="button" onClick={this.loadCaptcha} >
												<i className="ion-ios7-reload" /> Refresh
											</button>
										</Col>
											</Row>*/}
									
											
										
										
											<input type="hidden" name="i__captchaCode" value={this.state.captchaCode} />
											
											<Row className="mt-15" >
												<Col md={12} >
													<button type="submit" className="btn btn-success btn-block" disabled={this.state.btnText!='Save Marks'} >
														{this.state.btnText}
													</button>
												</Col>
											</Row>
											
										</form>
										
										<div className="py-50" ></div>
										
										
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default MarksUpload;
