import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Button} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var, MonthData, Api} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, getlsItem, upperCase} from '../../../utils';
import {PageLoader} from '../../elements';

import ApplicationPaymentReceipt from './ApplicationPaymentReceipt';

class ApplicationDownloadList extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			isRedirect: false,
			redirectTo: '',
			redirectParams: [],
			showPrint: false,
			printData: [],
		}

	}
	
	componentDidMount(){
		
		this.loadApplicationList();
	}
	
	loadApplicationList(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			API.post('v1/ug/courses',form).then(res=>{
				if(res['data'].status=='1'){
					var d = groupByMultiple(res['data'].data, function(obj){
						return[obj.uuid, obj.course_type];
					});
					this.setState({dataList: res['data'].data, dataView: d, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getCourses(item){
		var tr = [];
		try{
			
			item.map((course,i)=>{
				tr.push(<tr key={i} >
					<td>{course.preference}</td>
					<td>
					{course.installment_pay_status=='1' ? <a href="javascript:;" onClick={this.onClick.bind(this,course)}>Print Payment Receipt</a> : null}	
					</td>
					<td>{course.degreename}-{course.coursename}
					</td>
					<td>{capitalizeFirst(course.medium)}</td>
					<td>{course.fee_paid_status =='1' ? 'Paid' : 'Un Paid'}</td>
				</tr>);
			});
			return tr;
		}
		catch(error){
			return tr;
		}
	}
	
	onClick(course){
		this.setState({printData: course, showPrint: true});
	}
	
	
	getDownLoadLink(items){
		var tr = [];
		try{
			var item = items[0];
			if(item.fee_paid_status=='1'){
				tr.push(<button type="button" className="btn btn-sm btn-primary" onClick={this.handleDownloadClick.bind(this,item)} >
					Download
				</button>);
			}
			else{
			//else if(item.fee_paid_status=='0'){
				tr.push(<div>
					{(item.fee_paid_status=='0' && item.order_id!=null && item.order_id!='' && upperCase(item.fee_paid_response) != upperCase('Aborted')) ? <a href="javascript:;" onClick={this.trackMyOrderStatus.bind(this,item)} className='btn btn-secondary btn-block btn-sm me-1 mb-2' >
					<i className="fa-solid fa-shuffle"></i> Track Status
				</a> : null}
				<button type="button" className="btn btn-sm btn-block btn-warning" onClick={this.handleRepayClick.bind(this,items)} >
					Pay Now
				</button>
				</div>);
			}
			return tr;
		}
		catch(error){
			
			return tr;
		}
	}

	trackMyOrderStatus(course){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__apiCode', this.context.api);
			form.append('uuid', course.uuid);
			form.append('id', course.id);
			
			API.post('v1/ug/trackmyorder', form).then(res=>{
				if(res['data'].status=='1'){
					//notify.show(res['data'].message, 'success');
					this.loadApplicationList();
					this.setState({showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	handleRepayClick(items){
		
		this.setState({redirectParams: items, redirectTo: '/app/ug/repay', isRedirect: true});
	}
	
	handleDownloadClick(item){
		try{
			
			this.setState({showLoader: true});
			//console.log(Api.Url +'v1/ug/pdf/'+item.application_code+'/'+item.api_code);
			/* var link = document.createElement('a');
			link.href = Api.Url +'v1/ug/pdf/'+item.application_code+'/'+item.api_code;
			link.download = item.application_code+'.pdf';
			link.dispatchEvent(new MouseEvent('click')) */;
			var filePath = Api.Url +'/v1/ug/pdf/'+item.admission_year+'/'+item.uuid+'/'+item.application_code;
			//console.log(filePath);
			//return;
			var a = document.createElement('A');
			a.href = filePath;
			a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			this.setState({showLoader: false});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
				
		let sno=1;
		
		if(this.state.isRedirect){
			return(<Redirect to={{
					pathname: this.state.redirectTo,
					state: this.state.redirectParams
			}} />);
		}
		
		return(								
				<Card className="shadow-lg" >
					<Card.Body>
						
						<PageLoader loading={this.state.showLoader} />
						
						<Row>
							<Col md={12} >
								<div className="text-center" >
									<h5>Download Filled Applications</h5>
									<hr />
								</div>
							</Col>
						</Row>
						
						<Row>
							<Col md={12} >
								<div className="table-responsive" >
								<table className="table table-bordered table-sm" >
									<thead>
										<tr className="bg-red-light-4" >
											<th>Application Status</th>
											<th>Course Type</th>
											<th>Application No</th>
											<th>Preference</th>
											<th>Payment Receipt</th>
											<th>Course Name</th>
											<th>Medium</th>
											<th>Fee Status</th>
										</tr>
									</thead>
									<tbody>
									{this.state.dataView.map((item,i)=>{
										return <React.Fragment>
											<tr key={i} >
											<td align="center" rowSpan={Object.keys(item).length+1} >
												{this.getDownLoadLink(item)}
											</td>
											<td rowSpan={Object.keys(item).length+1} >{capitalizeFirst(item[0].course_type)}</td>
											<td rowSpan={Object.keys(item).length+1} >{item[0].application_code}</td>
										</tr>
										{this.getCourses(item)}
										</React.Fragment>;
									})
									}
									</tbody>
								</table>
								</div>
							</Col>
						</Row>
												
						
						{this.state.showPrint ? <ApplicationPaymentReceipt data={this.state.printData} atClose={()=>{this.setState({printData: [], showPrint: false})}} /> : null}
						
					</Card.Body>
				</Card>
		);
	}
	
}
export default ApplicationDownloadList;
