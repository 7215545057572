import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, lowerCase, upperCase} from '../../../utils';

import  ApplicationDownloadList from '../UG/ApplicationDownloadList';
import  PGApplicationDownloadList from '../PG/ApplicationDownloadList';
import  MPHILApplicationDownloadList from '../MPHIL/ApplicationDownloadList';
import ChangePassword from '../Admission/ChangePassword';

import AdmissionFeePayment from '../UG/AdmissionFeePayment';
import PGAdmissionFeePayment from '../PG/AdmissionFeePayment';
import MPHILAdmissionFeePayment from '../MPHIL/AdmissionFeePayment';
import PsContext from '../../../context';

class DahboardMenu extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showPasswordModal: false,
		}
	}
	
	
	
	render(){
		
		var applyFor = lowerCase(this.context.user.applied_for);
		
				return(
			<div className="container mt-30 pt-30" >
				
				<Row >
					<Col md={{span:10, offset:1}} >
						<Card className="shadow-lg" >
							<Card.Body>
								<h5>Dashboard Menu</h5>
								<hr className="my-5" />
								<Row className="mt-10" >
									
									{/*<Col md={12} >
										{applyFor=='ug' ? <AdmissionFeePayment /> : <PGAdmissionFeePayment />}
				</Col>*/}
									
									<Col md={3} >
										<div className="text-center bg-gradient-primary text-white py-15" >
											<i className="ion-ios7-contact" style={{fontSize: '45px'}} />
											<div>View Profile</div>
										</div>
									</Col>
									
									<Col md={3} >
										<Link to={"/app/"+applyFor+"-course-apply"} >
											<div className="text-center bg-gradient-success text-white py-15" >
												<i className="ion-plus" style={{fontSize: '45px'}} />
												<div>Apply Course</div>
											</div>
										</Link>
									</Col>
									
									<Col md={3} >
										<Link to={"/app/"+applyFor+"-application-download"} >
											<div className="text-center bg-gradient-space text-white py-15" >
												<i className="ion-clipboard" style={{fontSize: '45px'}} />
												<div>Download</div>
											</div>
										</Link>
									</Col>
									
									<Col md={3} >
										<div className="text-center bg-gradient-heaven text-white py-15" onClick={()=>{this.setState({showPasswordModal:true})}} style={{cursor: 'pointer'}} >
											<i className="ion-compose" style={{fontSize: '45px'}} />
											<div>Change Password</div>
										</div>
									</Col>
									
								</Row>
							</Card.Body>
						</Card>
						
						<div className="mt-30" >
							{upperCase(this.context.user.applied_for)=='UG' ? 
								<ApplicationDownloadList /> :
								upperCase(this.context.user.applied_for)=='PG' ?
								<PGApplicationDownloadList /> :
								<MPHILApplicationDownloadList />
							}
						</div>
						
					</Col>
				</Row>
				
				<Modal show={this.state.showPasswordModal} onHide={()=>{this.setState({showPasswordModal:false})}}  >
					<Modal.Header closeButton >
						<Modal.Title>Change Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						
						<ChangePassword />
						
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default DahboardMenu;
