import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Var} from '../../../utils/Variables';
import {getlsItem} from '../../../utils';
import PsContext from '../../../context';

class CourseApplyFor extends Component{

	static  contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			
		}

	}
	
	componentDidMount(){
	
	}
	
	
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		return(
			<React.Fragment>
								
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:6, offset:3}} >
								
								
								
								<Card className="shadow-lg" >
									<Card.Body>
										
										<Row>
											<Col md={12} >
												<div className="text-center" >
													<h5>Select any one Option</h5>
													<hr />
												</div>
											</Col>
										</Row>
										
										{this.context.settingValue('admission_has_pg_regular')=='1'&& (
										<Row className="" style={{marginBottom: '25px'}} >
											<Col md={{span:10, offset:1}} >
												<Link className="btn btn-success btn-lg btn-block" to="/app/pg-regular-course-apply" >
												<i className="ion-clipboard" /> APPLY FOR REGULAR COURSE
												</Link>
											</Col>
										</Row>)}
										
										{this.context.settingValue('admission_has_pg_self')=='1'&& (
										<Row className="mt-5 mb-20" >
											<Col md={{span:10, offset:1}} >
												<Link className="btn btn-warning btn-lg btn-block" to="/app/pg-self-course-apply" >
													<i className="ion-clipboard" /> APPLY FOR SELF COURSE
												</Link>
											</Col>
										</Row>
										)}
										
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default CourseApplyFor;
