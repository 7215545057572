const COOKIE_KEY = "cc22";

const Api = {
  // localhost
  //Url :'http://localhost/nmcp-admission/api/',

  // Christopher College
  //Url :'https://christophercollege.kalvisalai.in/manage/api',

  //TDMNS College
  //Url :'https://admission.tdmnscollege.edu.in/services',

  //Sarah Tucker College
  //Url :'https://svncollege.kalvisalai.in/admission/api',

  // Sri Parasakthi College - Coutallam
  //Url :'https://admission.sriparasakthicollege.edu.in/api',

  // St.Johns College
  //Url :'https://admission.stjohnscollege.edu.in/api',

  // Thiruvalluvar College - Papanasam
  //Url :'https://thiruvalluvarcollege.kalvisalai.in/api',

  // Margocis Collge - Nazareth
  Url: "https://nmcpadmission.kalvisalai.in/manage/api/",
};

const Var = {
  Title: "",
  Site: "",
  Phone: "",
  Email: "",
  Address: "",
  City: "",
  Pincode: "",
  RegularCourseFeeForScSt: 100,
  RegularCourseFeeForOthers: 100,
  SelfCourseFeeForScSt: 100,
  SelfCourseFeeForOthers: 100,
  EnableCashPayment: false,
  EnableOnlinePayment: true,

  PGRegularCourseFeeForScSt: 100,
  PGRegularCourseFeeForOthers: 100,
  PGSelfCourseFeeForScSt: 100,
  PGSelfCourseFeeForOthers: 100,
  PGEnableCashPayment: false,
  PGEnableOnlinePayment: true,
};

const MonthData = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export { COOKIE_KEY, Api, Var, MonthData };

export const loginFor = [
  { text: "UG Courses", value: "ug", key: "enable_ug_admission" },
  { text: "PG Courses", value: "pg", key: "enable_pg_admission" },
  { text: "MPhil Courses", value: "mphil", key: "enable_mphil_admission" },
];

export const siteMenus = [
  {
    title: "UG Registration",
    to: "/ug-admission-instruction",
    icon: "fa-solid fa-file-circle-plus",
    key: "enable_ug_admission",
  },
  {
    title: "PG Registration",
    to: "/pg-admission-instruction",
    icon: "fa-solid fa-file-circle-plus",
    key: "enable_pg_admission",
  },
  {
    title: "Mphil Registration",
    to: "/mphil-admission-instruction",
    icon: "fa-solid fa-file-circle-plus",
    key: "enable_mphil_admission",
  },
  { title: "Login", to: "/", icon: "fa-solid fa-arrow-right-from-bracket" },
];

export const ugMenus = [
  { title: "Dashboard", to: "/app/dashboard", icon: "fa-solid fa-house" },
  {
    title: "Apply Course",
    to: "/app/ug-course-apply",
    icon: "fa-solid fa-notes-medical",
  },
  {
    title: "Download",
    to: "/app/ug-application-download",
    icon: "fa-solid fa-cloud-arrow-down",
  },
];

export const pgMenus = [
  { title: "Dashboard", to: "/app/dashboard", icon: "fa-solid fa-house" },
  {
    title: "Apply Course",
    to: "/app/pg-course-apply",
    icon: "fa-solid fa-notes-medical",
  },
  {
    title: "Download",
    to: "/app/pg-application-download",
    icon: "fa-solid fa-cloud-arrow-down",
  },
];

export const mphilMenus = [
  { title: "Dashboard", to: "/app/dashboard", icon: "fa-solid fa-house" },
  {
    title: "Apply Course",
    to: "/app/mphil-course-apply",
    icon: "fa-solid fa-notes-medical",
  },
  {
    title: "Download",
    to: "/app/mphil-application-download",
    icon: "fa-solid fa-cloud-arrow-down",
  },
];
