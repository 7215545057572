import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Var} from '../../../utils/Variables';
import {getls, getlsItem} from '../../../utils';

import ApplicationDownloadList from './ApplicationDownloadList';

class ApplicationDownload extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			
		}

	}
	
	componentDidMount(){
	
	}
	
	
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		if(getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0'){
			return(<Redirect to="/app/dashboard" />);
		}
		
		
		return(
			<React.Fragment>
								
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:10, offset:1}} >
								
								<ApplicationDownloadList />
								
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default ApplicationDownload;
