import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Table, Button} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var, MonthData, Api} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, upperCase} from '../../../utils';
import {PageLoader} from '../../elements';

import multiDownload from 'multi-download';
import LOGO from '../../../assets/img/spc-logo.png';

class ApplicationPaymentReceipt extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			showPasswordModal: false,
			showLoader: false,
			dataList: [],
			rePayData: [],
			reqData: [],
			userData: [],
			submitReqForm: false,
			courseData: this.props.data,
		}
	}
	
	
	componentDidMount(){
		//console.log(this.props.data);
		this.printDocument("printDiv");
	}
	
	getCourseField(fieldName){
		try{
			
			var d = this.state.courseData;
			return d[fieldName];
		}
		catch(error){
			return '0';
		}
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	
	render(){
		
		return(
		<div style={{display: 'none'}} >
				<div id="printDiv" style={{backgroundColor: '#fff'}} >
					<table  align="center" width="100%" >
						<tr>
							<td style={{padding: '10px'}} >
								<table width="95%" align="center" >
									<tr>
										<td >
											<img src={LOGO} style={{width: '60%'}} />
											<br /><br />
										</td>
										<td width="35%"  >
											Ref. Id : {this.getCourseField('installment_orderid')}
											<br />
											Ref. Date : {this.getCourseField('installment_orderdate')}
										</td>
									</tr>
									<tr>
										<td colSpan="2" style={{borderTop: '1px solid grey'}} ></td>
									</tr>
									<tr>
										<td colSpan="2" >
											<br />
											<table width="95%" align="center" >
												<tr>
													<td height="35" width="30%" >Name</td>
													<td> : </td>
													<td>{getls('logged_user', 'name')}</td>
												</tr>
												<tr>
													<td height="35" >Mobile</td>
													<td> : </td>
													<td>{getls('logged_user', 'mobile')}</td>
												</tr>
												<tr>
													<td height="35" >Email Id</td>
													<td> : </td>
													<td>{getls('logged_user', 'email')}</td>
												</tr>
												<tr>
													<td  height="35" >Course Name</td>
													<td> : </td>
													<td>{this.getCourseField('degreename')} - {this.getCourseField('coursename')}</td>
												</tr>
												<tr>
													<td  height="35" >Total</td>
													<td> : </td>
													<td>Rs. {this.getCourseField('installment_amount')}</td>
												</tr>
												<tr>
													<td  height="35" >Status</td>
													<td> : </td>
													<td>{this.getCourseField('installment_status')}</td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>

			</div>
				
		);
	}
	
	
}
export default ApplicationPaymentReceipt;
