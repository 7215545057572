import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getls, getlsItem, lowerCase} from '../../../utils';

import UGPersonalInformation from '../UG/PersonalInformation';
import HSCMarksUpload from '../UG/MarksUpload';
import UGCourseApplyFor from '../UG/CourseApplyFor';
import CertificateUploading from '../UG/CertificateUploading';
import DahboardMenu from './DahboardMenu';

import PGPersonalInformation from '../PG/PersonalInformation';
import PGMarksUpload from '../PG/MarksUpload';
import PGCourseApplyFor from '../PG/CourseApplyFor';
import PGCertificateUploading from '../PG/CertificateUploading';

import MPHILPersonalInformation from '../MPHIL/PersonalInformation';
import MPHILMarksUpload from '../MPHIL/MarksUpload';
import MPHILCourseApplyFor from '../MPHIL/CourseApplyFor';
import MPHILCertificateUploading from '../MPHIL/CertificateUploading';
import PsContext from '../../../context';


class Dashboard extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {

		}
	}
	
	getDisplayForm(){
		
		var applyFor =  lowerCase(this.context.user.applied_for); //getls('logged_user', 'applied_for');
		
		if(applyFor=='ug'){
			//if(getls('logged_user', 'registration_completed')=='0'){
			if(this.context.user.registration_completed=='0'){
				return <UGPersonalInformation />;
			}
			else if(this.context.settingValue('admission_ug_certupload_atsecond_step')=='1' && this.context.user.certificates_uploaded=='0'){
				return <CertificateUploading />;
			}
			//else if(getls('logged_user', 'marks_uploaded')=='0'){
			else if(this.context.user.marks_uploaded=='0'){
				return <HSCMarksUpload />;
			}			
			//else if(getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0'){
			else if(this.context.user.regular_course_applied=='0' && this.context.user.self_course_applied=='0'){
				return <UGCourseApplyFor />;
			}
			//else if(getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0'){
			/*else if(this.context.user.regular_course_applied=='0' && this.context.user.self_course_applied=='0'){
				return <CertificateUploading />;
			}*/
			//else if(getlsItem('certificate_upload')=='1'){
			else{
				return <DahboardMenu />;
			}
		}
		else if(applyFor=='pg'){
			//if(getls('logged_user', 'registration_completed')=='0'){
			if(this.context.user.registration_completed=='0'){
				return <PGPersonalInformation />;
			}

			else if(this.context.settingValue('admission_pg_certupload_atsecond_step')=='1' && this.context.user.certificates_uploaded=='0'){
				return <PGCertificateUploading />;
			}
			else if(this.context.user.marks_uploaded=='0'){
				return <PGMarksUpload />;
			}
			//else if(getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0'){
			else if(this.context.user.regular_course_applied=='0' && this.context.user.self_course_applied=='0'){
				return <PGCourseApplyFor />;
			}
			else{
				return <DahboardMenu />;
			}
		}
		else if(applyFor=='mphil'){
			//if(getls('logged_user', 'registration_completed')=='0'){
			if(this.context.user.registration_completed=='0'){
				return <MPHILPersonalInformation />;
			}
			//else if(getls('logged_user', 'marks_uploaded')=='0'){
			else if(this.context.user.marks_uploaded=='0'){
				return <MPHILMarksUpload />;
			}
		/* 	else if(getls('logged_user', 'certificates_uploaded')=='0'){
				return <PGCertificateUploading />;
			} */
			//else if(getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0'){
			else if(this.context.user.regular_course_applied=='0' && this.context.user.self_course_applied=='0'){
				return <MPHILCourseApplyFor />;
			}
			else{
				return <DahboardMenu />;
			}
		}

	}
	
	render(){
		
		//if(getlsItem('logged')!='yes'){
		if(this.context.logged!='yes'){
			return(<Redirect to="/" />);
		}
		
		var applyFor = getls('logged_user', 'applied_for');
		
		
		return(
			<React.Fragment>
			
				{this.getDisplayForm()}
			
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
