import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, getlsItem, setls, upperCase, setlsItem} from '../../../utils';
import {PageLoader} from '../../elements';
import ReactFileReader from 'react-file-reader';

import SELECT_IMG from '../../../assets/img/select-doc.png';

import NotFoundAlert from '../NotFound/NotFoundAlert';
import PsContext from '../../../context';

const styles = {
	imgHolder:{
		marginLeft: 'auto',
		marginRight: 'auto',
		border: '1px solid #dad6d6',
		lineHeight: '20px',
		padding: '10px',
	}
}

class CertificateUploading extends Component{

	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'UPLOAD',
			showLoader: false,
			isRedirect: false,
			rePayData: [],
			applicantInfo: [],
			certificates: [],
		}
		
		this.handleUploadFormSubmit = this.handleUploadFormSubmit.bind(this);
		
	}
	
	componentDidMount(){
		
		this.loadApplicantInfo();
	}
	
	loadApplicantInfo(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			API.post('v1/pgapplicant/min_info',form).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var c = [];
					if(this.context.settingValue('admission_pg_certupload_atsecond_step')=='1')
					{
						if(d['community']!='oc' && this.context.settingValue('admission_pg_cert_commu_require')=='1' )
							c.push({name: 'community', value: ''});
						
						//c.push({name: 'transfer', value: ''});
						//c.push({name: 'signature', value: ''});
						
						if(this.context.settingValue('admission_ug_cert_tc_require')=='1') c.push({name: 'transfer', value: ''});

						if(this.context.settingValue('admission_ug_cert_marksheet_require')=='1')
						{
							c.push({name: 'sem_1_marksheet', value: ''});
							c.push({name: 'sem_2_marksheet', value: ''});
							c.push({name: 'sem_3_marksheet', value: ''});
							c.push({name: 'sem_4_marksheet', value: ''});
							c.push({name: 'sem_5_marksheet', value: ''});
						}

						if(d['ex_army_child']=='1' && this.context.settingValue('admission_pg_cert_exservice_require')=='1')	c.push({name: 'exarmy', value: ''});
						if(d['physically_challanged']=='1' && this.context.settingValue('admission_pg_cert_physical_require')=='1' )	c.push({name: 'physical', value: ''});
						if(d['in_sports']=='1' && this.context.settingValue('admission_pg_cert_sports_require')=='1' )	c.push({name: 'sports', value: ''});
						if(d['in_nss']=='1' && this.context.settingValue('admission_pg_cert_nss_require')=='1' )	c.push({name: 'nss', value: ''});
						if(d['in_ncc']=='1' && this.context.settingValue('admission_pg_cert_ncc_require')=='1' )	c.push({name: 'ncc', value: ''});
					}
					
					this.setState({applicantInfo: res['data'].data, certificates: c, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getApplicant(fieldName){
		try{
			var d = this.state.applicantInfo;
			return d[fieldName];
		}
		catch(error){return false;}
	}
	
	handleUploadFormSubmit(e){
		e.preventDefault();
		try{
			var c = this.state.certificates;
			for(var i=0;i<Object.keys(c).length;i++){
				
				if(c[i].value=='' || c[i].value.length<5){
					$.alert({type: 'red', title: 'Error', content: `Select ${c[i].name} certificate`});	
					return;
				}
			}
						
			/* if(c[0].hasOwnProperty('exarmy')){
				if(c[0].exarmy=='' || c[0].exarmy.length<5){
					$.alert({type: 'red', title: 'Error', content: 'Select Ex-army certificate'});
					return;
				}
			}
			 */
			 
			if(!window.confirm('Do you want to upload the certificates?')){
				return;
			}
			
			this.setState({showLoader: true, btnText: 'Processing'});
			//frmrePay
			API.post('v1/pg/register/uploadcertificates', $("#frmCerti").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					//setls('logged_user', res['data'].user);
					//setlsItem('certificate_upload', '0');
					this.context.updateUser(res['data'].user);
					this.setState({showLoader: false, isRedirect: true});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'UPLOAD'});
				}
			}); 
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'UPLOAD'});
		}
	}
	
	handleCertificateSelection(name, file){
		try{
			//console.log(file.fileList[0].type);
			var types = ['image/png', 'image/jpeg', 'image/JPEG', 'image/jpg', 'image/PNG', 'image/JPG'];
			if(!types.includes(file.fileList[0].type)){
				notify.show('Please select an Image with file extension .jpg or .png', 'error');
				return;
			}
			
			if(file.fileList[0].size > 120000)
			{
				notify.show('File size must less than 120 kb', 'error');
				return;
			}
			
			var c = this.state.certificates;
			var index = c.findIndex(function(obj){
				return (obj.name==name);
			});
			if(index>-1){
				c[index].value = file.base64;
				//console.log(c);
				this.setState({certificates: c});
			}
		}
		catch(error){
			console.log(error);
		}
	}
	
	getCertificate(name){
		try{
			var c = this.state.certificates;
			var v = c.filter(function(obj){
				return(obj.name==name);
			});
			if(v[0].value=='' || v[0].value.length<5) return SELECT_IMG;
			else return v[0].value;
		}
		catch(error){
			return SELECT_IMG;
		}
	}
	
		
	render(){
		
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		if(this.state.isRedirect){
			return(<Redirect to="/app/dashboard" />);
		}
		
				
		let sno=1;
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				
				<section>
					<div className="container mt-50 pb-30" >
						
						<Row>
							<Col md={12} >
								<Card className="mb-20" >
									<Card.Body style={{fontSize: '13px'}} >
										<ul className="mx-5 px-5" style={{lineHeight: '23px'}} >
											<li>பதிவேற்றக்கூடிய அணைத்து சான்றிதழ்களும் (.jpg)  அல்லது (.png) பார்மட்டில் மட்டும் இருக்க வேண்டும்.</li>
											<li>120 kb வரை உள்ள சான்றிதழ்களை மட்டுமே பதிவேற்றம் செய்ய இயலும்.</li>
											<li>தெளிவாக ஸ்கேன் செய்யப்பட்ட சான்றிதழ்களை மட்டும் பதிவேற்றம் செய்ய வேண்டும்.</li>
										</ul>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body>
										<form action="" method="post" encType="mutipart/form-data" id="frmCerti" onSubmit={this.handleUploadFormSubmit} >
											
											<input type="hidden" name="i__userId" value={getls('logged_user', 'user_id')} />
											<input type="hidden" name="i__userCode" value={getls('logged_user', 'user_code')} />
											<input type="hidden" name="i__apiCode" value={getls('logged_user', 'api_code')} />
											<input type="hidden" name="i__data" value={JSON.stringify(this.state.certificates)} />							
											<Row>
												<Col md={12} >
													<div className="text-center border-bottom" >
														<h5>Certificate Uploading</h5>
													</div>
												</Col>
											</Row>
											
											<Row>
												<Col md={12} className='text-center'>
													<label className="fw-bold my-20" >Click over the Image to select Certificate from your local computer..</label>
												</Col>
												{this.state.certificates.map((item,i)=>{
													return <Col md={3} className="mt-15 pb-20"   >
															<center style={styles.imgHolder} >
																<label><b>{upperCase(item.name)} {item.name=='signature' ? null : 'Certificate'}</b></label>
																<br /><br />
																<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,item.name)}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]}  >
																  <img src={this.getCertificate(item.name)} style={{width: '150px', cursor: 'pointer'}}  />
																</ReactFileReader>
															</center>
														</Col>;
												})
												}
												{/*
												<Col md={3} className="mt-15 pb-20" >
													<center style={styles.imgHolder} >
														<label><b>Community Certificate</b></label>
														<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,'community')}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]}  >
														  <img src={this.getCertificate('community')} style={{width: '150px', cursor: 'pointer'}}  />
														</ReactFileReader>
													</center>
												</Col>
												<Col md={3} className="mt-15 pb-20"  >
													<center style={styles.imgHolder} >
														<label><b>HSC Mark Sheet</b></label>
														<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,'marksheet')}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]} >
														  <img src={this.getCertificate('marksheet')} style={{width: '150px', cursor: 'pointer'}}  />
														</ReactFileReader>
													</center>
												</Col>
												
												{this.getApplicant('in_sports')=='1' ? <Col md={3} className="mt-15 pb-20"   >
													<center style={styles.imgHolder} >
														<label><b>Sports Certificate</b></label>
														<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,'sports')}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]}  >
														  <img src={this.getCertificate('sports')} style={{width: '150px', cursor: 'pointer'}}  />
														</ReactFileReader>
													</center>
												</Col> : null}
												
												{this.getApplicant('physically_challanged')=='1' ? <Col md={3} className="mt-15 pb-20"   >
													<center style={styles.imgHolder} >
														<label><b>Physical Certificate</b></label>
														<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,'physical')}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]}  >
														  <img src={this.getCertificate('physical')} style={{width: '150px', cursor: 'pointer'}}  />
														</ReactFileReader>
													</center>
												</Col> : null}
												
												{this.getApplicant('ex_army_child')=='1' ? <Col md={3} className="mt-15 pb-20"  >
													<center style={styles.imgHolder} >
														<label><b>Ex-army Certificate</b></label>
														<ReactFileReader handleFiles={this.handleCertificateSelection.bind(this,'exarmy')}  base64={true} fileTypes={[".jpg", ".png",".JPG",".PNG"]}  >
														  <img src={this.getCertificate('exarmy')} style={{width: '150px', cursor: 'pointer'}}  />
														</ReactFileReader>
													</center>
												</Col> : null}
												*/
	}
												
											</Row>
											
											<Row>
												<Col md={12} >
													<hr />
												</Col>
												<Col md={12} >
													<button type="submit" className="btn btn-success btn-block" disabled={this.state.btnText!='UPLOAD'} >
														{this.state.btnText}
													</button>
												</Col>
											</Row>
											
										</form>										
									</Card.Body>
								</Card>
							</Col>
						</Row>
						
					</div>
				</section>
				
				
			</React.Fragment>
		);
	}
	
}
export default CertificateUploading;
