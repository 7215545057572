import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import PsContext from '../../../context';
import API from '../../../utils/API';

const HelpLinePage=(props)=>{

    const context = useContext(PsContext);

    const [data, setData] = useState([]);

    const loadData=()=>{
        API.get('v1/page?slug=admission-helpline-numbers').then(res=>{
            if(res['data'].status=='1'){
                setData(res['data'].data);
            }
        });
    };

    useEffect(()=>{

        loadData();
    }, []);

    return(
        <>
            {data &&  <Card>
                <Card.Body>
                    <div dangerouslySetInnerHTML={{__html : data.content }} />
                </Card.Body>
            </Card>}
        </>
    );

};
export default HelpLinePage;