import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import './assets/font-awesome/css/all.css';
import './assets/ionicons/css/ionicons.min.css';
import './assets/custom.css';


import App from './App';

import './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

