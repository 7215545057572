import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import LOGO from '../../../assets/img/spc-logo.png';

class InstructionHeaderResponsive extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	componentDidMount(){
		   $('[data-toggle="offcanvas"]').on('click', function () {
			$('.offcanvas-collapse').toggleClass('open')
		  });
	}
	
	render(){
		
		
		return(
		<>
		{/*<nav className="navbar navbar-expand-md fixed-top bg-white bb-1">
		  <Link to="/" className="navbar-brand" >
			<img src={LOGO} style={{width: '280px'}} /> 
		  </Link>
		  <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
			<span className="navbar-toggler-icon"></span>
		  </button>

		  <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
			<ul className="navbar-nav ml-auto">
				<li className="nav-item">
					<Link to="/" className="nav-link" >Back to Login</Link>
				</li>
			</ul>
			
		  </div>
		</nav>
		<div className="nav-scroller bg-theme box-shadow mt-15">
		  <nav className="nav nav-underline">
		  
			<ul className="navbar-nav mr-auto ml-auto">
				<li className="nav-item" >			
					<a href="javascript:;" className="nav-link text-white" >
					{this.props.title}
					</a>
				</li>
			</ul>
			
		  </nav>
		</div>
		*/}
		</>
		);
	}
	
}
export default InstructionHeaderResponsive;
