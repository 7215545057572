import axios from 'axios';
import {Api} from './Variables';

export default axios.create({
  baseURL: Api.Url,
  headers: {
		"X-Requested-With": "XMLHttpRequest",
	}
  /*timeout: 1000,*/
 /* headers: {
	  'Content-Type': 'multipart/form-data',
	'PARAM_HEADER': 'eyJ0eXAiOiJKV1QiLC'
  }*/
});