import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $, { timers } from 'jquery';
import {Form ,Row,Col, Carousel, Card, Button,} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {loginFor, Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {setls, setlsItem, getlsItem} from '../../../utils';
import LOGO from '../../../assets/img/spc-logo.png';

import {MsgBox} from '../../elements';
import PsContext from '../../../context';


class LoginForm extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			loginText: 'LOGIN',
			captchaImg: '',
			captchaCode: '',
			isRedirect: false,
			validated: false,
			setValidated: false,
		}
		
		this.loadCaptcha = this.loadCaptcha.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.loadCaptcha();
	}
	
	loadCaptcha(){
		try{
			API.get('v1/captcha').then(res=>{
				this.setState({captchaImg: res['data'].img, captchaCode: res['data'].code});
			});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		 e.preventDefault();
		try{
			
			 const form = e.currentTarget;
			if (form.checkValidity() === false) {
			  e.stopPropagation();
			  this.setState({validated: true});
			  return;
			}
			
			this.setState({showLoader: true, loginText: 'Validating..'});
			API.post('v1/applicant/login', $("#frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message, 'success');
					/*setlsItem('logged', 'yes');
					setlsItem('certificate_upload', res['data'].prefer);
					setls('logged_user', res['data'].user);
					this.setState({showLoader: false, loginText: 'Redirecting..', isRedirect: true});*/
					this.context.saveLogin(res['data'].user, res['data'].prefer, res['data'].api);
					this.context.updateLogged();
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, loginText: 'LOGIN'});
					this.loadCaptcha();
				}
			});			
		}
		catch(error){
			this.setState({showLoader: false, loginText: 'LOGIN'});
			this.loadCaptcha();
		}
	}
	
	
	render(){
		
		if(this.state.isRedirect || getlsItem('logged')=='yes'){
			return(<Redirect to="/app/dashboard" />);
			window.location.href = '';
		}
		
		
	const options = [
		{value: 'sv', label: 'Swedish'},
		{value: 'en', label: 'English'},
	];
		return(
		<>
		
			<Card  className="shadow-lg">
				<Card.Body>
					<div className="text-center" >
						<h5>Applicant Login</h5>
						<hr />
						{/*<form action="" method="post" encType="multipart/form-data" id="frmLogin" onSubmit={this.handleFormSubmit} >
							<Row>
								<Col md={12} >
									<select className="form-control form-control-sm" required name="i__loginFor" >
										<option value="ug" > UG Login </option>
										<option value="pg" > PG Login </option>
										<option value="mphil" > MPhil Login </option>
									</select>
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={12} >
									<input type="text" className="form-control form-control-sm" placeholder="Mobile No (OR) Email Id" name="i__userName" required />
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={12} >
									<input type="password" className="form-control form-control-sm" placeholder="Password" name="i__password" required />
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={4} >
									<img src={this.state.captchaImg} />
								</Col>
								<Col md={6} className="px-0" >
									<input type="text" className="form-control form-control-sm" placeholder="Captcha" name="i__captcha" required />
								</Col>
								<Col md={2} className="pl-0">
									<button className="btn btn-outline-secondary btn-sm" type="button" onClick={this.loadCaptcha} >
										<i className="ion-ios7-reload" />
									</button>
								</Col>
							</Row>
							
							<input type="hidden" name="i__captchaCode" value={this.state.captchaCode} />
							
							
							<Row className="mt-15" >
								<Col md={12} >
									<button type="submit" className="btn btn-success btn-sm btn-block" name="i__submit" disabled={this.state.loginText!='LOGIN'} >
										{this.state.loginText}
									</button>
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={12} >
									<Link to="/account/forgot-password" className="text-primary" style={{fontSize: '14px'}} > Forgot Password?</Link>
								</Col>
							</Row>
						</form>*/}
						
						<Form noValidate  validated={this.state.validated} onSubmit={this.handleFormSubmit} id="frmLogin"  encType="multipart/form-data" >
							
														
							<Form.Group as={Col} md="12" controlId="" className='mt-3'>
								<Form.Control as="select" name="i__loginFor" size="sm" >
									{/*<option value="ug" > UG Login </option>
									<option value="pg" > PG Login </option>
					<option value="mphil" > MPhil Login </option>*/}
									{loginFor.map(item => this.context.settingValue(item.key)=='1' && <option value={item.value}>{item.text}</option>)}
								</Form.Control>
							</Form.Group>
							
							<Form.Group as={Col} md="12" controlId="" className='mt-3'>
							  <Form.Control type="text" placeholder="Mobile No (OR) Email Id" name="i__userName" required size="sm" />
							  <Form.Control.Feedback type="invalid" >
								Username is Required
							  </Form.Control.Feedback>
							</Form.Group>
							
							<Form.Group as={Col} md="12" controlId="" className='mt-3'>
							  <Form.Control type="password" placeholder="Password" name="i__password" required size="sm" />
							  <Form.Control.Feedback type="invalid" >
								Password is Required
							  </Form.Control.Feedback>
							</Form.Group>
							
							<Form.Group as={Row} className='mt-3' >
								<Col md={4} >
									<img src={this.state.captchaImg} />
								</Col>
								<Col md={6} className="px-0" >
									<Form.Control type="text" placeholder="Captcha" name="i__captcha" required size="sm" />
									  <Form.Control.Feedback type="invalid" >
										Captcha is Required
									  </Form.Control.Feedback>
								</Col>
								<Col md={2} className="pl-0">
									<button className="btn btn-outline-secondary btn-sm" type="button" onClick={this.loadCaptcha} >
										<i className="fa-solid fa-arrows-rotate" />
									</button>
								</Col>
							</Form.Group>
							
							<input type="hidden" name="i__captchaCode" value={this.state.captchaCode} />
							
							
							<Row className="mt-15" >
								<Col md={12} >
									<button type="submit" className="btn btn-danger btn-md btn-block" name="i__submit" disabled={this.state.loginText!='LOGIN'} >
										{this.state.loginText}
									</button>
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={12} >
									<Link to="/account/forgot-password" className="text-primary" style={{fontSize: '14px'}} > Forgot Password?</Link>
								</Col>
							</Row>
								
						</Form>
						
						
					</div>
				</Card.Body>
			</Card>
			
			
			{/*<MsgBox show={true} message="Invalid Username or Password" alertType="danger" />*/}
			
			</>
		);
	}
	
}
export default LoginForm;
