import React, { Component } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Card, Navbar, NavDropdown, Nav, Modal, Container, Offcanvas } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { getlsItem, setls, setlsItem, getls, lowerCase } from '../../../utils';
import { Api, mphilMenus, pgMenus, siteMenus, ugMenus, Var } from '../../../utils/Variables';
import LOGO from '../../../assets/img/spc-logo.png';
import FAV_LOGO from '../../../assets/img/spc-fav-logo.png';

import ChangePassword from '../../pages/Admission/ChangePassword';
import PsContext from '../../../context';

class HeaderResponsive extends Component {

	static contextType = PsContext;

	constructor(props) {
		super(props);
		this.state = {
			showPasswordModal: false,
		}
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}


	componentDidMount() {
		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.offcanvas-collapse').toggleClass('open')
		});
	}


	handleLogOut() {

		if (!window.confirm('Do you want to logout?')) {
			return;
		}
		//setlsItem('logged', 'no');
		//setls('logged_user', '');
		//this.setState({isRedirect:true});
		//window.location.href = '';

		this.context.logout();
	}
	checkKeyExist(key) {

		let m = this.context.settings.find(item => item.field_name == key);
		return (m && m.field_name) ? m.field_value == '1' : false;
	}

	renderMenus(menus) {
		/*return menus.map(item => item.key ? this.checkKeyExist(item.key) ? <Link to={item.to} className='nav-link'>
			<i className={item.icon}></i> {item.title}
		</Link>);*/
		
		let m = [];
		menus.map((item, i) => {
			if (item.key) {
				if (this.checkKeyExist(item.key)) {
					m.push(<Link to={item.to} className='nav-link' style={{color:'green'}} >
						<i className={item.icon} style={{color:'green'}}></i> {item.title} 
					</Link>);
				}
			}
			else {
				m.push(<Link to={item.to} className='nav-link' style={{color:'green'}}>
					<i className={item.icon} style={{color:'green'}}></i> {item.title}
				</Link>);
			}
		});
		return m;
	};

	render() {

		/*if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}*/

		if (this.state.isRedirect) {
			return (<Redirect to="/" />);
		}


		let isStepCompleted = (getls('logged_user', 'regular_course_applied') == '0' && getls('logged_user', 'self_course_applied') == '0');
        let expand = "md";
		return (
			<>
			 <img src={Api.Url + '/public/adm_logo.png'} style={{ width: '100%', }} /> 
			 <Navbar key={"md"} bg="white"  expand={"md"} className="mb-3"> 
				<Container fluid> 
					<Link to="/">
							{/*<img src={LOGO} className='brand_logo' /> */}
							
					</Link>
					<Navbar.Brand>

					</Navbar.Brand>
						<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
						<Navbar.Offcanvas
							id={`offcanvasNavbar-expand-${expand}`}
							aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
							placement="end"
						>
							<Offcanvas.Header closeButton>
								<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
									MENU
								</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="justify-content-end flex-grow-1 pe-3">
									{this.context.logged == 'yes' ?
										lowerCase(this.context.user.applied_for) == 'ug' ?
											this.renderMenus(ugMenus) :
											lowerCase(this.context.user.applied_for) == 'pg' ?
												this.renderMenus(pgMenus) :
												this.renderMenus(mphilMenus)
										: this.renderMenus(siteMenus)
									}

									{this.context.logged == 'yes' && (
										<a href="javascript:;" onClick={this.handleLogOut} className="nav-link" >
											<i className="fa-solid fa-arrow-right-to-bracket" />  Logout
										</a>
									)}
								</Nav>

							</Offcanvas.Body>
						</Navbar.Offcanvas>
					 </Container>
				  </Navbar>  
			</>
		);

		return (
			<React.Fragment>
				<nav className="navbar navbar-expand-md fixed-top bg-white bb-1 " style={{ borderBottom: '1px solid #eae8e7' }} >
					<Link to="/" className="navbar-brand" >
						<img src={LOGO} style={{ width: '280px' }} />
					</Link>
					<button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
						<span className="navbar-toggle-icon ion-navicon-round"></span>
					</button>

					<div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
						{(getlsItem('logged') == 'yes') ? <ul className="navbar-nav ml-auto">
							{(getls('logged_user', 'applied_for') == 'ug') ? <>
								<li className="nav-item">
									<NavLink to="/app/dashboard" className="nav-link" >
										<i className="ion-home" />  Dashboard
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/app/ug-course-apply" className="nav-link" >
										<i className="ion-ios7-copy-outline" />  Apply Course
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to="/app/ug-application-download" className="nav-link" >
										<i className="ion-ios7-download-outline" />  Download
									</NavLink>
								</li>
							</>
								:
								(getls('logged_user', 'applied_for') == 'mphil') ? <>
									<li className="nav-item">
										<NavLink to="/app/dashboard" className="nav-link" >
											<i className="ion-home" />  Dashboard
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink to="/app/mphil-course-apply" className="nav-link" >
											<i className="ion-ios7-copy-outline" />  Apply Course
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink to="/app/mphil-application-download" className="nav-link" >
											<i className="ion-ios7-download-outline" />  Download
										</NavLink>
									</li>
								</>
									:
									<>
										<li className="nav-item">
											<NavLink to="/app/dashboard" className="nav-link" >
												<i className="ion-home" />  Dashboard
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink to="/app/pg-course-apply" className="nav-link" >
												<i className="ion-ios7-copy-outline" />  Apply Course
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink to="/app/pg-application-download" className="nav-link" >
												<i className="ion-ios7-download-outline" />  Download
											</NavLink>
										</li></>
							}
							<li className="nav-item">
								<a href="javascript:;" onClick={this.handleLogOut} className="nav-link" >
									<i className="ion-power" />  Logout
								</a>
							</li>
						</ul>
							:
							<ul className="navbar-nav ml-auto" >
								<li className="nav-item"><Link to="/ug-admission-instruction" className="nav-link">UG Registration</Link></li>
								<li className="nav-item"><Link to="/pg-admission-instruction" className="nav-link">PG Registration</Link></li>
								<li className="nav-item"><Link to="/admission/apply/mphil" className="nav-link">Mphil Registration</Link></li>
								<li className="nav-item"><Link to="/" className="nav-link">Login</Link></li>
							</ul>
						}

					</div>
				</nav>

				{/*<div className="nav-scroller bg-theme box-shadow mt-15">
			  <nav className="nav nav-underline">
			  
				<ul className="navbar-nav mr-auto ml-auto">
					
				</ul>
				
			  </nav>
			</div>*/}

				<Modal show={this.state.showPasswordModal} onHide={() => { this.setState({ showPasswordModal: false }) }}  >
					<Modal.Header closeButton >
						<Modal.Title>Change Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<ChangePassword />

					</Modal.Body>
				</Modal>


			</React.Fragment>
		);
	}

}
export default HeaderResponsive;
