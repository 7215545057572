import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav , Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import LOGO from '../../../assets/img/spc-logo.png';
import FAV_LOGO from '../../../assets/img/spc-fav-logo.png';

import ChangePassword from '../../pages/Admission/ChangePassword';
import HeaderResponsive from './HeaderResponsive';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			showPasswordModal: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		setlsItem('logged', 'no');
		setls('logged_user', '');
		this.setState({isRedirect:true});
	}
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		let isStepCompleted = (getls('logged_user', 'regular_course_applied')=='0' && getls('logged_user', 'self_course_applied')=='0');
		
		return(
			
			<React.Fragment>
			
			<HeaderResponsive {...this.props} />
			
			{/*<nav className="navbar navbar-expand-md fixed-top nav-light bg-light">
			  <Link to="/app/dashboard" className="navbar-brand" >
				<img src={LOGO} style={{width: '320px'}} /> 
			  </Link>
			  <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
				<span className="navbar-toggler-icon"></span>
			  </button>

			  <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
				<ul className="navbar-nav mr-auto">
				  <li className="nav-item active">
					<NavLink to="/app/dashboard" className="nav-link"  >
						Dashboard
					</NavLink>
				  </li>
				  <li className="nav-item">
					<NavLink to="/app/ug-course-apply" className="nav-link" >
						Apply Course
					</NavLink>
				  </li>
				  <li className="nav-item">
					<NavLink to="/app/ug-application-download" className="nav-link" >
						Download
					</NavLink>
				  </li>
				  
				  <li className="nav-item">
					<a href="javascript:;" className="nav-link" onClick={()=>{this.setState({showPasswordModal:true})}} >
						Change Password
					</a>
				  </li>

				  <li className="nav-item">
					<a href="javascript:;" className="nav-link" onClick={this.handleLogOut} >
						Logout
					</a>
				  </li>
				 
				 <li className="nav-item dropdown">
					<a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Settings</a>
					<div className="dropdown-menu" aria-labelledby="dropdown01">
					  <a className="dropdown-item" href="#">Action</a>
					  <a className="dropdown-item" href="#">Another action</a>
					  <a className="dropdown-item" href="#">Something else here</a>
					</div>
				 </li>
				 
				</ul>
				
			  </div>
			</nav>*/}
			
			{/*<section style={{backgroundColor: '#fff'}}>
				<div className="container" >
					<Row  className="px-10 py-10"  >
						<Col md={4} >
							<Link to="/app/dashboard" > <img src={LOGO} style={{width: '100%'}} /> </Link>
						</Col>
						<Col md={7} ></Col>
						<Col md={1} >
							<div className="text-center d-none d-md-block  " style={{borderLeft: '1px solid #9e9a9a'}} >
								<a href="javascript:;" onClick={this.handleLogOut} >
									<div className="btn" style={{fontSize: '23px', padding: '0px' }} >
										<i className="ion-power" />
									</div>
									<div style={{fontSize: '13px', marginTop: '-6px'}} >Logout</div>
								</a>
							</div>
						</Col>
					</Row>
				</div>
			</section>
			
			<Navbar collapseOnSelect className="navbar navbar-expand-lg navbar-dark bg-theme" >
				  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
				  <Navbar.Collapse id="responsive-navbar-nav">
				  {isStepCompleted ? null : <Nav className="ml-auto mr-auto">
					 <div className="text-center">
						<Nav.Item>
							<NavLink to="/app/dashboard" className="nav-link" >Dashboard</NavLink>
						 </Nav.Item>
					 </div>
					</Nav>
				  }
					
				  </Navbar.Collapse>
				</Navbar>
				
				
				<Modal show={this.state.showPasswordModal} onHide={()=>{this.setState({showPasswordModal:false})}}  >
					<Modal.Header closeButton >
						<Modal.Title>Change Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						
						<ChangePassword />
						
					</Modal.Body>
				</Modal>
				
	*/}
			</React.Fragment>
		);
	}
	
}
export default Header;
