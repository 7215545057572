import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Alert} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, getlsItem} from '../../../utils';
import {PageLoader} from '../../elements';

import NotFoundAlert from '../NotFound/NotFoundAlert';

class PaymentComplete extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'PAY NOW',
			showLoader: false,
			orderId: this.props.match.params.orderId,
			orderData: [],
		}
		
		
	}
	
	componentDidMount(){
		
		this.getInfo();
	}
	
	getInfo(){
		try{
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__orderId', this.state.orderId);
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			API.post('v1/mphil/orders', form).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({orderData: res['data'].data, showLoader: false});
				}
				else{
					//notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(field){
		try{
			var d = this.state.orderData;
			return d[0][field];
		}
		catch(error){
			return '';
		}
	}
	
	
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		let sno=1;
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
							
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:8, offset:2}} >
								
								
								
								<Card className="shadow-lg" >
									<Card.Body>
									{this.state.showLoader ? <div></div> :
									<div>
										{Object.keys(this.state.orderData).length>0 && this.getField('fee_paid_status')=='0' ? <div>
											<Alert variant="danger" >
												<h4>We are getting error in process the payment</h4>
												<hr />
												<p>Payment Status : {this.getField('fee_paid_response')}</p>
											</Alert>
											
											<div style={{fontSize: '14px'}} className="mt-30" >
												<b>மாணவ / மாணவியர் கவனத்திற்கு.</b><br />
தாங்கள் ஆன்லைனில் விண்ணப்ப கட்டணம் செலுத்திய பின்பு உங்கள் வங்கி கணக்கிலிருந்து பணம் எடுக்கப்பட்டு விண்ணப்ப படிவம் பதிவிறக்கம் செய்ய முடியவில்லையென்றால் இரண்டு மணி நேரம் கழித்து மீண்டும் உங்கள் பக்கத்தை லாகின் (Login) செய்து பதிவிறக்கம் செய்து கொள்ளலாம். மேலும் விவரங்களுக்கு கல்லூரி அலுவலகத்தை தொடர்பு கொள்ளவும்.!
											</div>
											
										</div>
										: Object.keys(this.state.orderData).length > 0 ?
										<div>
											<Alert variant="success" >
												<h4>Your payment has been completed.</h4>
												<hr />
												<p>Payment Status : {this.getField('fee_paid_response')}</p>
											</Alert>
										</div>
										:
										<div>
											<Alert variant="danger text-center" >
												<h4>Sorry we are getting error in process your request. Please try again later.</h4>
											</Alert>
											<div style={{fontSize: '14px'}} className="mt-30" >
												<b>மாணவ / மாணவியர் கவனத்திற்கு.</b><br />
தாங்கள் ஆன்லைனில் விண்ணப்ப கட்டணம் செலுத்திய பின்பு உங்கள் வங்கி கணக்கிலிருந்து பணம் எடுக்கப்பட்டு விண்ணப்ப படிவம் பதிவிறக்கம் செய்ய முடியவில்லையென்றால் இரண்டு மணி நேரம் கழித்து மீண்டும் உங்கள் பக்கத்தை லாகின் (Login) செய்து பதிவிறக்கம் செய்து கொள்ளலாம். மேலும் விவரங்களுக்கு கல்லூரி அலுவலகத்தை தொடர்பு கொள்ளவும்.!
											</div>
										</div>
										}
										<hr />
										{this.getField('fee_paid_status')=='0' ? <div className="text-center" >
											<Link to="/app/mphil-application-download" className="btn btn-warning" >
												Go to Download list
											</Link>
										</div> : <div className="text-center" >
											<Link to="/app/mphil-application-download" className="btn btn-success" >
												Go to Download list
											</Link>
										</div>}
										
										
										
									</div>
									}
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				}
				
			</React.Fragment>
		);
	}
	
}
export default PaymentComplete;
