import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Form, Spinner, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ReactFileReader from 'react-file-reader';
import {Api, Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {integerIndMobile,setlsItem, getls, setls, decimalKeyPress, numberToMonth, indAadharKeyPress, indEmisNoKeyPress, momentDate} from '../../../utils';
import {InstructionHeader} from '../../elements';
import SELECT_IMAGE from '../../../assets/img/select-image.png';

import Instruction from './Instruction';
import PsContext from '../../../context';

class EditPersonalInformation extends Component{

	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Submit',
			captchaImg: '',
			captchaCode: '',
			isRedirect: false,
			selectedCountry: 'India',
			selectedState: 'Tamil Nadu',
			selectedFile: '',
			validated: false,
			selectedReligion: '',
			sportsStatus: '0',
			nccStatus: '0',
			nssStatus: '0',
			exServiceStatus: '0',
			physicalStatus: '0',
			passingYear: '',
			dataSource: [],
		}
		this.loadCaptcha = this.loadCaptcha.bind(this);
		this.handleProfilePhotChange = this.handleProfilePhotChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
		this.loadCaptcha();
		
	}
	
	loadCaptcha(){
		try{
			API.get('v1/captcha').then(res=>{
				this.setState({captchaImg: res['data'].img, captchaCode: res['data'].code});
			});
		}
		catch(error){ }
	}

	loadData(){
		this.setState({showLoader: true});
		const form = new FormData();
		form.append('i__apiCode', this.context.user.api_code);

		API.post('v1/ug/edit/personal', form).then(res=>{
			if(res['data'].status=='1'){
				let d = res['data'].data;
				this.setState({
					selectedCountry: d.country,
					selectedState: d.state,
					selectedReligion: d.religion,
					sportsStatus: d.in_sports,
					nccStatus: d.in_ncc,
					nssStatus: d.in_nss,
					exServiceStatus: d.ex_army_child,
					physicalStatus: d.physically_challanged,
					passingYear: d.year_of_passing,
					dataSource: res['data'].data,
					showLoader: false,
				});
			}
			else{
				alert('Getting error on fetching details..');
			}
		});
	}
	
	handlePassingYearChange(e){
		this.setState({passingYear: e.target.value});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		const form = e.currentTarget;
		if(form.checkValidity() === false){
			e.stopPropagation();
			this.setState({validated: true});
			notify.show('Please check all the require fiedls are filled', 'error');
			return;
		}
		
		try{
			
			/*if(this.state.selectedFile=='' || this.state.selectedFile.length<=3){
				notify.show('Please select your Photo to proceed next','warning');
				return;
			}*/
			
			if(!window.confirm('Please check all the filled informations before processing. Do you want to proceed next?')){
				return;
			}
			
			this.setState({btnText: 'Processing..', showLoader: true});
			API.post('v1/ug/update/personal', $("#frmStepIUpdate").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					//setls('logged_user', res['data'].user);
					this.context.updateUser(res['data'].user);
					this.setState({btnText: 'Submit', showLoader: false, isRedirect: true});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Submit', showLoader: false});
					this.loadCaptcha();
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Submit', showLoader: false});
			this.loadCaptcha();
		}
	}
	
	handleProfilePhotChange(files){
		if(files.fileList[0].size > 90000){
			alert('Select a file less than 90kb');
			return;
		}
		this.setState({selectedFile: files.base64});
	}
	
	handleInitialChange(e){
		if(e.target.value.length>=3) e.preventDefault();
	}

	handleReligionChange(e){
		this.setState({selectedReligion: e.target.value});
	}

	handleSportsChange(fieldName, e){
		this.setState({[fieldName] : e.target.value});
	}


	field(fieldName){

		let m = this.state.dataSource;
		console.log(m[fieldName]);
		return (m && m[fieldName]) ? m[fieldName] : '';
	}
	
	render(){

		if(this.context.logged != 'yes'){
			return(<Redirect to="/app/dashboard" />);
		}
		
		if(this.state.isRedirect){
			return(<Redirect to="/app/dashboard" />);
		}

		if(this.context.user.regular_course_applied=='1' || this.context.user.self_course_applied=='1')
		{
			return(<>
				<div className='container mt-50' >
					<Row>
						<Col md={{offset: 3, span: 6}} >

							<Card>
								<Card.Body>
									<div className='my-50 text-center'>
										<h3>
											<i className="fa-solid fa-triangle-exclamation me-2 text-danger"></i>
											"Sorry, this feature isn't available ... "
										</h3>
									</div>
									<div className='text-end'>
										<hr style={{borderTop: '1px solid #000'}} />
										<Link to="/app/dashboard" className="btn btn-primary btn-sm" >
											Back
										</Link>
									</div>
								</Card.Body>
							</Card>

						</Col>
					</Row>
					
				</div>
			
			</>);
		}
		
		var day = [];
		for(var i=1;i<=31;i++){
			day.push(("0" + i).slice(-2));
		}
		
		var month = [];
		for(var i=1;i<=12;i++){
			month.push(("0" + i).slice(-2));
		}
		
		var year = [];
		for(var i= ((new Date()).getFullYear()-16);i>=((new Date()).getFullYear()-26);i--){
			year.push(i);
		}
		
		var profileImage = SELECT_IMAGE;
		if(this.state.selectedFile!='' && this.state.selectedFile.length>4)
			profileImage = this.state.selectedFile;
		
	if(this.state.dataSource && this.state.dataSource.id)
	{
		return(
			<React.Fragment>
				
				<section>
					<div className="container mt-50 pb-30" >
						
						<Row >
							<Col md={{span:8, offset:2}} >
								
								<Card className="shadow-lg" >
									<Card.Body>
										<Form noValidate validated={this.state.validated}  action="" method="post" encType="multipart/form-data" id="frmStepIUpdate" onSubmit={this.handleFormSubmit} >
											

											<input type="hidden" name="i__userId" value={this.context.user.user_id} />
											<input type="hidden" name="i__userCode" value={this.context.user.user_code} />
											<input type="hidden" name="i__apiCode" value={this.context.user.api_code} />
											<input type="hidden" name="i__id" value={this.field('id')} />
											
											<Row>
												<Col md={12} >
													<div className="text-center" >
														<h5>Edit Personal Information</h5>
														<hr className='border-bottom'/>
													</div>
												</Col>
											</Row>
											
											<Row>
												<Col md={9} >
												
											
											<Row className="cl__row_btm_border" >
												<Col md={4} >
													<label>Board <span className="text-danger">*</span></label>
												</Col>
												<Col md={4} >
												<label className="custom_radio_input">
													<input 
														type="radio" 
														name="i__board"  
														className="form-control" 
														value="state" 
														required 
														defaultChecked={this.field('board')=='state'} 
													/>
													<span>
														State Board
													</span>
												</label>
													
												</Col>
												<Col md={4} >
													<label className="custom_radio_input">
														<input 
															type="radio" 
															name="i__board"  
															className="form-control"  
															value="cbse" 
															required 
															defaultChecked={this.field('board')=='cbse'}
														/>
														<span>Others (CBSE / ISC)</span>
													</label>
													
												</Col>
											</Row>
											
											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>Year of Passing <span className="text-danger">*</span></label>
												</Col>
												<Col md={4} >
													<label className="custom_radio_input">
													  <input 
															type="radio" 
															name="i__passingYear" 
															className="form-control" 
															value="2019" 
															required 
															defaultChecked={this.field('year_of_passing')=='2019'}
															onChange={this.handlePassingYearChange.bind(this)} 
														/>
													  <span className="">2019 (or) After 2019</span>
													</label>
												</Col>
												<Col md={4} >
													<label className="custom_radio_input">
													  <input 
															type="radio" 
															name="i__passingYear" 
															className="form-control" 
															value="2018" 
															required 
															onChange={this.handlePassingYearChange.bind(this)} 
															defaultChecked={this.field('year_of_passing')=='2018'}
														/>
													  <span className=" ">Before 2019</span>
													</label>
												</Col>
											</Row>
											
											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>Name <span className="text-danger">*</span></label>
												</Col>
												<Col md={2} className="" >
													<input 
														type="text" 
														className="form-control form-control-sm" 
														required 
														placeholder="Initial" 
														name="i__initial" 
														style={{textTransform: 'uppercase'}} 
														defaultValue={this.field('initial')}
														onKeyPress={this.handleInitialChange.bind(this)} 
													/>
												</Col>
												<Col md={6} className="" >
													<input 
														type="text" 
														className="form-control form-control-sm" 
														required 
														placeholder="NAME" 
														name="i__name" 
														style={{textTransform: 'uppercase'}}  
														defaultValue={this.field('name')}
													/>
												</Col>
												<Col md={4}></Col>
												<Col md={8} >
													<label style={{color: 'red', fontSize: '13px'}} >(As per 10th Marksheet)</label>
												</Col>
											</Row>
											
											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>Aadhar No <span className="text-danger">*</span></label>
												</Col>
												<Col md={8}>
													<input 
														type="text" 
														className="form-control form-control-sm" 
														required 
														placeholder="Aadhar No" 
														name="i__aadhar"  
														onKeyPress={indAadharKeyPress} 
														defaultValue={this.field('aadhar')}
													/>
												</Col>
											</Row>

											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>EMIS No {this.context.settingValue('admission_ug_emisno_optional')=='0' && (<span className="text-danger">*</span>)}</label>
												</Col>
												<Col md={8}>
													{this.context.settingValue('admission_ug_emisno_optional')=='0' ? 
														<input type="text" 
															className="form-control form-control-sm" 
															required 
															placeholder="EMIS No" 
															name="i__emisNo" 
															onKeyPress={indEmisNoKeyPress} 
															defaultValue={this.field('emisno')}
														/>
													: <input 
														type="text" 
														className="form-control form-control-sm"  
														placeholder="EMIS No" 
														name="i__emisNo"  
														onKeyPress={indEmisNoKeyPress} 
														defaultValue={this.field('emisno')}
													/>
													}
												</Col>
											</Row>
											
											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>Gender <span className="text-danger">*</span></label>
												</Col>
												{this.context.settingValue('admission_enable_gender_male_ug')=='1' && (<Col md={2} >
													<label className="custom_radio_input">
													  <input 
															type="radio" 
															name="i__gender" 
															value="male" 
															className="form-control" 
															required  
															defaultChecked={this.field('gender')=='male'}
														/>
													  <span className="">Male</span>
													</label>
												</Col>)}
												{this.context.settingValue('admission_enable_gender_female_ug')=='1' && (<Col md={2} >
												<label className="custom_radio_input">
													  <input 
															type="radio" 
															name="i__gender" 
															value="female" 
															className="form-control" 
															required  
															defaultChecked={this.field('gender')=='female'}
														/>
													  <span className="">Female</span>
													</label>
												</Col>)}

												<Col md={2} >
													<label className="custom_radio_input">
													  <input 
													  		type="radio" 
															name="i__gender" 
															value="tg" 
															className="form-control" 
															required  
															defaultChecked={this.field('gender')=='tg'}
														/>
													  <span className="">Transgender</span>
													</label>
												</Col>
											</Row>
											
											<Row className="cl__row_btm_border mt-15" >
												<Col md={4} >
													<label>Date of Birth <span className="text-danger">*</span></label>
												</Col>
												<Col md={2} className="pr-0" >
													<select 
														className="form-control form-control-sm" 
														name="i__dobDay" 
														required 
													>
														<option value=""> Day </option>
														{day.map((item,i)=>{
															return <option 
																value={item} 
																selected={momentDate(this.field('date_of_birth'),'DD')==item}
															> {item} </option>;
														})
														}
													</select>
												</Col>
												<Col md={3} className="px-0"  >
													<select 
														className="form-control form-control-sm" 
														required 
														name="i__dobMonth"
													>
														<option value=""> Month </option>
														{month.map((item,i)=>{
															return <option 
																value={item} 
																selected={momentDate(this.field('date_of_birth'),'MM')==item}
															> {numberToMonth(item)} </option>;
														})
														}
													</select>
												</Col>
												<Col md={3} className="pl-0"  >
													<select 
														className="form-control form-control-sm" 
														required 
														name="i__dobYear"
													 >
														<option value=""> Year </option>
														{year.map((item,i)=>{
															return <option 
																value={item} 
																selected={momentDate(this.field('date_of_birth'),'YYYY')==item}
															> {item} </option>;
														})
														}
													</select>
												</Col>
											</Row>
											
											</Col>
											
											<Col md={3} >
												{this.state.selectedFile && (<img src={profileImage} style={{width: '100%'}} />)}

												{!this.state.selectedFile && (<img src={Api.Url+'/v1/ug/photo?apicode='+this.field('api_code')} style={{width: '100%'}} />)}

												{!this.state.selectedFile && (<ReactFileReader fileTypes={[".png",".jpg",".jpeg"]} base64={true} handleFiles={this.handleProfilePhotChange} >
													<button type="button" className="btn btn-outline-secondary btn-sm btn-block" >
														Select Image
													</button>
												</ReactFileReader >)}

												{this.state.selectedFile && (<>
												<Button type="button" size="sm" className="btn btn-outline-danger btn-sm btn-block" onClick={e => {
													this.setState({selectedFile: ''})
												}} >
													Remove New Photo
												</Button>
												</>)}
											</Col>
										</Row>
										
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Community <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select 
													name="i__community" 
													className="form-control form-control-sm" 
													required 
													defaultValue={this.field('community')}
												>
													<option value=""> -Select- </option>
													<option value="oc">OC ( மு.வ ) </option>
													<option value="bc">BC ( பி.வ ) </option>
													<option value="bcm">BC-Muslim ( பி.வ முஸ்லீம் ) </option>
													<option value="mbc">MBC ( மி.பி.வ ) </option>
													<option value="dnc">DNC ( மி.பி.வ ) </option>
													<option value="sc">SC ( ஆ.தி ) </option>
													<option value="sca">SCA ( ஆ.தி அருந்ததியர் ) </option>
													<option value="st">ST</option>
												</select>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Caste <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													ame="i__caste" 
													className="form-control form-control-sm" 
													required 
													placeholder="Caste" 
													defaultValue={this.field('caste')}
												/>
											</Col>
										</Row>
											
										 <Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Blood Group <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select 
													name="i__bloodGroup" 
													className="form-control form-control-sm" 
													required 
													defaultValue={this.field('blood_group')}
												>
													<option value=""> -Select- </option>
													<option value="A+"> A+ </option>
													<option value="A-"> A- </option>
													<option value="A1+"> A1+ </option>
													<option value="A1-"> A1- </option>
													<option value="A2+"> A2+ </option>
													<option value="A2-"> A2- </option>
													<option value="A1B+"> A1B+ </option>
													<option value="A1B-"> A1B- </option>
													<option value="A2B+"> A2B+ </option>
													<option value="A2B-"> A2B- </option>
													<option value="B+"> B+ </option>
													<option value="B-"> B- </option>
													<option value="O+"> O+ </option>
													<option value="O-"> O- </option>
												</select>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} />

											<Col md={8}>
												<label style={{fontSize: '14px'}} >Willing to Donate Blood ? இரத்த தானம் செய்ய விருப்பமா ? </label>
											</Col>
											<Col md={3} />
											<Col md={8} >
												<label className="custom_radio_input">
												  <input 
												  		type="radio" 
														name="i__willingToDonateBlood" 
														className="form-control" 
														value="1" 
														required 
														defaultChecked={this.field('donate_blood')=='1'}
													/>
												  <span className="">Yes</span>
												</label>
											</Col>
											<Col md={3} />
											<Col md={8} >
												<label className="custom_radio_input">
												  <input 
												  		type="radio" 
														name="i__willingToDonateBlood" 
														className="form-control" 
														value="0" 
														required  
														defaultChecked={this.field('donate_blood')=='0'}
													/>
												  <span className="">No</span>
												</label>
											</Col>
										</Row> 
										
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Religion <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select 
													name="i__religion" 
													className="form-control form-control-sm" 
													required 
													onChange={this.handleReligionChange.bind(this)} 
													defaultValue={this.field('religion')}
												>
													<option value=""> -Select- </option>
													<option value="hindu"> Hindu </option>
													<option value="christian"> Christian </option>
													<option value="muslim"> Muslim </option>
													<option value="sikh"> Sikh </option>
													<option value="jain"> Jain </option>
													<option value="buddhist"> Buddhist </option>
													<option value="other"> Other </option>
												</select>
											</Col>
										</Row>
										
										
										{this.context.settingValue('admission_religion_with_christ_denomination')=='1' && this.state.selectedReligion=='christian' && (<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Denomination <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<select 
													name="i__denomination" 
													className="form-control form-control-sm" 
													required 
													defaultValue={this.field('denomination')}
												>
													<option value=""> -Select- </option>
													{Api.url!='https://nmcpadmission.kalvisalai.in/api/' && (<option value="csi tvl"> CSI  Thoothukudi  Nazareth Diocese</option>)}
													{Api.Url=='https://nmcpadmission.kalvisalai.in/api/' && (<option value="csi thoothukudi nazareth"> CSI - Thoothukudi Nazareth Diocese</option>)}
													{/* <option value="other diocese">Other Diocese </option> */}
													<option value="rc"> Roman Catholic </option>
													<option value="other"> Others </option>
												</select>
											</Col>
										</Row>)}
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Father Name <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													name="i__fatherName" 
													className="form-control form-control-sm" 
													required 
													placeholder="Father Name" 
													style={{textTransform: 'uppercase'}}  
													defaultValue={this.field('father_name')}
												/>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label> <span className="text-danger"></span></label>
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >Occupation of father (உங்கள் தந்தை தொழில் ) <span className="text-danger">*</span></label>
												<select 
													name="i__fatherOccupation" 
													className="form-control form-control-sm" 
													required 
													defaultValue={this.field('father_occupation')}
												>
													<option value=""> -Select- </option>
													<option value="farmer"> Farmer ( விவசாயி ) </option>
													<option value="agriculture-labourer"> Agriculture Labourer ( விவசாய கூலி ) </option>
													<option value="registered-tenant"> Registered Tenant ( பதிவு செய்யப்பட்ட விவசாய குத்தகைதாரர் ) </option>
													<option value="business">Business</option>
													<option value="construction">Construction</option>
													<option value="doctor">Doctor</option>
													<option value="driver">Driver</option>
													<option value="engineer">Engineer</option>
													<option value="lawyer">Lawyer</option>
													<option value="professor">Professor</option>
													<option value="professional">Professional</option>
													<option value="paramedical">Paramedical</option>
													<option value="teacher">Teacher</option>
													<option value="unemployed">Unemployed</option>
													<option value="other"> Others </option>
												</select>
											</Col>
										</Row>
										
										 <Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Nature of Work <span className="text-danger"></span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													name="i__fatherWorkNature" 
													className="form-control form-control-sm" 
													placeholder="" 
													defaultValue={this.field('father_work_nature')}
												/>
											</Col>
										</Row> 
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Mother Name <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													name="i__motherName" 
													className="form-control form-control-sm" 
													required 
													placeholder="Mother Name" 
													style={{textTransform: 'uppercase'}}  
													defaultValue={this.field('mother_name')}
												/>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label> <span className="text-danger"></span></label>
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >Occupation of mother (உங்கள் தாய் தொழில் ) <span className="text-danger">*</span></label>
												<select 
													name="i__motherOccupation" 
													className="form-control form-control-sm" 
													required 
													defaultValue={this.field('mother_occupation')}
												>
													<option value=""> -Select- </option>
													<option value="farmer"> Farmer ( விவசாயி ) </option>
													<option value="agriculture-labourer"> Agriculture Labourer ( விவசாய கூலி ) </option>
													<option value="registered-tenant"> Registered Tenant ( பதிவு செய்யப்பட்ட விவசாய குத்தகைதாரர் ) </option>
													<option value="business">Business</option>
													<option value="construction">Construction</option>
													<option value="doctor">Doctor</option>
													<option value="driver">Driver</option>
													<option value="engineer">Engineer</option>
													<option value="house-wife">House Wife</option>					
													<option value="lawyer">Lawyer</option>
													<option value="professor">Professor</option>
													<option value="professional">Professional</option>
													<option value="paramedical">Paramedical</option>
													<option value="teacher">Teacher</option>
													<option value="unemployed">Unemployed</option>
													<option value="other"> Others </option>
												</select>
											</Col>
										</Row>
										
										 <Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Nature of Work <span className="text-danger"></span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													name="i__motherWorkNature" 
													className="form-control form-control-sm" 
													placeholder="" 
													defaultValue={this.field('mother_work_nature')}
												/>
											</Col>
										</Row> 
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label>Annual Income <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													name="i__annualIncome" 
													className="form-control form-control-sm" 
													required 
													placeholder="Annual Income" 
													onKeyPress={decimalKeyPress} 
													defaultValue={this.field('annual_income')}
												/>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >
													Whether the applicant is Physically challenged ? விண்ணப்பதாரர் மாற்றுதிறனாளியா ? 
													<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												>
												  <input 
												  		type="radio" 
														className="form-control" 
														name="i__isPhysicallyChallanged" 
														value="1" 
														required 
														onChange={this.handleSportsChange.bind(this, 'physicalStatus')} 
														defaultChecked={this.field('physically_challanged')=='1'}
													/>
												  <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												>
												  <input 
												  		type="radio" 
														className="form-control" 
														name="i__isPhysicallyChallanged" 
														value="0" 
														required 
														onChange={this.handleSportsChange.bind(this, 'physicalStatus')} 
														defaultChecked={this.field('physically_challanged')=='0'}
													/>
												  <span className="">No</span>
												</label>

												{this.state.physicalStatus=='1' && this.context.settingValue('admission_ug_cert_physical_require')=='1' && (<p className='text-danger' style={{fontSize: '14px'}}>
													Upload the certificate
												</p>)}
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >
													Are you the daughter of Ex-serviceman? நீங்கள் முன்னாள் இராணுவத்தினரின் மகளா ? 
													<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												>
												  <input 
												  		type="radio" 
														name="i__exArmyChild" 
														className="form-control"  
														value="1" 
														required 
														onChange={this.handleSportsChange.bind(this, 'exServiceStatus')} 
														defaultChecked={this.field('ex_army_child')=='1'}
													/>
												  <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												>
													  <input 
													  		type="radio" 
															name="i__exArmyChild" 
															className="form-control"  
															value="0" 
															required 
															onChange={this.handleSportsChange.bind(this, 'exServiceStatus')} 
															defaultChecked={this.field('ex_army_child')=='0'}
													 	/>
												  <span className="">No</span>
												</label>
												{this.state.exServiceStatus=='1' && this.context.settingValue('admission_ug_cert_exservice_require')=='1' && (<p className='text-danger' style={{fontSize: '14px'}}>
													Upload the certificate
												</p>)}
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >
													Are you of Tamil Origin from Andaman Nicobar islands / Sri Lanka ? அந்தமான் நிகோபர் தீவுகளின் தமிழினத்தைச் சார்ந்தவரா / இலங்கை வாழ் தமிழரா ?  
													<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												>
												 	 <input 
														type="radio" 
														name="i__sriLankan" 
														className="form-control"  
														value="1"  
														required 
														defaultChecked={this.field('srilankan_citizen')=='1'}
													/>
												  <span className="">Yes</span>
												</label>
												<label className="custom_radio_input">
												  	<input 
														type="radio" 
														name="i__sriLankan" 
														className="form-control"  
														value="0" 
														required 
														defaultChecked={this.field('srilankan_citizen')=='0'}
													/>
												  <span className="">No</span>
												</label>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label style={{fontSize: '14px'}} >
														Are you of migrated from Jammu and Kashmir ? நீங்கள் ஜம்மு மற்றும் காஷ்மீரில் இருந்து குடியேறியிருக்கிறீர்களா ?   
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												>
												  	<input 
														type="radio" 
														name="i__kashmire" 
														className="form-control" 
														value="1" 
														required 
														defaultChecked={this.field('citizen_from_jammu')=='1'}
													/>
												  <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												>
												  	<input 
														type="radio" 
														name="i__kashmire" 
														className="form-control" 
														value="0" 
														required 
														defaultChecked={this.field('citizen_from_jammu')=='0'}
													/>
												 	 <span className="">No</span>
												</label>
											</Col>
										</Row>
										

										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label 
													style={{fontSize: '14px'}} 
												>
														Whether the Student belongs to Other State or NRI ? நீங்கள் வேறு மாநிலம் அல்லது வேறு நாட்டை சார்ந்தவரா?   
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												 >
												  	<input 
														type="radio" 
														name="i__nri" 
														className="form-control" 
														value="1" 
														required 
														defaultChecked={this.field('is_nri')=='1'}
													/>
												 	 <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												>
												 	 <input 
													 	type="radio" 
														name="i__nri" 
														className="form-control" 
														value="0" 
														required 
														defaultChecked={this.field('is_nri')=='0'}
													/>
												 	 <span className="">No</span>
												</label>
											</Col>
										</Row>
										

										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label 
													style={{fontSize: '14px'}} 
												>
														Participation in Sports. விளையாட்டு சிறப்புகள் ஏதேனும் உள்ளதா ?  
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												 >
												 	 <input 
													 		type="radio" 
															name="i__inSports" 
															className="form-control" 
															value="1" 
															required 
															onChange={this.handleSportsChange.bind(this, 'sportsStatus')} 
															defaultChecked={this.field('in_sports')=='1'}
													 />
												  <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												 >
												 	 <input 
													 		type="radio" 
															name="i__inSports" 
															className="form-control" 
															value="0" 
															required 
															onChange={this.handleSportsChange.bind(this, 'sportsStatus')} 
															defaultChecked={this.field('in_sports')=='0'}
														/>
												  <span className="">No</span>
												</label>
												{this.state.sportsStatus=='1' && this.context.settingValue('admission_ug_cert_sports_require')=='1' && (<p className='text-danger' style={{fontSize: '14px'}}>
													Upload the certificate
												</p>)}
											</Col>
										</Row>

										{this.state.sportsStatus=='1' && (<Row  className="cl__row_btm_border mt-15" >
											<Col md={3}>
												<label>Level <span className='text-danger'>*</span></label>
											</Col>
											<Col md={8}>
												<select 
													name="i__sportsLevel" 
													className='form-control form-control-sm' 
													required 
													defaultChecked={this.field('sports_level')}
												>
													<option value="">Select</option>
													<option value="district">District</option>
													<option value="state">State</option>
													<option value="national">National</option>
												</select>
											</Col>
										</Row>)}
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label 
													style={{fontSize: '14px'}} 
												>
														Participation in NCC. தேசிய மாணவர்வ்படை சிறப்புகள் ஏதேனும் உள்ளதா ?  
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												 >
												  		<input 
															type="radio" 
															name="i__inNcc" 
															className="form-control" 
															value="1" 
															required 
															onChange={this.handleSportsChange.bind(this, 'nccStatus')} 
															defaultChecked={this.field('in_ncc')=='1'}
														/>
												 	 <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												>
													  <input 
													  		type="radio" 
															name="i__inNcc" 
															className="form-control" 
															value="0" 
															required
															onChange={this.handleSportsChange.bind(this, 'nccStatus')} 
															defaultChecked={this.field('in_ncc')=='0'}
														/>
												  <span className="">No</span>
												</label>
												{this.state.nccStatus=='1' && this.context.settingValue('admission_ug_cert_ncc_require')=='1' && (<p className='text-danger' style={{fontSize: '14px'}}>
													Upload the certificate
												</p>)}
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label 
													style={{fontSize: '14px'}} 
												>
														Participation in NSS. நாட்டு நலப்பணித்திட்டம் சிறப்புகள் ஏதேனும் உள்ளதா ?   
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}}
												 >
												  		<input 
															type="radio" 
															name="i__inNss" 
															className="form-control" 
															value="1" 
															required 
															onChange={this.handleSportsChange.bind(this, 'nssStatus')} 
															defaultChecked={this.field('in_nss')=='1'}
														/>
												  <span className="">Yes</span>
												</label>
												<label 
													className="custom_radio_input"
												 >
												 		<input 
														 	type="radio" 
															name="i__inNss" 
															className="form-control" 
															value="0" 
															required 
															onChange={this.handleSportsChange.bind(this, 'nssStatus')} 
															defaultChecked={this.field('in_nss')=='0'}
														/>
												  <span className="">No</span>
												</label>
												{this.state.nssStatus=='1' && this.context.settingValue('admission_ug_cert_nss_require')=='1' && (<p className='text-danger' style={{fontSize: '14px'}}>
													Upload the certificate
												</p>)}
											</Col>
										</Row>

										{this.context.settingValue('admission_form_need_ug_hostel_field')=='1' ? 
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												
											</Col>
											<Col md={8}>
												<label 
													style={{fontSize: '14px'}} 
												>
														Does the applicant need Hostel Accommodation?   
														<span className="text-danger">*</span>
												</label>
												<label 
													className="custom_radio_input" 
													style={{width: '100%', marginTop: '10px'}} 
												>
													  <input 
													  	type="radio" 
														name="i__hostel" 
														value="1" 
														required 
														className='form-control' 
														defaultChecked={this.field('need_hostel')=='1'}
													/>
													  <span className="">Yes</span>
												</label>
												<label 												
													className="custom_radio_input"
												 >
												  		<input 
															type="radio" 
															name="i__hostel" 
															value="0" 
															required 
															className='form-control' 
															defaultChecked={this.field('need_hostel')=='0'}
														/>
												 		<span className="">No</span>
												</label>
											</Col>
										</Row>
										: <input type="hidden" name="i__hostel" value="0" />}
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label style={{fontSize: '14px'}} >Full Address <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="text" 
													className="form-control form-control-sm" 
													required 
													name="i__street" 
													placeholder="Door No / Street" 
													required 
													defaultValue={this.field('street')}
												/>
												<input 
													type="text" 
													className="form-control form-control-sm mt-10" 
													required 
													name="i__place" 
													placeholder="Place Name" 
													required 
													defaultValue={this.field('place')}
												/>
												<input 
													type="text" 
													className="form-control form-control-sm mt-10" 
													required 
													name="i__city" 
													placeholder="City" 
													defaultValue={this.field('city')}
												/>
												<input 
													type="text" 
													className="form-control form-control-sm mt-10" 
													required 
													name="i__district" 
													placeholder="District" 
													required 
													defaultValue={this.field('district')}
												/>
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label style={{fontSize: '14px'}} >Country <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<CountryDropdown
												classes="form-control form-control-sm" 
												name="i__country" 
												value={this.state.selectedCountry}
											  	required="required"
											  	onChange={(val)=>{this.setState({selectedCountry:val})}} />
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label style={{fontSize: '14px'}} >State <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<RegionDropdown
												classes="form-control form-control-sm" 
												name="i__state" 
												country={this.state.selectedCountry} 
												value={this.state.selectedState}
												required="required"
											 	onChange={(val)=>{this.setState({selectedState:val})}} />
											</Col>
										</Row>
										
										<Row className="cl__row_btm_border mt-15" >
											<Col md={3} >
												<label style={{fontSize: '14px'}} >Pincode <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input 
													type="number" 
													className="form-control form-control-sm" 
													required 
													name="i__pinCode" 
													placeholder="Pincode" 
													required 
													defaultValue={this.field('pincode')}
												/>
											</Col>
										</Row>
																				
											
											<Row className="mt-15" >
												<Col md={3} ><label>Captcha <span className="text-danger" >*</span></label></Col>
												<Col md={2} >
													<img src={this.state.captchaImg} />
												</Col>
												<Col md={4}  >
													<input type="text" className="form-control form-control-sm" placeholder="Captcha" name="i__captcha" required />
												</Col>
												<Col md={3} >
													<button className="btn btn-outline-secondary btn-sm" type="button" onClick={this.loadCaptcha} >
														<i className="ion-ios7-reload" /> Refresh
													</button>
												</Col>
											</Row>
											
											<input type="hidden" name="i__captchaCode" value={this.state.captchaCode} />
											<input type="hidden" name="i__profileImage" value={this.state.selectedFile} />
											
											<Row className="mt-15 py-20" >
												<Col md={12} >
													<button type="submit" className="btn btn-success btn-block" disabled={this.state.btnText!='Submit'} >
														{this.state.btnText}
													</button>
												</Col>
											</Row>
											
										</Form>
										
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	else{
		return(<>
			<center>
				<Spinner border="animation" />
			</center>
		</>);
	}

	}
	
}
export default EditPersonalInformation;
