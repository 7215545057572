import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {InstructionHeader, PageLoader} from '../../elements';

class NotFoundAlert extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	

	
	render(){
		
		
		return(
			<React.Fragment>
				
				
				<section>
					<div className="container" >
						
						<Row className="mt-50" >
							<Col md={{span:6, offset:3}} >
							
								<Card className="shadow-lg" >
									<Card.Body>
										
										<h3 className="py-50" >Sorry the requested page not found.!</h3>
										
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
						
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default NotFoundAlert;
