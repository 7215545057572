import React, { Component, Suspense, lazy, useContext, useEffect } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import EmptyLayout from '../layouts/EmptyLayout';

import PortalLockWindow from '../views/pages/portalLockWindow';
import PsContext from '../context';
import { Spinner } from 'react-bootstrap';

const routes = (props) =>{

	const context = useContext(PsContext);

	useEffect(()=>{
		
		context.loadSettings();
	},[]);

	if(context.settingValue('admission_portal_locked')=='1' && context.portalLocked=='1'){
		return(<>
			<PortalLockWindow />
		</>);
	}
	else{
		
		return(
			<BrowserRouter base="/" >
				<Switch>
					<Route path="/" component={SiteLayout} />
					<Route path="/df" component={EmptyLayout} />
					<Redirect from="*" to="/" />
				</Switch>
			</BrowserRouter >);
			
	}
	/*else{
		return(<>
			<center>
				<Spinner animation="border" />
			</center>
		</>);
	}*/
};

export default routes;

/*
export default () => (
<PortalLockWindow>
	<BrowserRouter base="/" >
		<Switch>
			<Route path="/" component={SiteLayout} />
			<Route path="/df" component={EmptyLayout} />
			<Redirect from="*" to="/" />
		</Switch>
	</BrowserRouter >
</PortalLockWindow>	
);
*/