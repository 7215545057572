import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class InstructionForMarksUpload extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		
		
		return(
			<Card className="mb-15 shadow-lg" >
				<Card.Body>
					<h6>Please read the instructions carefully - கவனமாக படித்துவிட்டு தொடரவும்</h6>
					<hr />
					<ul style={{fontSize: '13px', lineHeight: '24px'}} >
						<li>Mention the qualifying examination passed.</li>
						<li>HSC or equivalent - Academic stream / Vocational Stream</li>
						<li>தகுதி படிப்பிற்கான பொதுத் தேர்வினை குறிப்பிடுக</li>
						<li>மேல்நிலை - பள்ளிப் படிப்பு அல்லது அதற்கு இணையானது-பொதுகல்வி முறை / தொழிற்கல்வி முறை</li>
					</ul>
				</Card.Body>
			</Card>
		);
	}
	
}
export default InstructionForMarksUpload;
