import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class Instruction extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		
		
		return(
			<Card className="mb-15 shadow-lg" >
				<Card.Body>
					<h6>Please read the instructions carefully - கவனமாக படித்துவிட்டு தொடரவும்</h6>
					<hr />
					<ul style={{fontSize: '13px', lineHeight: '24px'}} >
						<li>Fields marked with (<span className="text-danger">*</span>) are required</li>
						<li>Upload Image in JPG (or) PNG format only (minimum 300kb of size)</li>
						<li>(<span className="text-danger">*</span>) இக் குறியீடு உள்ள இடங்களை கண்டிப்பாக நிரப்பவும்.</li>
						<li>உங்கள் புகைப்படத்தை PNG அல்லது JPG format-ல் மட்டும் இணைக்கவும்</li>
					</ul>
				</Card.Body>
			</Card>
		);
	}
	
}
export default Instruction;
