import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import $ from "jquery";
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Var } from "../../../utils/Variables";
import API from "../../../utils/API";
import { integerIndMobile, getlsItem, setlsItem, setls } from "../../../utils";
import { InstructionHeader, BlockLoader } from "../../elements";
import PsContext from "../../../context";

class OtpVerify extends Component {
  static contextType = PsContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      btnText: "Verify OTP",
      captchaImg: "",
      captchaCode: "",
      enableResend: false,
      timeSpan: 180,
      isRedirect: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.showTimeSpan = this.showTimeSpan.bind(this);
    this.handleResendOtp = this.handleResendOtp.bind(this);
  }

  componentDidMount() {
    setInterval(this.showTimeSpan, 1000);
  }

  showTimeSpan() {
    if (this.state.timeSpan == 0) {
      this.setState({ enableResend: true });
    } else {
      this.setState({ timeSpan: this.state.timeSpan - 1 });
    }
  }

  handleResendOtp() {
    try {
      this.setState({ showLoader: true });
      var form = new FormData();
      form.append("i__api", getlsItem("reg_api_code"));

      API.post("v1/mobileotp/resend", form).then((res) => {
        if (res["data"].status == "1") {
          notify.show(res["data"].message, "success");
          this.setState({
            enableResend: false,
            timeSpan: 180,
            showLoader: false,
          });
        } else {
          notify.show(res["data"].message, "error");
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      this.setState({ showLoader: false });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    try {
      this.setState({ btnText: "Validating..", showLoader: true });
      API.post("v1/mobileotp/verify", $("#frmMobOtpVerify").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            notify.show(res["data"].message, "success");
            //  setlsItem("reg_hadotp", "no");
            setlsItem("reg_hadmobile", "");
            setlsItem("reg_api_code", "");
            //setlsItem('logged', 'yes');
            //setls('logged_user', res['data'].user);
            this.context.saveLogin(res["data"].user, "", res["data"].api);
            this.context.updateLogged();
            this.setState({
              btnText: "Verify OTP",
              showLoader: false,
              isRedirect: true,
            });
          } else {
            notify.show(res["data"].message, "error");
            this.setState({ btnText: "Verify OTP", showLoader: false });
          }
        }
      );
    } catch (error) {
      this.setState({ btnText: "Verify OTP", showLoader: false });
    }
  }

  render() {
    if (this.state.isRedirect || getlsItem("reg_hadotp") != "yes") {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <InstructionHeader title="Mobile Verification" />

        <BlockLoader loading={this.state.showLoader} />

        <section>
          <div className="container mt-30 pb-30">
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Card className="shadow-lg mt-30">
                  <Card.Body>
                    <form
                      action=""
                      method="post"
                      encType="multipart/form-data"
                      id="frmMobOtpVerify"
                      onSubmit={this.handleFormSubmit}
                    >
                      <input
                        type="hidden"
                        name="i__api"
                        value={getlsItem("reg_api_code")}
                      />
                      <Row>
                        <Col md={12}>
                          <div className="text-center">
                            <h5>Mobile number verification</h5>
                            <hr />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            readOnly
                            value={getlsItem("reg_hadmobile")}
                            style={{ textAlign: "center" }}
                            name="i__mobile"
                          />
                        </Col>
                      </Row>

                      {/* <Row className="mt-20">
                        <Col md={12}>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            style={{ textAlign: "center" }}
                            name="i__otp"
                            required
                            placeholder="Enter Your OTP"
                            autoFocus
                          />
                        </Col>
                      </Row> */}

                      <Row className="mt-15">
                        <Col md={12}>
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-success btn-block"
                            disabled={this.state.btnText != "Verify OTP"}
                          >
                            {this.state.btnText}
                          </button>
                        </Col>
                        <Col md={12}>
                          <div className="text-center mt-15">
                            {this.state.enableResend
                              ? null
                              : this.state.timeSpan}{" "}
                            &emsp;
                            {this.state.enableResend ? (
                              <a
                                href="javascript:;"
                                onClick={this.handleResendOtp}
                              >
                                {" "}
                                Resend
                              </a>
                            ) : (
                              <a
                                href="javascript:;"
                                style={{ color: "rgb(173, 173, 173)" }}
                              >
                                {" "}
                                Resend
                              </a>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </form>

                    <div className="py-40"></div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default OtpVerify;
