import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";



class Announcement extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<div className="container" >
					<Row>
						<Col md={{span:6, offset:3}} >
							<Card>
								<Card.Body>
									<div>
										தேர்வு செய்யப்பட்ட முதலாம் ஆண்டு மாணவிகள் (2020-2021) இணைய வழி மூலம் கல்விக் கட்டணம் கட்டும் கடைசி நாள் 26.08.2020.   தவறினால் சேர்க்கை ரத்து  செய்யப்படும்.
									</div>
									
									<div className="alert alert-info mt-30" style={{fontWeight: '600'}} >
										Admission for the academic year 2020 -2021 will be conducted only through online. The online application for UG programmes will be available from July 20, 2020 onwards.
									</div>
								</Card.Body>						
							</Card>
						</Col>					
					</Row>
				</div>
				
					
			</React.Fragment>
		);
	}
	
}
export default Announcement;
