import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import moment from 'moment';
import jwt from 'jwt-simple';
import $ from 'jquery';

const COOKIE_KEY = 'spca202';


/**
* instance for cookie class
*/
const cookies = new Cookies();


/**
* set
* to set Cookie value
* @param1 cookiename
* @param2 Cookie value
* @return NULL
*/
export function setCookie(cookieName,cookieValue){
	
	cookies.set(COOKIE_KEY + cookieName, cookieValue);
}

/**
* get
* to get Cookie value 
* @param1 cookiename
* @return COOKIE VALUE
*/
export function getCookie(cookieName){
	
	return cookies.get(COOKIE_KEY + cookieName);
}

/**
* get
* to get Cookie value from stored cookie array
* @param1 cookiename
* @param2 fieldName
* @return COOKIE VALUE
*/
export function getCookieField(cookieName, fieldName){
	try{
		var s = cookies.get(COOKIE_KEY + cookieName);
		var json = s; //JSON.parse(s);
		return json[fieldName];
	}
	catch(error){
		console.log(error);
	}
}

/**
* remove
* to remove Cookie value 
* @param1 cookiename
* @return NULL
*/
export function removeCookie(cookieName){
	
	return cookies.remove(COOKIE_KEY + cookieName);
}

/**
* removeAll
* to remove all the Cookie value s
* @param1 NULL
* @return NULL
*/
export function removeAll(){
	
	return cookies.remove();
}


/**
* setlsItem
* to set local storage
* @param1 $key
* @param2 $value
*/
export function setlsItem(key,value){
	try{
		
		localStorage.setItem(COOKIE_KEY + key, value);
	}
	catch(error){ }
}

/**
* setls
* to set local storage 
* @param1 $key
* @param2 $value (JSON)
*/
export function setls(key,value){
	try{
		
		localStorage.setItem(COOKIE_KEY + key,  JSON.stringify(value));
	}
	catch(error){ }
}

/**
* getls
* to get local storage object filed
* @param1 $key
* @param2 $field
* @return string
*/
export function getls(key, field){
	try{
		
		if (localStorage.length > 0) {
			let item = JSON.parse(localStorage.getItem(COOKIE_KEY + key));
			return item[field];
		}
	}
	catch(error){ }
}

/**
* getls
* to get local storage field value
* @param1 $key
* @param2 $field
* @return string
*/
export function getlsItem(key, field){
	try{
		
		if (localStorage.length > 0) {
			return localStorage.getItem(COOKIE_KEY + key);
		}
	}
	catch(error){ }
}

/**
* getAcronym
* to get first letter of each word in a string
* @param1 stringValue
* @param2 noOfChars
* @return String
*/
export function getAcronym(stringValue,noOfChars=1){
	try{
		var str = stringValue; //"Java Script Object Notation";
		var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
		var acronym = matches.join(''); // JSON

		return acronym.slice(0,noOfChars);
	}
	catch(error){
		console.log(error);
	}
}

/**
* groupByMultiple
* to group by multiple field name in a json
* @param1 $array
* @param2 $fieldList
* @return JSON
*/
export function groupByMultiple( array , f ){
	  var groups = {};
	  array.forEach( function( o )
	  {
		var group = JSON.stringify( f(o) );
		groups[group] = groups[group] || [];
		groups[group].push( o );  
	  });
	  return Object.keys(groups).map( function( group )
	  {
		return groups[group]; 
	  });
}

/**
* capitalizeFirst
* to capitalize the first letter of the word
* @param1 $str (string)
* @return string
*/
export function capitalizeFirst(str) {
	if(str=='' || str==null || str==' '){		
		return '';
	}
	else{
		str = str.toLowerCase();
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
}

/**
* upperCase
* to conver the string to upper case
* @param1 $str (string)
* @return string
*/
export function upperCase(str) {
	if(str=='' || str==null || str==' ') 
		return '';
	else
		return  str.toString().toUpperCase();
}

export function lowerCase(str) {
	if(str=='' || str==null || str==' ') 
		return '';
	else
		return  str.toString().toLowerCase();
}

/**
* yesorNo
* to get the value yes or no form the boolean
* @param1 $val (bool) true,false, 1,0
* @param2 $withColor (true,false) default false
* @return String
*/
export function yesorNo(val,withColor=false){
	if(val=='' || val==null || val==' ')
		return 'No';
	else if(val=='1' || val==true)
		return 'Yes';
	else 
		return 'No';
}

/**
* isNullOrEmpty
* to check the given value is null or empty
* @param $val
* @return Boolean
*/
export function isNullOrEmpty(val){
	try{
		if(val=='' || val==null || val==' ' || val==NaN)
			return false;
		else return true;
	}
	catch(error){
		console.log(error);
		return true;
	}
}
 
 
 /**
 * calculateMessageCount
 * to calculate letters count
 * @param1 $textArea
 * @param2 $displayArea
 * @return HTML
 */
 export function calculateMsgCount(textAreaId,divId){
	 try{
		 
		 var singleSmsLength = 160;
		 var ele = document.getElementById(textAreaId);
		 var charLength = ele.value.length;
		 var msgCount = Math.ceil(charLength / singleSmsLength);
		 document.getElementById(divId).innerHTML = charLength+' / '+msgCount;
	 }
	 catch(error){
		console.log(error); 
	 }
 }
 
 
 /**
 * momentDate
 * to convert a date format using moment
 * @param1 $date 
 * @param2 $format
 * @return date
 */
 export function momentDate(value,toFormat="DD/MMM/YYYY"){
	 try{
		 
		 if(value=='0000-00-00' || value=='' || value==' ' || value==null || value==NaN)
		 {
			 return '';
		 }
		 else
		 {
			 //var d = new Date(value).getTime();
			return moment(value).format(toFormat);
		 }
	 }
	 catch(error){
		 console.log(error);
		 return '';
	 }
 }
 

 export function indAadharKeyPress(e){
	let len = e.target.value.length;
	if(len>=12){
		e.preventDefault();
		return false;
	}
	if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
		//showAdvice(this, "Integer values only");
		e.preventDefault();
		return false;
	}
}
 


export function indEmisNoKeyPress(e){
	let len = e.target.value.length;
	if(len>=22){
		e.preventDefault();
		return false;
	}
	if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
		//showAdvice(this, "Integer values only");
		e.preventDefault();
		return false;
	}
}
 

/**
* integerKeyPress
* to handle textbox key press event and check whether the input is integer or not
*
* @param EVENT
* @return NULL
*/
export function integerKeyPress(e){
	if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
		//showAdvice(this, "Integer values only");
		e.preventDefault();
		return false;
	}
}


/**
* decimalKeyPress
* to handle textbox key press event and check whether the input is decimal or not
*
* @param EVENT
* @return NULL
*/
export function decimalKeyPress(e){
	if ( e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
		//showAdvice(this, "Decimal numbers only");
		e.preventDefault();
		return false;
	}
	if (e.which == 46 && e.target.value.indexOf(".") != -1) {
	   // showAdvice(this, "Only one period allowed in decimal numbers");
	   e.preventDefault();
		return false;   // only one decimal allowed
	}
}

/**
* lettersOnly
* to handle textbox key press event and check whether the input is alphabet or not
*
* @param EVENT
* @return NULL
*/
export function lettersOnly(e){
	var inputValue = e.which;
	// allow letters and whitespaces only.
	if(e.which != 8 && !(inputValue >= 65 && inputValue <= 122) && (inputValue != 32 && inputValue != 0)) { 
		e.preventDefault(); 
		return(false);
	}
}


/**
* integerIndMobile
* to handle textbox key press event and check whether the input is integer and less than 10 characters or not
*
* @param EVENT
* @return NULL
*/
export function integerIndMobile(e){
	let len = e.target.value.length;
	if(len>=10){
		e.preventDefault();
		return false;
	}
	
	if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
		//showAdvice(this, "Integer values only");
		e.preventDefault();
		return false;
	}
}

/**
* numberToWords
* to convert number to words
*
* @param1 number (int)
* @return string
*/
export function numberToWords(amount) {
	var words = new Array();
	words[0] = '';
	words[1] = 'One';
	words[2] = 'Two';
	words[3] = 'Three';
	words[4] = 'Four';
	words[5] = 'Five';
	words[6] = 'Six';
	words[7] = 'Seven';
	words[8] = 'Eight';
	words[9] = 'Nine';
	words[10] = 'Ten';
	words[11] = 'Eleven';
	words[12] = 'Twelve';
	words[13] = 'Thirteen';
	words[14] = 'Fourteen';
	words[15] = 'Fifteen';
	words[16] = 'Sixteen';
	words[17] = 'Seventeen';
	words[18] = 'Eighteen';
	words[19] = 'Nineteen';
	words[20] = 'Twenty';
	words[30] = 'Thirty';
	words[40] = 'Forty';
	words[50] = 'Fifty';
	words[60] = 'Sixty';
	words[70] = 'Seventy';
	words[80] = 'Eighty';
	words[90] = 'Ninety';
	amount = amount.toString();
	var atemp = amount.split(".");
	var number = atemp[0].split(",").join("");
	var n_length = number.length;
	var words_string = "";
	if (n_length <= 9) {
		var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
		var received_n_array = new Array();
		for (var i = 0; i < n_length; i++) {
			received_n_array[i] = number.substr(i, 1);
		}
		for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
			n_array[i] = received_n_array[j];
		}
		for (var i = 0, j = 1; i < 9; i++, j++) {
			if (i == 0 || i == 2 || i == 4 || i == 7) {
				if (n_array[i] == 1) {
					n_array[j] = 10 + parseInt(n_array[j]);
					n_array[i] = 0;
				}
			}
		}
		var value = "";
		for (var i = 0; i < 9; i++) {
			if (i == 0 || i == 2 || i == 4 || i == 7) {
				value = n_array[i] * 10;
			} else {
				value = n_array[i];
			}
			if (value != 0) {
				words_string += words[value] + " ";
			}
			if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
				words_string += "Crores ";
			}
			if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
				words_string += "Lakhs ";
			}
			if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
				words_string += "Thousand ";
			}
			if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
				words_string += "Hundred and ";
			} else if (i == 6 && value != 0) {
				words_string += "Hundred ";
			}
		}
		words_string = words_string.split("  ").join(" ");
	}
	return words_string;
}


/**
* makeUrl
* to convert the string into url
* to remove all the special characters and remove space 
*
* @param1 $str 
* @return String
*/
export function makeUrl(str){
	try{
		str = str.replace(/[&\/\\#,+()$~%.\'":*?<>{}]/g, '');
		str = str.replace(/ /g, "-"); 
		return str.toLowerCase();
	}
	catch(error){
		console.log(error);
	}
}


/**
* getAmountColor
* to return amount with color
*
* @param1 $amount 
* @return Html
*/
export function getAmountColor(amount){
	try{
		if(parseFloat(amount)>0)
		{
			return <span className="text-success">{Math.abs(amount).toFixed(2)}</span>;
		}
		else
		{
			return <span className="text-danger">{Math.abs(amount).toFixed(2)}</span>;
		}
	}
	catch(error){
		console.log(error);
		return <span className="text-success">0.00</span>;
	}
}


/**
* href()
* if params then return value else return javascript:;
*
* @param1 $value
* @return string
*/
export function href(value){
	try{
		
		return (value!='' && value!=' ' && value!=null && value!==undefined) ? value : 'javascript:;';
	}
	catch(error){
		return 'javascript:;';
	}
}


/**
* getAscSortOrder
* to sort an array by particular field 
* @param Property of an array
* @return int
*/
export function getAscSortOrder(prop) {  
    return function(a, b) {  
        if (a[prop] > b[prop]) {  
            return 1;  
        } else if (a[prop] < b[prop]) {  
            return -1;  
        }  
        return 0;  
    }  
}

/**
* getDescSortOrder
* to sort an array by particular field 
* @param Property of an array
* @return int
*/
export function getDescSortOrder(prop) {  
    return function(a, b) {  
        if (a[prop] < b[prop]) {  
            return 1;  
        } else if (a[prop] > b[prop]) {  
            return -1;  
        }  
        return 0;  
    }  
}

/**
* removeBothSlash
* to remove the first and last slash from a string
* @param1 $string
* @return String
*/
export function removeBothSlash(str){
	return str.replace(/^\/|\/$/g, '');
}

/**
* loadBg
* to load background image for a section
* @param NULL
* @return NULL
*/
export function loadBg(){
	var $bgSection = $(".bg-section");
	var $bgPattern = $(".bg-pattern");
	var $colBg = $(".col-bg");

	$bgSection.each(function() {
		var bgSrc = $(this).children("img").attr("src");
		var bgUrl = 'url(' + bgSrc + ')';
		$(this).parent().css("backgroundImage", bgUrl);
		$(this).parent().addClass("bg-section");
		$(this).remove();
	});

	$bgPattern.each(function() {
		var bgSrc = $(this).children("img").attr("src");
		var bgUrl = 'url(' + bgSrc + ')';
		$(this).parent().css("backgroundImage", bgUrl);
		$(this).parent().addClass("bg-pattern");
		$(this).remove();
	});

	$colBg.each(function() {
		var bgSrc = $(this).children("img").attr("src");
		var bgUrl = 'url(' + bgSrc + ')';
		$(this).parent().css("backgroundImage", bgUrl);
		$(this).parent().addClass("col-bg");
		$(this).remove();
	});
}


/**
* numberToMonth
* to convert number to month 
*
* @since 1.0
* @param1 number
* @return String
*/
export function numberToMonth(number){
	
	var months = [];
	months['01'] = 'January';
	months['02'] = 'February';
	months['03'] = 'March';
	months['04'] = 'April';
	months['05'] = 'May';
	months['06'] = 'June';
	months['07'] = 'July';
	months['08'] = 'August';
	months['09'] = 'September';
	months['10'] = 'October';
	months['11'] = 'November';
	months['12'] = 'December';
	
	return months[number];
}
