import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card, Alert} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {getls, groupByMultiple, capitalizeFirst, getlsItem} from '../../../utils';
import {PageLoader} from '../../elements';

import NotFoundAlert from '../NotFound/NotFoundAlert';

class AdmissionFeeComplete extends Component{
	constructor(props){
		super(props);
		this.state = {
			btnText: 'PAY NOW',
			showLoader: false,
			orderId: this.props.match.params.orderId,
			orderData: [],
		}
		
		
	}
	
	componentDidMount(){
		
		this.getInfo();
	}
	
	getInfo(){
		try{
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('i__orderId', this.state.orderId);
			form.append('i__apiCode', getls('logged_user', 'api_code'));
			API.post('v1/ug/installment_orders', form).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({orderData: res['data'].data, showLoader: false});
				}
				else{
					//notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getField(field){
		try{
			var d = this.state.orderData;
			return d[0][field];
		}
		catch(error){
			return '';
		}
	}
	
	
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		let sno=1;
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
							
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:8, offset:2}} >
								
								
								
								<Card className="shadow-lg" >
									<Card.Body>
									{this.state.showLoader ? <div></div> :
									<div>
										{Object.keys(this.state.orderData).length>0 && this.getField('installment_pay_status')=='0' ? <div>
											<Alert variant="danger" >
												<h4>We are getting error in process the payment</h4>
												<hr />
												<p>Payment Status : {this.getField('installment_pay_status')}</p>
												<p>Reason : {this.getField('installment_status_msg')}</p>
											</Alert>
											
											<Alert variant="info text-info" >
												If Your money is debited from your bank account, Please don't pay one more time for the same course. Please contact College office for further assistance.
												<hr />Office :  <b>{Var.Phone}</b>
											</Alert>
										</div>
										: Object.keys(this.state.orderData).length > 0 ?
										<div>
											<Alert variant="success" >
												<h4>Your payment has been completed.</h4>
												<hr />
												<p>Payment Status : {this.getField('installment_pay_status')}</p>
												<p>Reason : {this.getField('installment_status_msg')}</p>
											</Alert>
											
											<Alert variant="info text-info" >
												If Your money is debited from your bank account, Please don't pay one more time for the same course. Please contact College office for further assistance.
												<hr />Office :  <b>{Var.Phone}</b>
											</Alert>
										</div>
										:
										<div>
											<Alert variant="danger text-center" >
												<h4>Sorry we are getting error in process your request. Please try again later.</h4>
											</Alert>
											<Alert variant="info text-info" >
												If Your money is debited from your bank account, Please don't pay one more time for the same course. Please contact College office for further assistance.
												<hr />Office :  <b>{Var.Phone}</b>
											</Alert>
										</div>
										}
																				
									
									</div>
									}
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				}
				
			</React.Fragment>
		);
	}
	
}
export default AdmissionFeeComplete;
