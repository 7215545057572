import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Modal, Alert, Button } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import LOGO from '../../../assets/img/spc-logo.png';
import FAV_LOGO from '../../../assets/img/spc-fav-logo.png';

class MsgBox extends Component{
	constructor(props){
		super(props);
		this.state = {
			show: this.props.show,
		}		
		
		this.handleHide = this.handleHide.bind(this);
	}
	
	handleHide(){
		if(this.props.hide)
			this.props.hide();
		
		this.setState({show: false});
	}
	
	render(){
		return(
			<Modal show={this.state.show} onHide={this.handleHide} backdrop="static" >
				<Modal.Header closeButton style={{padding: '8px'}} >
					{this.props.title || 'Message'}
				</Modal.Header>
				<Modal.Body>
				{this.props.alertType ? 
					<Alert variant={this.props.alertType} >
						{this.props.message}
					</Alert>
					: 
					<div>
						{this.props.message}
					</div>
				}
				</Modal.Body>
				<Modal.Footer>
					<div className="pull-right" >
						<Button type="button" size="sm"  variant="primary" onClick={this.handleHide} >
							&emsp; Ok &emsp;
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
	
}
export default MsgBox;
