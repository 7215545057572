import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import {integerIndMobile,getlsItem, setlsItem, getls} from '../../../utils';
import {InstructionHeader} from '../../elements';

class ChangePassword extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Change Password',
			captchaImg: '',
			captchaCode: '',
			enableResend: false,
			timeSpan: 180,
			isRedirect: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnText: 'Updating..', showLoader: true});
			API.post('v1/password/update', $("#frmChangePass").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmChangePass").reset();
					notify.show(res['data'].message,'success');
					this.setState({btnText: 'Change Password', showLoader: false, isRedirect: true});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Change Password', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Change Password', showLoader: false});
		}
	}
	
	render(){
		
			
		return(
			<form action="" method="post" encType="multipart/form-data" id="frmChangePass" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__apiCode" value={getls('logged_user', 'api_code')} />
				<input type="hidden" name="i__userId" value={getls('logged_user', 'user_id')} />
				<input type="hidden" name="i__userCode" value={getls('logged_user', 'user_code')} />
				
				<Row>
					<Col md={12} >
						<label>Current Password</label>
						<input type="password" className="form-control form-control-sm" required name="i__currentPass" />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<label>New Password</label>
						<input type="password" className="form-control form-control-sm" required name="i__newPass" />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<label>Confirm Password</label>
						<input type="password" className="form-control form-control-sm" required name="i__confirmPass" />
					</Col>
				</Row>
				
				<Row className="mt-25 mb-20" >
					<Col md={12} >
						<button type="submit" className="btn btn-success btn-md btn-block" disabled={this.state.btnText!='Change Password'} >
							{this.state.btnText}
						</button>
					</Col>
				</Row>
				
			</form>										
		);
	}
	
}
export default ChangePassword;
