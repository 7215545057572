import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Footer} from '../../elements';

import LoginForm from './LoginForm';
import RegisterFor from './RegisterFor';
import Announcement from './Announcement';
import HelpLinePage from './helpLinePage';

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			showAlert: true,
		}
	}
	
	
	
	render(){
		
		return(
			<section>
			
			{/*<div className="container" >
					<Row className="mt-50" >
						<Col md={{span:8, offset:2}} >
							<Card>
								<Card.Body>
						
								</Card.Body>
							</Card>
						</Col>
					</Row>
			</div>*/}
				
				<div className="container" >
					
					<Row className="mt-50" >
						
						<Col md={12} >
							<Announcement />
						</Col>
						
						<Col md={8} >
							<RegisterFor />

							<HelpLinePage />
							
						</Col>
						
						<Col md={4} >
							<LoginForm />
						</Col>
						
					</Row>
					
			
				</div>
						
			</section>
		);
		
	}
	
}
export default Login;
