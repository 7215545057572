import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ReactFileReader from 'react-file-reader';
import {Var, MonthData} from '../../../utils/Variables';
import API from '../../../utils/API';
import {integerIndMobile,setlsItem, getls, setls, decimalKeyPress, numberToMonth, upperCase, getlsItem, groupByMultiple} from '../../../utils';
import {PageLoader} from '../../elements';
import SELECT_IMAGE from '../../../assets/img/select-image.png';
import Select from "react-dropdown-select";

import InstructionForMarksUpload from './InstructionForMarksUpload';
import PsContext from '../../../context';

class MarksUpload extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save Marks',
			captchaImg: '',
			captchaCode: '',
			isRedirect: false,
			redirectToCourseApply: false,
			selectedCountry: '',
			selectedState: '',
			selectedFile: '',
			applicantInfo: [],
			hscSubjects: [],
			selectedCommunity: '',
			studiedUg: '',
			scoredTotal: 0,
			part3Total : 0,
			maxTotal: 0,
			part3MaxTotal: 0,
			part3PercentageTotal: 0,
			registerNo: '',
			ugDegreeList: [],
			ugDegree: [],
			ugMajor: [],
			today: '',
			subject3: '',
			subject4: '',
			subject5: '',
			subject6: '',
		}
		this.loadCaptcha = this.loadCaptcha.bind(this);
		this.handleProfilePhotChange = this.handleProfilePhotChange.bind(this);
		this.marksInputOnBlur = this.marksInputOnBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleStudiedDegreeChange = this.handleStudiedDegreeChange.bind(this);
		this.handleMajorChange = this.handleMajorChange.bind(this);
	}
	
	componentDidMount(){
		var MyDate = new Date();
		var d =  MyDate.getFullYear()+'-'+('0' + (MyDate.getMonth()+1)).slice(-2)+'-'+('0' + MyDate.getDate()).slice(-2);
		this.setState({today: d});
		this.loadApplicantInfo();
		this.loadUgForPg();
		//this.loadHscSubjects();
		//this.loadCaptcha();
	}
	
	loadApplicantInfo(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', this.context.api);
			API.post('v1/pgapplicant/min_info',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({applicantInfo: res['data'].data, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadHscSubjects(){
		try{
			this.setState({showLoader: true, hscSubjects: []});			
			API.get('v1/hsc/subjects').then(res=>{
				if(res['data'].status=='1'){
					this.setState({hscSubjects: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadCaptcha(){
		try{
			API.get('v1/captcha').then(res=>{
				this.setState({captchaImg: res['data'].img, captchaCode: res['data'].code});
			});
		}
		catch(error){ }
	}
	
	loadUgForPg(){
		try{
			API.get('v1/pg/get_ug_forpg').then(res=>{
				if(res['data'].status=='1'){
					var d = groupByMultiple(res['data'].data,function(obj){
						return[obj.ugdegree];
					});
					this.setState({ugDegreeList: res['data'].data, ugDegree: d});
				}
			});
		}
		catch(error){ }
	}
	
	handleStudiedDegreeChange(e){
		try{
			
			var d = this.state.ugDegreeList;
			var s = d.filter(function(obj){
				return(obj.ugdegree==e.target.value);
			});
			this.setState({ugMajor: s});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			/* var hasRegno=false;
			$(".hs_regno").each(function(k){
				if($(this).val()!='' && $(this).val()!=null) hasRegno = true;
			});
			
			if(!hasRegno)
			{
				$.alert({title:'Error',content:'Please Enter Your Higher Secondary register No!'});
				return false;
			} */
			
			/* if(this.state.subject3=='' || this.state.subject4=='' || this.state.subject5=='' || this.state.subject6=='')
			{
				$.alert('Please fill all the subject');
				return false;
			} */
			
			if(parseFloat(this.state.part3PercentageTotal)==0 || parseFloat(this.state.part3PercentageTotal)>100){
				alert('Please check your marks. your Part III overall percentage is greater thean 100');
				return;
			}
			
			if(!window.confirm('Please check all the filled informations before processing. Do you want to proceed next?')){
				return;
			}
			
			this.setState({btnText: 'Processing..', showLoader: true});
			API.post('v1/pg/register/ugmarksupload', $("#frmStepII").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message,'success');
					this.context.updateUser(res['data'].user);
					this.setState({btnText: 'Save Marks', showLoader: false, redirectToCourseApply: true});
				}
				else{
					notify.show(res['data'].message,'error');
					this.setState({btnText: 'Save Marks', showLoader: false});
					//this.loadCaptcha();
				}
			});
		}
		catch(error){
			this.setState({btnText: 'Save Marks', showLoader: false});
			this.loadCaptcha();
		}
	}
	
	handleProfilePhotChange(files){
		
		this.setState({selectedFile: files.base64});
	}
	
	marksInputOnBlur(e){
		try{
			var $this = $(e.target);
			var min = $($this).attr('data-min');
			var max = $($this).attr('data-max');
			var val = e.target.value;
			
			/* if(parseFloat(val)<parseFloat(min) || parseFloat(val)>parseFloat(max)){
				alert('Please Enter Correct mark');
				$($this).val('');
			} */
			
			this.updateScoredTotal();
		}
		catch(error){
			
		}
	}
	
	updateScoredTotal(){
		try{
			var scored = 0;
			var partTotal = 0;
			var maxTotal = 0;
			var part3MaxTotal = 0;
			var perTotal = 0;
			
			$(".i__mark_scored").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					scored = parseFloat(scored) + parseFloat(mark);
				}
			});
			
			$(".i__part3_scored").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					partTotal = parseFloat(partTotal) + parseFloat(mark);
				}
			});
			
			$(".i__mx_marks").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					maxTotal = parseFloat(maxTotal) + parseFloat(mark);
				}
			});
			
			$(".i__p3mx_marks").each(function(){
				var mark = $(this).val();
				if(mark!=''){
					part3MaxTotal = parseFloat(part3MaxTotal) + parseFloat(mark);
				}
			});
			
			perTotal = parseFloat((partTotal/part3MaxTotal)*100).toFixed(2);
			
			if(!parseFloat(perTotal) || perTotal=='Infinity') perTotal=0;
			
			this.setState({scoredTotal: scored, part3Total: partTotal, maxTotal: maxTotal, part3MaxTotal: part3MaxTotal, part3PercentageTotal: perTotal});
		}
		catch(error){ }
	}
	
	handleMajorChange(e){
		try{
			var v = e.target.value;
			var d = this.state.ugDegreeList;
			
			var index = d.findIndex(function(obj){
				return(obj.id==v);
			});
			
			if(index>-1)
				this.setState({studiedUg: d[index].ugmajor});
		}
		catch(erro){}
	}
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		if(this.state.isRedirect){
			return(<Redirect to="/app/dashboard" />);
		}

		if(this.state.redirectToCourseApply){
			return(<Redirect to="/app/pg-course-apply" />);
		}
		
		var minMark = 70;
		var maxMark = 200;
		var community = '';
		var hasGradeSystem = false;
		if(Object.keys(this.state.applicantInfo).length>0 && this.state.applicantInfo.year_of_passing=='2019'){
			minMark = 35;
			maxMark = 100;
			hasGradeSystem = true;
		}
		
		var year = [];		
	
		for(var i= ((new Date()).getFullYear());i>=((new Date()).getFullYear()-10);i--){
			year.push(i);
		}
		
		
		var profileImage = SELECT_IMAGE;
		if(this.state.selectedFile!='' && this.state.selectedFile.length>4)
			profileImage = this.state.selectedFile;
		
		
		if(Object.keys(this.state.applicantInfo).length>0){
			community = this.state.applicantInfo.community;
		}
		
		var subjects = [];
		this.state.hscSubjects.map((item,i)=>{
			subjects.push({value: item.id, label: item.name});
		});
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				<section>
					<div className="container mt-50 pb-30" >
						
						<Row >
							<Col md={{span:10, offset:1}} >
								
								{/*<InstructionForMarksUpload />*/}
								
								<Card className="shadow-lg" >
									<Card.Body>
										<form action="" method="post" encType="multipart/form-data" id="frmStepII" onSubmit={this.handleFormSubmit} >
											
											<input type="hidden" name="i__userId" value={this.context.user.user_id} />
											<input type="hidden" name="i__userCode" value={this.context.user.user_code} />
											<input type="hidden" name="i__apiCode" value={this.context.user.api_code} />
											<input type="hidden" name="i__studiedUg" value={this.state.studiedUg} />
											
											<Row>
												<Col md={12} >
													<div className="text-center" >
														<h5>PG Registration Step-II</h5>
														<hr />
													</div>
												</Col>
												<Col md={6} >
													<label>Name : <b>{upperCase(this.context.user.name)}</b></label>
												</Col>
												<Col md={6} className="text-end" >
													<label>Registration No : <b>{this.context.user.user_code}</b></label>
												</Col>
												
											</Row>
											
											
										
										<Row className="mt-15" >
											<Col md={3} >
												<label>College Previously studied <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input type="text" className="form-control form-control-sm" required name="i__collegeName" style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										
										
										<Row className="mt-15" >
											<Col md={3} >
												<label>Name of the University <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input type="text" className="form-control form-control-sm" required name="i__universityName" style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										
										
										<Row className="mt-15" >
											<Col md={3} >
												<label>Studied Degree & Major <span className="text-danger">*</span></label>
											</Col>
											<Col md={4}>
												<select name="i__studiedDegree" className="form-control form-control-sm" required style={{padding: '3px'}} onChange={this.handleStudiedDegreeChange} >
													<option value=""> -Select- </option>
													{this.state.ugDegree.map((item,i)=>{
														return <option value={item[0].ugdegree} > {item[0].ugdegree} </option>;
													})
													}
												</select>
											</Col>
											<Col md={4}>
												<select name="i__studiedMajor" className="form-control form-control-sm" required style={{padding: '3px'}} onChange={this.handleMajorChange}>
													<option value=""> -Select- </option>
													{this.state.ugMajor.map((item,i)=>{
														return <option value={item.id} data-id={item.id} > {upperCase(item.ugmajor)} </option>;
													})
													}
												</select>
											</Col>
										</Row>
											
										<Row className="mt-15" >
											<Col md={3} >
												<label>Month & Year of Passing <span className="text-danger">*</span></label>
											</Col>
											<Col md={4}>
												<select name="i__pgPassMonth" className="form-control form-control-sm" required style={{padding: '3px'}} >
													{MonthData.map((item,i)=>{
														return <option value={item.name} selected={item.id==4 ? 'selected' : ''} > {item.name} </option>;
													})
													}
												</select>
											</Col>
											<Col md={4}>
												<select name="i__pgPassYear" className="form-control form-control-sm" required style={{padding: '3px'}} >
													{year.map((item,i)=>{
														return <option value={item} > {item} </option>;
													})
													}
												</select>
											</Col>
										</Row>
										
										
										
											
										<Row className="mt-15" >
											<Col md={3} >
												<label>Register Number <span className="text-danger">*</span></label>
											</Col>
											<Col md={8}>
												<input type="text" className="form-control form-control-sm" required name="i__pgRegisterNumber" style={{textTransform: 'uppercase'}} />
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="px-0 mt-20" >
												<table className="table table-bordered table-sm cl__tbl__marksupload" >
													<thead className="thead-light" >
														<tr>
															<th width="220px" >Subject (பாடம்)</th>
															<th>Total Marks Obtained</th>
															<th>Maximum Marks</th>
															<th>Class / Grade Obtained</th>
															<th>Percentage of Marks</th>
															{/*<th>No.of Attempts</th>*/}
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="" >Part-I
																<input type="text" name="i__subjects[]" required  style={{textTransform: 'uppercase'}} value="Tamil" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored" onBlur={this.marksInputOnBlur} name="i__markScored[]" required disabled={hasGradeSystem} />
															</td>
															<td>
																<input type="number" className="cl__no__arrow i__mx_marks" name="i__maxMarks[]" required onBlur={this.marksInputOnBlur}   disabled={hasGradeSystem} />
															</td>
															<td>
															{/*<input type="text" className="cl__no__arrow" name="i__class[]" required />*/}
															<select  name="i__class[]"  className="cl__no__arrow" style={{padding:'4px'}} required >
																<option value="I"> I </option>
																<option value="II"> II </option>
																<option value="III"> III </option>
															</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  name="i__markPercentage[]" style={{width: '100px'}} required step="any" />
																<input type="hidden" name="i__attempts[]" value="1" />
															</td>
															{/*<td>
																<select  name="i__attempts[]"  className="cl__no__arrow" style={{padding:'4px'}}  >
																	<option value="1"> 1 </option>
																	<option value="2"> 2 </option>
																	<option value="3"> 3 </option>
																	<option value="4"> 4 </option>
																</select>
												</td>*/}
														</tr>
														
														<tr>
															<td className="" >Part-II
															<input type="text" name="i__subjects[]" required  style={{textTransform: 'uppercase'}} value="English" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored" onBlur={this.marksInputOnBlur} name="i__markScored[]" required  disabled={hasGradeSystem} />
															</td>
															<td>
																<input type="number" className="cl__no__arrow i__mx_marks" name="i__maxMarks[]" required onBlur={this.marksInputOnBlur}  disabled={hasGradeSystem}  />
															</td>
															<td>
																{/*<input type="text" className="cl__no__arrow" name="i__class[]" required />*/}
																<select  name="i__class[]"  className="cl__no__arrow" style={{padding:'4px'}} required >
																	<option value="I"> I </option>
																	<option value="II"> II </option>
																	<option value="III"> III </option>
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  step="any" name="i__markPercentage[]" style={{width: '100px'}} required   />
																<input type="hidden" name="i__attempts[]" value="1" />
															</td>
															{/*<td>
																<select  name="i__attempts[]"  className="cl__no__arrow" style={{padding:'4px'}}  >
																	<option value="1"> 1 </option>
																	<option value="2"> 2 </option>
																	<option value="3"> 3 </option>
																	<option value="4"> 4 </option>
																</select>
											</td>*/}
														</tr>
														<tr>
															<td colSpan="6">
																Part-III
															</td>
														</tr>
														<tr>
															<td className=""  >
															Major & Allied
															{this.context.settingValue('admission_pg_mark_part3_has_default')=='1' ? 
															<input type="text" name="i__subjects[]" required  style={{textTransform: 'uppercase'}} value='Major & Allied' /> : 
															<input type="text" name="i__subjects[]" required  style={{textTransform: 'uppercase'}} />}
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored i__part3_scored" onBlur={this.marksInputOnBlur} name="i__markScored[]" required  disabled={hasGradeSystem} />
															</td>
															<td>
																<input type="number" className="cl__no__arrow i__mx_marks i__p3mx_marks" name="i__maxMarks[]" required onBlur={this.marksInputOnBlur}   disabled={hasGradeSystem} />
															</td>
															<td>

																<select  name="i__class[]"  className="cl__no__arrow" style={{padding:'4px'}} required >
																	<option value="I"> I </option>
																	<option value="II"> II </option>
																	<option value="III"> III </option>
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  step="any" name="i__markPercentage[]" style={{width: '100px'}} required onChange={(e)=>{this.setState({part3PercentageTotal: e.target.value})}} />
																<input type="hidden" name="i__attempts[]" value="1" />
															</td>
															{/*<td>
																<select  name="i__attempts[]"  className="cl__no__arrow" style={{padding:'4px'}}  >
																	<option value="1"> 1 </option>
																	<option value="2"> 2 </option>
																	<option value="3"> 3 </option>
																	<option value="4"> 4 </option>
																</select>
										</td>*/}
														</tr>
														
														<tr>
															<td className="" >
															<input type="text" name="i__subjects[]" required  style={{textTransform: 'uppercase'}} value="PART-IV" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored "  onBlur={this.marksInputOnBlur} name="i__markScored[]" required  disabled={hasGradeSystem} />
															</td>
															<td>
																<input type="number" className="cl__no__arrow i__mx_marks " name="i__maxMarks[]" required onBlur={this.marksInputOnBlur}  disabled={hasGradeSystem}  />
															</td>
															<td>
																{/*<input type="text" className="cl__no__arrow" name="i__class[]" required />*/}
																<select  name="i__class[]"  className="cl__no__arrow" style={{padding:'4px'}} required >
																	<option value="I"> I </option>
																	<option value="II"> II </option>
																	<option value="III"> III </option>
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow"  step="any" name="i__markPercentage[]" style={{width: '100px'}} required  />
																<input type="hidden" name="i__attempts[]" value="1" />
															</td>
															{/*<td>
																<select  name="i__attempts[]"  className="cl__no__arrow" style={{padding:'4px'}}  >
																	<option value="1"> 1 </option>
																	<option value="2"> 2 </option>
																	<option value="3"> 3 </option>
																	<option value="4"> 4 </option>
																</select>
									</td>*/}
														</tr>
														
														<tr>
															<td className="" >
															<input type="text" name="i__subjects[]"  style={{textTransform: 'uppercase'}} value="PART-V" />
															</td>
															<td>
															<input type="number" className="cl__no__arrow i__mark_scored "  onBlur={this.marksInputOnBlur} name="i__markScored[]"  disabled={hasGradeSystem} />
															</td>
															<td>
																<input type="number" className="cl__no__arrow i__mx_marks " name="i__maxMarks[]" onBlur={this.marksInputOnBlur}   disabled={hasGradeSystem} />
															</td>
															<td>
																{/*<input type="text" className="cl__no__arrow" name="i__class[]" required />*/}
																<select  name="i__class[]"  className="cl__no__arrow" style={{padding:'4px'}} >
																	<option value="I"> I </option>
																	<option value="II"> II </option>
																	<option value="III"> III </option>
																</select>
															</td>
															<td>
																<input type="number" className="cl__no__arrow" step="any"  name="i__markPercentage[]" style={{width: '100px'}}  />
																<input type="hidden" name="i__attempts[]" value="1" />
															</td>
															{/*<td>
																<select  name="i__attempts[]"  className="cl__no__arrow" style={{padding:'4px'}}  >
																	<option value="1"> 1 </option>
																	<option value="2"> 2 </option>
																	<option value="3"> 3 </option>
																	<option value="4"> 4 </option>
																</select>
								</td>*/}
														</tr>
														
														<tr>
															<td>Total Marks / Percentage ( Part-III only)</td>
															<td>
																Marks Obtained in Part-III
																<input type="hidden" name="i__scoredTotal" className="cl__no__arrow" value={this.state.scoredTotal} />
															<input type="number" name="i__part3Total" className="cl__no__arrow"  style={{width: '80px'}} value={this.state.part3Total} disabled={hasGradeSystem}  />
															</td>
															<td>	
																Total marks in Part-III
																<input type="hidden" name="i__maxTotal" value={this.state.maxTotal} style={{width: '80px'}} step="any" />
																<input type="text" name="i__part3MaxTotal" value={this.state.part3MaxTotal} style={{width: '80px'}} disabled={hasGradeSystem}  step="any" />
															</td>
															<td>	
																Overall Class Obtained
																<input type="number" className="cl__no__arrow" name="i__overAllClass" required style={{width: '80px'}} />
															</td>
															<td>	
																Overall Percentage in Part-III
																<input type="text" name="i__part3PercentTotal" value={this.state.part3PercentageTotal} style={{width: '80px'}} /*onChange={(e)=>{this.setState({part3PercentageTotal: e.target.value})}}*/ />
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
										</Row>
										
									
										<div>
											<input type="hidden" name="i__community"  value={upperCase(community)} />
											<input type="hidden" name="i__caste" value="" />
											<input type="hidden" name="i__issuingAuthority" value="" />
											<input type="hidden" name="i__certificateNo" value="" />
											<input type="hidden" name="i__issueDate" value="" />
											<input type="hidden" name="i__district" value="" />
											<input type="hidden" name="i__taluk" value="" />
										</div>
																					
											<input type="hidden" name="i__captchaCode" value={this.state.captchaCode} />
											
											<Row className="my-15" >
												<Col md={12} >
													<button type="submit" className="btn btn-success btn-block" disabled={this.state.btnText!='Save Marks'} >
														{this.state.btnText}
													</button>
												</Col>
											</Row>
											
										</form>
										
									
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default MarksUpload;
