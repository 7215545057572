import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {notify} from 'react-notify-toast';
import {Var, MonthData} from '../../../utils/Variables';
import API from '../../../utils/API';
import {integerIndMobile,setlsItem, getls, setls, getlsItem, decimalKeyPress, numberToMonth, capitalizeFirst} from '../../../utils';
import {PageLoader} from '../../elements';

import InstructionForCourseApply from './InstructionForCourseApply';
import PsContext from '../../../context';

class SelfCourseApply extends Component{

	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			isRedirect: false,
			btnText: 'APPLY NOW',
			applicantInfo: [],
			courseList: [],
			appliedCourses: [],
			selectedCourse: [],
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadApplicantInfo();
		this.getCourseSuggestion();
	}
	
	loadApplicantInfo(){
		try{
			this.setState({showLoader: true, applicantInfo: []});
			var form = new FormData();
			form.append('i__apiCode', this.context.user.api_code);
			API.post('v1/applicant/min_info',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({applicantInfo: res['data'].data, showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getCourseSuggestion(){
		try{
			
			this.setState({showLoader: true, courseList: []});
			var form = new FormData();
			form.append('i__apiCode', this.context.user.api_code);
			form.append('i__courseType', 'self');
			API.post('v1/ug/apply/course_for_me',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						courseList: res['data'].data, 
						appliedCourses: res['data'].applied_course,
						showLoader: false,
					});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleCourseSelect(item){
		try{
			var d = this.state.selectedCourse;
			if(Object.keys(d).length>=parseInt(this.context.settingValue('admission_ug_self_max_course_selection'))){
				$.alert({type: 'red', title: 'Message', content: 'You are eligible to select '+ this.context.settingValue('admission_ug_self_max_course_selection') +' courses only'});
				return false;
			}
			var index = d.findIndex(function(obj){
				return (obj.id==item.id);
			});
			
			if(index>-1){
				return;
			}
			
			d.push(item);
			this.setState({selectedCourse: d});
		}
		catch(error){ }
	}
	
	checkCourseExist(courseId){
		try{
			var d = this.state.selectedCourse;
			var index = d.findIndex(function(obj){
				return (obj.id==courseId);
			});
			return (index>-1);
		}
		catch(error){ return false; }
	}

	checkCourseApplied(courseId){
		try{
			
			var d = this.state.appliedCourses;
			var index = d.findIndex(function(obj){
				return (obj.course_id==courseId);
			});
			return (index>-1);
		}
		catch(error){
			return false;
		}
	}
	
	getSelectedCoursePreference(courseId){
		try{
			var d = this.state.selectedCourse;
			var index = d.findIndex(function(obj){
				return (obj.id==courseId);
			});
			return (index==-1) ? '' : (index+1);
		}
		catch(error){  }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(Object.keys(this.state.selectedCourse).length<=0){
				notify.show('Please select at least one course to apply', 'warning');
				return;
			}
			
			if(!window.confirm('Please check your course preference before processing. Do you want to proceed to next?')){
				return false;
			}
			
			this.setState({showLoader: true, btnText: 'Processing..'});
			API.post('v1/ug/course/apply', $("#frmStepIII").serialize()).then(res=>{
				if(res['data'].status=='1'){
					notify.show(res['data'].message, 'success');
					//setls('logged_user', res['data'].user);
					this.context.updateUser(res['data'].user);
					this.setState({showLoader: false, selectedCourse: [], btnText: 'APPLY NOW', isRedirect: true});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false, btnText: 'APPLY NOW'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, btnText: 'APPLY NOW'});
		}
	}
	
	getApplicantValue(field){
		try{
			
			var d = this.state.applicantInfo;
			return d[field];
		}
		catch(error){
			return false;
		}
	}
	
	
	render(){
		
		if(getlsItem('logged')!='yes'){
			return(<Redirect to="/" />);
		}
		
		if(this.state.isRedirect){
			return(<Redirect to="/app/dashboard" />);
		}
		
		let sno=1;
		var community = 'sc';
		
		if(Object.keys(this.state.applicantInfo).length>0){
			community = this.state.applicantInfo.community;
		}
		
		return(
			<React.Fragment>
				
				<PageLoader loading={this.state.showLoader} />
				
				<section>
					<div className="container mt-30 pb-30" >
						
						<Row >
							<Col md={{span:10, offset:1}} >
								
								<InstructionForCourseApply />
								
								<Card className="shadow-lg" >
									<Card.Body>
										
										<Row>
											<Col md={12} >
												<div className="text-center" >
													<h5>Online Course Apply</h5>
													<hr />
												</div>
											</Col>
											<Col md={6} >
												<label>Name : <b className='text-uppercase'>{this.context.user.name+' '+this.context.user.initial}</b></label>
											</Col>
											<Col md={6} className="text-end" >
												<label>Registration No : <b>{this.context.user.user_code}</b></label>
											</Col>
											<Col md={12}><hr className="my-0" /></Col>
										</Row>
										
										<Row>
											<Col md={12} >
												<ul className="nav nav-tabs">
												  {this.context.settingValue('admission_has_ug_regular')=='1'&& (<li className="nav-item">
														<NavLink to="/app/ug-regular-course-apply" className="nav-link" activeClassName="active" >Regular</NavLink>
													</li>)}
												  <li className="nav-item">
													<NavLink to="/app/ug-self-course-apply" className="nav-link" activeClassName="active" >Self Financed</NavLink>
												  </li>
												</ul>
											</Col>
										</Row>
										
										<Row>
											<Col md={12} >
												<div className="table-responsive" >
												<table className="table table-bordered " >
													<thead>
														<tr className="bg-light-10" >
															<th>S.No</th>
															<th>Name of the Course</th>
															<th>Medium ( பயிற்று மொழி )</th>
															<th>Preference</th>
															<th>Select
															{Object.keys(this.state.selectedCourse).length>0 ?
																<button className="btn btn-danger btn-sm" type="button" style={{float:'right'}} onClick={(e)=>{this.setState({selectedCourse: []})}} >
																Un select all
															</button> : null}
															</th>
														</tr>
													</thead>
													<tbody>
													{this.state.courseList.map((item,i)=>{
														return <tr key={i} className={this.checkCourseExist(item.id) ? 'bg-warning' : ''} >
															<td>{sno++}</td>
															<td>{item.degreename} - {item.name}</td>
															<td>{capitalizeFirst(item.medium)}</td>
															<td>{this.getSelectedCoursePreference(item.id)}</td>
															{this.getApplicantValue('self_course_applied')=='1' &&  this.context.settingValue('admission_ug_self_multiple_apply')=='0' ? 
																<td>
																	
																</td>
																: 
																this.checkCourseApplied(item.id) ? <td style={{fontSize: '13px', fontWeight: 'bold'}}><i>Already Applied</i></td> :  <td style={{fontSize: '13px', cursor: 'pointer'}} onClick={this.handleCourseSelect.bind(this,item)} >
																	Click here to select
																</td>
															}
														</tr>;
													})
													}
													</tbody>
												</table>
												</div>
											</Col>
										</Row>
										
										
										{this.getApplicantValue('self_course_applied')=='1' && this.context.settingValue('admission_ug_self_multiple_apply')=='0' ? 
										<div>
											<div className="alert alert-info" >
												Course already applied. Please <Link to="/app/ug-application-download">Click Here</Link> to go download list
											</div>
										</div>
										:
										<form action="" method="post" encType="multipart/form-data" id="frmStepIII" onSubmit={this.handleFormSubmit} >
											{Object.keys(this.state.selectedCourse).length>0 ?
												<Row>
													<Col md={10} className="text-right"  >
														<label >Application Fee : </label>
													</Col>
													<Col md={2} style={{fontWeight: '700'}} >
														{(community=='sc' || community=='st' || community=='sca') ?
															<label> 
																{parseFloat(this.context.settingValue('admission_ug_self_application_scfee')).toFixed(2)} 
																<input type="hidden" name="i__fee" value={parseFloat(this.context.settingValue('admission_ug_self_application_scfee')).toFixed(2)} />
															</label>
															:
															<label> 
																{parseFloat(this.context.settingValue('admission_ug_self_application_fee')).toFixed(2)} 
																<input type="hidden" name="i__fee" value={parseFloat(this.context.settingValue('admission_ug_self_application_fee')).toFixed(2)} />
															</label>
														}
													</Col>
													<Col md={12} ><hr /></Col>
											</Row> : null}
											<input type="hidden" name="i__userId" value={this.context.user.user_id} />
											<input type="hidden" name="i__userCode" value={this.context.user.user_code} />
											<input type="hidden" name="i__apiCode" value={this.context.user.api_code} />
											<input type="hidden" name="i__courseType" value="self" />
											<input type="hidden" name="i__courseApply" value={JSON.stringify(this.state.selectedCourse)} />
											
											{((community=='sc' || community=='st' || community=='sca') && parseFloat(this.context.settingValue('admission_ug_self_application_scfee')) ==0) ?
												<Row>
													<Col md={12} >
														<input type="radio" name="i__paymentMethod" value="direct" checked /> No Payment for SC/ST/SCA Students
													</Col>
												</Row>
												:
												<Row>
												<Col md={12} >
													<label><b>Select Your Payment Method</b></label>
												</Col>
													{Var.EnableCashPayment ? <Col md={12} className="mt-15"  >
														<label className="chk-container">Direct Cash Payment / Bank DD Payment
														  <input type="radio" name="i__paymentMethod" value="cash" required />
														  <span className="checkmark"></span>
														</label>
													</Col> : null}
													{Var.EnableOnlinePayment ? <Col md={12} className="mt-15" >
														<label className="chk-container">Online (Debit Card/ Credit Card/ Net Banking/ Wallet/ UPI)
														  <input type="radio" name="i__paymentMethod" value="online" required  defaultChecked={true} />
														  <span className="checkmark"></span>
														</label>
													</Col> : null}
												</Row>
											}
											
											<Row>
												<Col md={12} >
													<div className="text-center my-25" >
														<Link to="/app/dashboard" className="btn btn-danger btn-md" >
															CANCEL
														</Link>
														<button type="submit" className="btn btn-success btn-md" disabled={this.state.btnText!='APPLY NOW'} >
															<i className="ion-checkmark" /> {this.state.btnText}
														</button>
													</div>
												</Col>
											</Row>
											
										</form>
										}
										
									</Card.Body>
								</Card>
							
							</Col>
						</Row>
						
				
					</div>
				</section>
				
			</React.Fragment>
		);
	}
	
}
export default SelfCourseApply;
