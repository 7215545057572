import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';
import PsContext from '../../../context';

class InstructionForCourseApply extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		
		
		return(
			<Card className="mb-15 shadow-lg" >
				<Card.Body>
					<h6>Please read the instructions carefully - கவனமாக படித்துவிட்டு தொடரவும்</h6>
					<hr />
					<ul style={{fontSize: '13px', lineHeight: '24px'}} >
						<li>Please select atleast one course to submit the form</li>
						{this.props.regular ? <li>Your are eligible to select Maximum of {this.context.settingValue('admission_ug_regular_max_course_selection')} courses</li>
						: <li>You are eligible to select Maximum of {this.context.settingValue('admission_ug_self_max_course_selection')} courses</li>}
						<li>Please check the course priority is filled or not</li>
						<li>Please filled out all the required fields</li>
					</ul>
				</Card.Body>
			</Card>
		);
	}
	
}
export default InstructionForCourseApply;
