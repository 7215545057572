import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PsContext from './index';
import { getlsItem, setlsItem } from '../utils';
import { listAll  } from '../utils/fieldSettings';

const PsContextProvider=(props)=>{
	
	const checkUserLogged=()=>{
		return getlsItem('logged') || 'no';
	};
	
	const getAdminUser=()=>{
		return getlsItem('logged_user') ? JSON.parse(getlsItem('logged_user')) : [];
	};
	
	const getAdminCompany=()=>{
		return getlsItem('coa_company_data') ? JSON.parse(getlsItem('coa_company_data')) : [];
	};
	
	const getAdminApi=()=>{
		return getlsItem('api') || '';
	};
	
	const getCertificateUpload=()=>{
		return getlsItem('certificate_upload') || '';
	};

	const getSettings=()=>{
		return getlsItem('adm_settings') ? JSON.parse(getlsItem('adm_settings')) : [];
	};

	const getPortalLocker=()=>{
		return getlsItem('adm_portal_locked') ? (getlsItem('adm_portal_locked')) : 1;
	};
	
	const [state, setState] = useState([]);
	
	const [logged, setLogged] = useState(checkUserLogged());
	const [user, setUser] = useState(getAdminUser());
	const [api, setApi] = useState(getAdminApi());
	const [certificateUpload, setCertificateUpload] = useState(getCertificateUpload());

	const [backgroundProcess, setBackgroundProcess] = useState(false);

	const [settings, setSettings] = useState(getSettings());

	const [portalLocked, setPortalLocked] = useState(getPortalLocker());
	
	const saveLogin=(user, certificate_upload,  api)=>{
		setlsItem('logged_user', JSON.stringify(user));
		setUser(user);		
		
		setlsItem('certificate_upload', user);
		setCertificateUpload(certificate_upload);
		
		setlsItem('api', api);
		setApi(api);
	};
	
	const logout=()=>{
		setlsItem('logged_user', false);
		setUser([]);
		setlsItem('api', '');
		setlsItem('certificate_upload', '');
		setApi(null);
		//axios.defaults.headers.common['Api-Token'] = '';
		setlsItem('logged', 'no');
		setLogged('no');
	};
	
	const updateUser=(user)=>{
		setlsItem('logged_user', JSON.stringify(user));
		setUser(user);		
	};
	
	const updateLogged=()=>{
		setlsItem('logged', 'yes');
		setLogged('yes');	
	};

	const loadSettings=()=>{
		listAll().then(res => {
			setlsItem('adm_settings', JSON.stringify(res));
			setSettings(res);
		});
	};

	const settingValue=(key)=>{
		let  m = settings.find(item => item.field_name==key);
		return (m && m.field_name) ? m.field_value : false;
	};

	const set=(fieldName, fieldValue)=>{
		setlsItem(fieldName, fieldValue);
	};

	const get=(fieldName)=>{
		return getlsItem(fieldName) || '';
	};

	const setPassLockDisabled=()=>{
		setlsItem('adm_portal_locked', '0');
		setPortalLocked('0');
	};
	
	return (
      <PsContext.Provider value={{
			state: state,
			logged: logged,
			user: user,
			api: api,
			settings:settings,
			certificateUpload: certificateUpload,
			saveLogin: saveLogin,
			updateUser: updateUser,
			updateLogged: updateLogged,
			setBackgroundProcess: setBackgroundProcess,
			backgroundProcess: backgroundProcess,
			logout: logout,
			loadSettings: loadSettings,
			settingValue: settingValue,
			set: set,
			get: get,
			portalLocked: portalLocked,
			setPassLockDisabled: setPassLockDisabled,
      }}
      >
        {props.children}
      </PsContext.Provider>
	);
};

export default PsContextProvider;