import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import routes from '../views';
import {Header, Footer, HeaderResponsiver} from '../views/elements';
import API from '../utils/API';
import {Api} from '../utils/Variables';
import {setls} from '../utils';
import Notifications, {notify} from 'react-notify-toast';


class EmptyLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		//this.loadSettings();
	}
	
	loadSettings(){
		try{
			API.get('v1/settings/me/1').then(res=>{
				if(res['data']. status == '1'){
					this.setState({siteData: res['data'].data});
					setls('site',res['data'].data);	
				}
			});
		}
		catch(error){
			
		}
	
	}
	
	render(){
		
		$('html, body').animate({scrollTop:0}, 'slow');
		
		return(
			<div> 
				<Notifications />
				
				<div className="page_content_holder" >
				
					<Switch>
						{routes.map((page,key)=>(
							<Route exact path={page.path}  component={page.component} key={key} />
						))}
					</Switch>	
				
				</div>
				
				<Footer />
			</div>
		);
	}
	
}
export default EmptyLayout;