import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';
import PsContext from '../../../context';

class Footer extends Component{

	static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	render(){
		
		return(
			<footer>
				<Row>
					<Col md={3} />
					<Col md={2} >
						<h6>Quick Links</h6>
						<ul>
							<li><Link to="/">Login</Link></li>
							{this.context.settingValue('enable_ug_admission')=='1' && (<li><Link to="/ug-admission-instruction">UG Registration</Link></li>)}
							{this.context.settingValue('enable_pg_admission')=='1' && (<li><Link to="/pg-admission-instruction">PG Registration</Link></li>)}
						</ul>
					</Col>
					
					<Col md={2} >
						<h6>Policies</h6>
						<ul>
							<li><Link to="/page/terms-and-condition">Terms and Condition</Link></li>
							<li><Link to="/page/refund-policy">Refund Policy</Link></li>
							<li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
							<li><Link to="/page/cancellation-policy">Cancellation Policy</Link></li>
						</ul>
					</Col>
					
					<Col md={2} >
						<img src="https://res.cloudinary.com/dzyixy4du/image/upload/v1584761826/admision/online_apy_list.png" style={{width: '100%'}}  />
					</Col>
				</Row>
				<Row style={{paddingTop: '15px', borderTop: '1px solid #6b6b6b'}} >
					<Col md={12} >
						<div className="text-center text-white" >
							<p>Copyright &copy; {this.context.settingValue('admission_form_clg_name')}
							&emsp;|&emsp; Powered by <a href="https://peacesoft.in" target="_blank" >Peace Soft</a>
							</p>
						</div>
					</Col>
				</Row>
			</footer>
		);
	}
	
}
export default Footer;
