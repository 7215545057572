import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {notify} from 'react-notify-toast';
import {Var} from '../../../utils/Variables';
import API from '../../../utils/API';

class InstructionForCourseApply extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		
		
		return(
			<Card className="mb-15 shadow-lg" >
				<Card.Body>
					<h6>Please read the instructions carefully - கவனமாக படித்துவிட்டு தொடரவும்</h6>
					<hr />
					<ul style={{fontSize: '13px', lineHeight: '24px'}} >
						<li>Please select atleast one course to submit the form</li>
						<li>Please filled out all the required fields</li>
					</ul>
					{/*<div>
						<ul style={{fontSize: '14px', lineHeight: '24px'}} >
							<li>அரசு மற்றும் சுயநிதிப் பாடப்பிரிவுகளுக்கு தனித்தனியாக விண்ணப்பிக்கவும்.</li>
							<li>அரசு உதவிப்பெறும் பாடப்பிரிவுகளுக்கு ஒரு முறையும் (3 பாடப்பிரிவுகள் வரை தேர்வு செய்யலாம்),</li>
							<li>சுயநிதிப் பாடப்பிரிவுகளுக்கு ஒரு முறையும் (3 பாடப்பிரிவுகள் வரை தேர்வு செய்யலாம்) விண்ணப்பிக்கலாம்.</li>
						</ul>
					</div>*/}
				</Card.Body>
			</Card>
		);
	}
	
}
export default InstructionForCourseApply;
